import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';
import AppContext from 'contexts/Context';
import { getColor } from 'helpers/utils';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

type Props = {
  value: string;
  onChange: (text: string) => void;
  height?: string;
  isInvalid?: boolean;
};

const TinymceEditor = ({
  value,
  onChange,
  height = '30vh',
  isInvalid,
}: Props) => {
  const { t } = useTranslation();

  const {
    config: { isDark, isRTL },
  }: any = useContext(AppContext);
  const editorRef: any = useRef(null);
  const [isOnFocus, setIsOnFocus] = useState(false);

  useEffect(() => {
    if (editorRef.current && isDark) {
      editorRef.current.dom.addStyle(
        `body{color: ${getColor('white')} !important;}`,
      );
    }
  }, [isDark]);

  const onFocus = () => {
    setIsOnFocus(true);
  };

  const onBlur = () => {
    setIsOnFocus(false);
  };

  return (
    <div
      className={classNames('tox-tinymce-wrapper', {
        'tox-tinymce__wrapper--on-focus': isOnFocus,
        'tox-tinymce__wrapper--invalid': isInvalid,
      })}
    >
      <Editor
        onInit={(_, editor) => (editorRef.current = editor)}
        value={value}
        onEditorChange={onChange}
        apiKey={process.env.REACT_APP_TINYMCE_APIKEY}
        init={{
          height,
          width: '100%',
          menubar: false,
          content_style: `body { color: ${getColor(
            'body-color',
          )}; margin: 1rem ; font-family: Inter; font-weight: 300 } 
            .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
            color: ${getColor('input-placeholder-color')};
            opacity: 1;
          }`,
          mobile: {
            theme: 'silver',
          },
          statusbar: false,
          plugins: 'link lists table media directionality code emoticons',
          toolbar:
            'undo redo | styles forecolor backcolor link bullist numlist table media emoticons code',
          directionality: isRTL ? 'rtl' : 'ltr',
          theme_advanced_toolbar_align: 'center',
          // content_css: [
          //   'https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700|Poppins:100,200,300,400,500,600,700,800,900&display=swap'
          // ],
          placeholder: t('general.type_here'),
        }}
        onBlur={onBlur}
        onFocus={onFocus}
      />
    </div>
  );
};

TinymceEditor.propTypes = {
  value: PropTypes.string,
  handleChange: PropTypes.func,
  height: PropTypes.string,
};

export default TinymceEditor;
