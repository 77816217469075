import { StatusEnum } from './common.type';
import { OrderPaymentStatusEnum } from './order.type';
import { UserInfo } from './user.type';

export type AffiliateItem = {
  commission_rate?: number;
  created_on?: number;
  discount?: number | null;
  display_name?: string;
  code?: string;
  owner?: UserInfo;
  slug?: string;
  affiliate_code?: string;
  socials?: [{ platform: string; url: string }];
  status?: StatusEnum;
  updated_on?: number;
  _id?: string;
  type?: string;
  partner_id?: string;
  partner_name?: string;
  customer_id?: string;
  customer_name?: string;
  customer?: {
    full_name: string;
    _id: string;
  };
  partner?: UserInfo & {
    email: string;
    phone_number: string;
    full_name: string;
    _id: string;
  };
};

export type PayoutItem = {
  _id?: string;
  name?: string;
  earnings?: number;
  payment_status?: OrderPaymentStatusEnum;
  paid_orders?: number;
  conversion_rate?: number;
  sold_items?: number;
  net_sales?: number;
  total_clicks?: number;
  status?: OrderPaymentStatusEnum;
  reference?: string;
  updated_on?: number;
  created_on?: number;
};

export enum AffiliateTypeEnum {
  CUSTOMER = 'customer',
  PARTNER = 'partner',
}
export type PayoutOrderItem = {
  _id: string;
  code: string;
  rowSpan?: number;
  created_on: number;
  item: {
    _id: string;
    product_name: string;
    product_slug: string;
    product_id: string;
    seller_id: string;
    variant_id: string;
    variant_name: string;
    variant_slug: string;
    status: StatusEnum;
    price: number;
    discount_price: number;
    quantity: number;
    image_urls: string[];
    item_total: number;
    item_discounted: number;
    affiliate_discounted: number;
    commission: number;
  };
};
