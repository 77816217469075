import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { apiCallV2 } from './ApiCallV2';
import { dataFetchingQueryConfig } from 'config';
import { AffiliateItem, PayoutItem } from 'types';
import { toast } from 'react-toastify';
import { t } from 'i18next';
const config = dataFetchingQueryConfig;

export const useAffiliateList = (params = {}, key?: string) => {
  // const
  const url = `admin/affiliates`;
  return useQuery<any>({
    queryKey: ['useAffiliateList', params, key],
    queryFn: () =>
      apiCallV2({
        url,
        method: 'GET',
        params,
      }),
    ...config,
  });
};

export const useAffiliateDetails = (id?: string) => {
  const url = `admin/affiliates/` + id;
  return useQuery<any>({
    queryKey: ['useAffiliateDetails', id],
    queryFn: () =>
      apiCallV2({
        url,
        method: 'GET',
      }),
    enabled: !!id,
    ...config,
  });
};
export const useAffiliatePayments = (id?: string) => {
  const url = `admin/affiliates/` + id + '/payments';
  return useQuery<any>({
    queryKey: ['useAffiliatePayments', id],
    queryFn: () =>
      apiCallV2({
        url,
        method: 'GET',
      }),
    enabled: !!id,
    ...config,
  });
};

export const useCreateAffiliate = () => {
  return useMutation({
    mutationFn: (data: AffiliateItem) => {
      const url = `admin/affiliates`;
      return apiCallV2({
        url,
        method: 'POST',
        data,
      });
    },
    onSuccess: (res) => {
      if (res?.status) {
        toast.success(t('general.create_success'));
      }
    },
    onError: (res: any) => {
      toast.error(res?.description?.message || t('general.create_fail'));
    },
  });
};

export const useUpdateAffiliate = () => {
  return useMutation({
    mutationFn: (params: AffiliateItem) => {
      const { _id, ...data } = params;
      const url = `admin/affiliates/` + _id;
      return apiCallV2({
        url,
        method: 'PUT',
        data,
      });
    },
    onSuccess: () => {
      toast.success(t('general.update_success'));
    },

    onError: (res: any) => {
      toast.error(res?.description?.message || t('general.update_fail'));
    },
  });
};

export const useAffiliateSummaryReport = ({
  id,
  from,
  to,
}: {
  id?: string;
  from?: number;
  to?: number;
}) => {
  const url = `admin/affiliates/` + id + '/summary-reports';
  return useQuery<any>({
    queryKey: ['useAffiliateSummaryReport', id, from, to],
    queryFn: () =>
      apiCallV2({
        url,
        method: 'GET',
        params: { from, to },
      }),
    enabled: !!id && !!from && !!to,
    ...config,
  });
};
export const useAffiliateReportChart = ({
  id,
  from,
  to,
  type,
  group_by,
}: {
  id?: string;
  from?: number;
  to?: number;
  type?: string;
  group_by?: string;
}) => {
  const url = `admin/affiliates/` + id + '/report-charts';
  return useQuery<any>({
    queryKey: ['useAffiliateReportChart', id, from, to, type, group_by],
    queryFn: () =>
      apiCallV2({
        url,
        method: 'GET',
        params: {
          from,
          to,
          type,
          group_by,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      }),
    enabled: !!id && !!from && !!to,
    ...config,
  });
};

export const useDeleteAffiliate = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: any) => {
      const url = `admin/affiliates/` + id;
      return apiCallV2({
        url,
        method: 'DELETE',
        data: { id },
      });
    },
    onSuccess: (res) => {
      if (res?.status) {
        toast.success(t('general.delete_success'));
        queryClient.invalidateQueries(['useAffiliateList'] as any);
      }
    },
    onError: (res) => {
      toast.error(t('general.delete_fail'));
    },
    ...config,
  });
};

export const useUpdateAffiliatePayout = () => {
  return useMutation({
    mutationFn: (params: PayoutItem) => {
      const { _id, ...data } = params;
      const url = `admin/affiliates/payments/` + _id;
      return apiCallV2({
        url,
        method: 'PUT',
        data,
      });
    },
    onSuccess: () => {
      toast.success(t('general.update_success'));
    },
    onError: () => {
      toast.error(t('general.update_fail'));
    },
  });
};

export const usePayoutOrders = (id?: string) => {
  const url = `admin/affiliates/payments/` + id;
  return useQuery<any>({
    queryKey: ['usePayoutOrders', id],
    queryFn: () =>
      apiCallV2({
        url,
        method: 'GET',
      }),
    enabled: !!id,
    ...config,
  });
};

export const useExportPayout = () => {
  return useMutation({
    mutationFn: (_id: string) => {
      const url = `admin/affiliates/payments/exports/` + _id;
      return apiCallV2({
        url,
        method: 'GET',
        params: {
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      });
    },
    onSuccess: () => {
      toast.success(t('general.export_success'));
    },
    onError: () => {
      toast.error(t('general.export_fail'));
    },
  });
};
