import dayjs from 'dayjs';
import { currencyFormatter } from './utils';
var weekOfYear = require('dayjs/plugin/weekOfYear');

export const getPosition = (pos, params, dom, rect, size) => ({
  top: pos[1] - size.contentSize[1] - 10,
  left: pos[0] - size.contentSize[0] / 2,
});

export const tooltipFormatter = (
  params,
  dateMode,
  duration,
  isCurrency = false,
  isPercent = false,
) => {
  dayjs.extend(weekOfYear);
  let tooltipItem = ``;
  let line1 = '';
  let line2 = '';
  if (dayjs(params[0].axisValue).isValid()) {
    const day = dayjs(params[0].axisValue);
    if (dateMode === 'hour') {
      line1 = `${day.format('MMM DD, h:mm A')}`;
      line2 = `${day.add(-duration, 'day').format('MMM DD, h:mm A')}`;
    } else if (dateMode === 'week') {
      line1 = `Week ${day.week()}`;
      line2 = `Week ${day.add(-duration, 'day').week()}`;
    } else {
      // day
      line1 = day.format('MMM DD, YYYY');
      line2 = day.add(-duration, 'day').format('MMM DD, YYYY');
    }
  }
  if (Array.isArray(params)) {
    params?.forEach((el, i) => {
      let value = typeof el.value === 'object' ? el.value[1] : el.value;
      if (isCurrency) {
        value = currencyFormatter.format(value || 0);
      } else if (isPercent) {
        value = (value || 0) + '%';
      }
      tooltipItem =
        tooltipItem +
        `<div class='mx-1 mt-2'> 
          <h6 class="text-700">
          ${i ? line2 : line1} : <b>
          ${value}</b>
          </h6>
        </div>`;
    });
  }
  return `<div>
            ${tooltipItem}
          </div>`;
};
export const xLabelFormatter = (value, dateMode) => {
  if (dayjs(value).isValid()) {
    let formated = dayjs(value).format('MMM DD');
    if (dateMode === 'hour') {
      formated = dayjs(value).format('MMM DD');
    }
    if (dateMode === 'week') {
      formated = dayjs(value).format('MMM YYYY');
    }
    return formated;
  } else return value;
};
