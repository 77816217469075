import {
  Anchor,
  Flex,
  Breadcrumbs as MantineBreadcrumbs,
  Text,
} from '@mantine/core';
import IconFA from 'components/common/IconFA';
import { t } from 'i18next';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { BreadCrumsProps } from 'types';

const BreadCrumbs = ({
  data = [],
  rightSection = null,
}: {
  data: BreadCrumsProps[];
  rightSection?: ReactNode;
}) => {
  const renderData: BreadCrumsProps[] = [
    {
      title: (
        <>
          <IconFA icon="home" className=" me-1" type="light" />
          {t('navigation.home')}
        </>
      ),
      href: '/',
    },
    ...data,
  ];
  return (
    <Flex align={'center'} justify={'space-between'}>
      <MantineBreadcrumbs className="my-3">
        {renderData?.map((item, index) =>
          item?.hidden ? null : index === renderData?.length - 1 ? (
            <Text c="dimmed" key={index}>
              {item?.title}
            </Text>
          ) : (
            <Anchor
              component={Link}
              to={item.href || ''}
              c={'primary'}
              key={index}
            >
              {item?.title}
            </Anchor>
          ),
        )}
      </MantineBreadcrumbs>
      {rightSection}
    </Flex>
  );
};
export default BreadCrumbs;
