import { Button, Text } from '@mantine/core';
import { ArrangeModels } from 'types';
import { useUpdateAdminBanner } from 'apis/BannerApis';
import { useArrangeItems } from 'apis/CommonApi';
import IconFA from 'components/common/IconFA';
import NailzyImage from 'components/common/NailzyImage';
import { SectionFooter } from 'components/MantineUI/CommonSection';
import { t } from 'i18next';
import DragDropList from 'pages/products/add-product/components/DragDropList';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';
type PropType = {
  opened: boolean;
  onClose: () => void;
  data?: any[];
};
const ArrangeModal = (props: PropType) => {
  // ### CONSTANTs
  const { opened = false, onClose: onCloseProp, data = [] } = props;
  const [items, setItems] = useState([...(data || [])]);
  const { mutate: update, isPending: updating } = useArrangeItems(
    ArrangeModels.BANNER,
  );

  useEffect(() => {
    if (opened) {
      setItems([...data]);
    }
  }, [opened, data]);
  // ### FUNCTIONs
  const onClose = () => {
    setItems([]);
    onCloseProp?.();
  };

  const queryClient = useQueryClient();
  const onDragListChange = (list: any[]) => {
    setItems([...list]);
  };
  const onClickSave = () => {
    const array: any = [...data]?.map((x, i) => {
      return {
        _id: items?.[i]?._id,
        // name: items?.[i]?.name,
        position: x?.position,
      };
    });
    update(array, {
      onSuccess: () => {
        onClose();
        toast.success(t('general.update_success'));
        queryClient.invalidateQueries(['useAdminBannerList'] as any);
      },
    });
  };
  // ### RENDERs

  return (
    <Modal size={'lg'} onHide={onClose} show={opened}>
      <Modal.Header closeButton>
        <Text>{t('promotions.arrange_banners')}</Text>
      </Modal.Header>
      <div className="p-4">
        <DragDropList
          containerClassName={'mb-0'}
          data={[...(items || [])] as any}
          onChange={(list: any) => onDragListChange(list)}
          renderItem={(provided: any, item: any, index: any) => {
            return (
              <div
                key={item?._id}
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                className="flex flex-row items-center mb-3 p-3 py-2 border rounded-3 bg-white"
              >
                <NailzyImage
                  className={'border rounded'}
                  style={{ width: 130, height: 50 }}
                  image_url={item?.image_url}
                />
                <Text className="flex-1 px-2">
                  {item?.name || `${t('products.variant')} #${index + 1}`}
                </Text>
                <IconFA icon={'grip-dots-vertical'} />
              </div>
            );
          }}
        />

        <SectionFooter>
          <Button loading={updating} onClick={onClickSave}>
            {t('general.save')}
          </Button>
          <Button onClick={() => onClose()} variant="default">
            {t('general.cancel')}
          </Button>
        </SectionFooter>
      </div>
    </Modal>
  );
};
export default ArrangeModal;
