import {
  CloseButton,
  ComboboxItem,
  Flex,
  MantineSize,
  NativeSelect,
  TextInput,
} from '@mantine/core';
import IconFA from 'components/common/IconFA';
import { useURLParams } from 'hooks/useURLParams';
import { t } from 'i18next';
import { ChangeEvent, ReactNode, useEffect, useState } from 'react';
import { useAsyncDebounce } from 'react-table';

type PropType = {
  children?: ReactNode;
  className?: string;
  size?: MantineSize;
  placeholder?: string;
  searchParams?: string;
  searchSelections?: ComboboxItem[];
};
const TableSearchBox = ({
  size = 'xs',
  placeholder = '',
  className = '',
  searchParams = '',
  searchSelections = [],
}: PropType) => {
  // ### CONSTANTs

  const { params, handleParamChange, handleParamsChange } =
    useURLParams() as any;
  const [value, setValue] = useState('');
  const [currentSearch, setCurrentSearch] = useState('');

  useEffect(() => {
    if (params?.[searchParams]) {
      setValue(params?.[searchParams]);
    } else if (searchSelections?.length) {
      for (let i = 0; i < searchSelections.length; i++) {
        const keyParams = searchSelections[i]?.value;
        if (params?.[keyParams]) {
          setValue(params?.[keyParams]);
          setCurrentSearch(keyParams);
          return;
        }
      }
      setCurrentSearch(searchSelections?.[0]?.value);
    }
  }, []);

  // ### FUNCTIONs
  const handleDebounceChange = useAsyncDebounce(
    (field: string, value?: string) => {
      if (field) {
        handleParamChange(field)(value || undefined);
      }
    },
    500,
  );

  const handleSearchChange = (event?: ChangeEvent<HTMLInputElement>) => {
    const value = event?.target?.value || '';
    setValue(value);
    handleDebounceChange(searchParams || currentSearch, value?.trim());
  };
  const onChangeSearch = (e: string) => {
    let obj: any = {};
    setCurrentSearch(e);
    for (let i = 0; i < searchSelections.length; i++) {
      const keyParams = searchSelections[i]?.value;
      obj = {
        ...obj,
        [keyParams]: keyParams !== e ? undefined : value || undefined,
      };
    }
    handleParamsChange(obj);
  };
  // ### RENDERs

  return (
    <Flex flex={1} gap={'xs'}>
      {searchSelections?.length ? (
        <NativeSelect
          onChange={(e) => onChangeSearch(e.target.value)}
          size={size}
          data={searchSelections}
          value={currentSearch}
        />
      ) : null}
      <TextInput
        flex={1}
        className={`grow ${className}`}
        value={value || ''}
        size={size}
        placeholder={placeholder || t('general.search')}
        onChange={handleSearchChange}
        leftSection={<IconFA icon="magnifying-glass" className="text-xs" />}
        rightSection={
          <CloseButton
            size="xs"
            aria-label="Clear input"
            onClick={() => {
              setValue('');
              handleParamChange(searchParams || currentSearch)(undefined);
            }}
            style={{ display: value ? undefined : 'none' }}
          />
        }
      />
    </Flex>
  );
};
export default TableSearchBox;
