import StrictModeDroppable from 'components/common/StrictModeDroppable';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import { Card, Col, Row } from 'react-bootstrap';
import { array } from 'yup';

const DragDropList = ({
  data = [],
  onChange,
  draggableId = 'draggableId',
  renderItem,
  containerClassName = '',
}) => {
  const [draggableData, setDraggableData] = useState([
    { id: draggableId, contents: data || [] },
  ]);
  useEffect(() => {
    if (data?.length !== draggableData?.[0]?.contents?.length) {
      setDraggableData([{ ...draggableData?.[0], contents: data }]);
    }
  }, [data]);

  useEffect(() => {
    const array = draggableData?.[0]?.contents?.filter(
      (x) => !!data?.find((y) => x?._id === y?._id),
    );
    if (array?.length) {
      onChange?.(array);
    }
  }, [draggableData?.[0]?.contents]);

  const getColumn = (id) => {
    return draggableData.find((item) => item.id === id);
  };

  const reorder = (array, fromIndex, toIndex) => {
    const newArr = [...array];

    const chosenItem = newArr.splice(fromIndex, 1)[0];
    newArr.splice(toIndex, 0, chosenItem);

    return newArr;
  };

  const move = (source, destination) => {
    const sourceItemsClone = [...getColumn(source.droppableId).contents];
    const destItemsClone = [...getColumn(destination.droppableId).contents];

    const [removedItem] = sourceItemsClone.splice(source.index, 1);
    destItemsClone.splice(destination.index, 0, removedItem);

    return {
      updatedDestItems: destItemsClone,
      updatedSourceItems: sourceItemsClone,
    };
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = getColumn(source.droppableId).contents;
      const reorderedItems = reorder(items, source.index, destination.index);
      const result = draggableData.map((item) =>
        item.id === source.droppableId
          ? { ...item, contents: reorderedItems }
          : item,
      );

      setDraggableData(result);
    } else {
      const sourceColumn = getColumn(source.droppableId);
      const destColumn = getColumn(destination.droppableId);
      const movedItems = move(source, destination);
      const result = draggableData.map((item) => {
        return {
          ...item,
          contents:
            (item._id === sourceColumn._id && movedItems.updatedSourceItems) ||
            (item._id === destColumn._id && movedItems.updatedDestItems),
        };
      });

      setDraggableData(result);
    }
  };
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <StrictModeDroppable droppableId={draggableId} type="DRAG">
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className={containerClassName || `py-3 mb-3`}
            style={{ maxHeight: '1000000px' }}
          >
            {data?.map((item, index) => (
              <Draggable
                key={`${item._id}_${index}`}
                draggableId={`drag_${item._id}_${index}`}
                index={index}
              >
                {(provided) =>
                  renderItem ? (
                    renderItem(provided, item, index)
                  ) : (
                    <div>
                      <Card
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="mb-3 kanban-item shadow-sm dark__bg-1100"
                      >
                        <Card.Body>
                          <p className="fs--1 fw-medium font-sans-serif mb-0">
                            {item.text}
                          </p>
                        </Card.Body>
                      </Card>
                    </div>
                  )
                }
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </StrictModeDroppable>
    </DragDropContext>
  );
};
export default DragDropList;
