export enum CouponTypeEnum {
  PERCENTAGE = 'percentage',
  FIXED = 'fixed',
}
export enum MinPurchaseEnum {
  AMOUNT = 'MinimumPurchaseAmount',
  QUANTITY = 'PurchaseQuantity',
}

export enum CouponSort {
  CREATED_ON_DESC = 'created_on_desc',
  CREATED_ON_ASC = 'created_on_asc',
  UPDATED_ON_DESC = 'updated_on_desc',
  UPDATED_ON_ASC = 'updated_on_asc',
  NAME_DESC = 'name_desc',
  NAME_ASC = 'name_asc',
  CODE_DESC = 'code_desc',
  CODE_ASC = 'code_asc',
  VALUE_DESC = 'value_desc',
  VALUE_ASC = 'value_asc',
  QUANTITY_DESC = 'quantity_desc',
  QUANTITY_ASC = 'quantity_asc',
}
