import { useMutation, useQuery } from '@tanstack/react-query';
import { apiCallV2 } from './ApiCallV2';

export const usePromotionDetail = (id: string) =>
  useQuery({
    queryKey: ['usePromotionDetail', id],
    queryFn: () =>
      apiCallV2({
        method: 'get',
        url: 'admin/promotions/' + id,
        redirect404Error: true,
      }),
    enabled: !!id,
  });
