import { faDownload, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { Anchor, Button, Flex, Grid } from '@mantine/core';
import { CommonTableColumn } from 'types';
import IconFA from 'components/common/IconFA';
import FilterButton from 'components/MantineUI/Buttons/FilterButton';
import {
  SectionBody,
  SectionHeader,
  SectionWrapper,
} from 'components/MantineUI/CommonSection';
import { CommonTable, TableSearchBox } from 'components/MantineUI/CommonTable';
import SortButton from 'components/MantineUI/CommonTable/SortButton';
import PageLayout from 'components/MantineUI/PageLayout';
import { currencyFormatter, dateFormatter } from 'helpers/utils';
import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import StatusBadge from 'components/MantineUI/StatusBadge';
import DropdownButton from 'components/MantineUI/Buttons/DropdownButton';
import { useDeleteCustomer, useGetCustomers } from 'apis/CustomersApi';
import { usePaginationParams } from 'hooks/usePaginationParams';
import { useURLParams } from 'hooks/useURLParams';
import CustomerFilter from './components/CustomerFilter';
import { useForm } from '@mantine/form';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';
import { userKeys } from './queryKeys';
import DeleteCustomerModal from './components/DeleteCustomerModal';
import ExportCustomerModal from './components/ExportCustomerModal';
import CustomerDetailsModal from './components/CustomerDetailsModal';
import { useTranslation } from 'react-i18next';

export const getCustomerTitle = (title: string) => {
  switch (title) {
    case 'nail_supply':
      return 'customer.nail_supply';
    case 'nail_tech':
      return 'customer.nail_tech';
    case 'salon_owner':
      return 'customer.salon_owner';
    default:
      return 'customer.other';
  }
};
const CustomersPage = () => {
  // ### CONSTANTs
  const { t } = useTranslation();
  const { params, handleParamsChange }: any = useURLParams();
  const { page, limit, onPaginationChange } = usePaginationParams();
  const [showFilter, setShowFilter] = useState(false);
  const [deleteCustomer, setDeleteCustomer] = useState<any>();
  const [selectedUser, setSelectedUser] = useState<any>();
  const [showExportModal, setShowExportModal] = useState(false);
  const { mutate: onDeleteCustomer, isPending: deletingCustomer }: any =
    useDeleteCustomer();
  const queryClient = useQueryClient();
  const { data, isLoading, isFetching }: any = useGetCustomers({
    ...params,
    page: page,
    limit: limit,
  });
  const customers = data?.data?.results || [];
  const total = data?.data?.total || 0;
  const count = customers?.length || 0;
  const form = useForm({
    initialValues: {
      status: '',
      title_type: '',
      is_referral: false,
      date_range: [],
    },
  });
  const { setValues, getValues } = form;
  useEffect(() => {
    setValues({
      status: params?.status || '',
      title_type: params?.title_type || '',
      is_referral: params?.is_referral ? true : false,
      date_range: params?.['date_range[from]']
        ? [
            dayjs.unix(params?.['date_range[from]']),
            dayjs.unix(params?.['date_range[to]']),
          ]
        : ([] as any),
    });
  }, []);
  // ### FUNCTIONs
  const handleSaveFilter = () => {
    const values = getValues();
    handleParamsChange({
      page: 1,
      status: values?.status || undefined,
      title_type: values?.title_type || undefined,
      is_referral: values?.is_referral ? 'true' : undefined,
      ['date_range[from]']: values.date_range?.[0]
        ? dayjs(values.date_range?.[0]).startOf('day').unix()
        : undefined,
      ['date_range[to]']: values.date_range?.[1]
        ? dayjs(values.date_range?.[1]).endOf('day').unix()
        : undefined,
    });
    setShowFilter(false);
  };
  const onReset = () => {
    form.reset();
    handleParamsChange({
      page: 1,
      status: undefined,
      title_type: undefined,
      is_referral: undefined,
      ['date_range[from]']: undefined,
      ['date_range[to]']: undefined,
    });
    setShowFilter(false);
  };
  const onDelete = (data: any, reason: string) => {
    onDeleteCustomer(
      { id: deleteCustomer?._id, reason },
      {
        onSuccess: () => {
          toast.success(t('general.delete_success'));
          setDeleteCustomer(undefined);
          queryClient.invalidateQueries(userKeys.lists() as any);
        },
      },
    );
  };

  // ### RENDERs
  const columns: CommonTableColumn[] = useMemo(
    () => [
      {
        accessor: 'name',
        Header: t('general.name'),
        Cell: (row: any) => {
          const { first_name, last_name } = row;
          const fullName =
            first_name && last_name ? `${first_name} ${last_name}` : undefined;
          return (
            <Anchor size="sm" component={Link} to={`/customers/${row?._id}`}>
              {fullName ? fullName : <>--</>}
            </Anchor>
          );
        },
      },
      {
        accessor: 'phone_number',
        Cell: (row: any) => (row.phone_number ? row.phone_number : '--'),
        Header: t('register.form.phone'),
      },
      {
        accessor: 'email',
        Cell: (row: any) => (row.email ? row.email : '--'),
        Header: t('register.form.email'),
      },
      {
        accessor: 'paid',
        Header: t('orders.paid'),
        Cell: (row: any) => {
          const { total_order, total_sale } = row?.sale_report;
          return (
            <>
              <div>
                {total_sale ? (
                  <Anchor
                    size="sm"
                    component={Link}
                    to={`/orders/?payment_status=paid&customer_id=${row?._id}`}
                  >{`${total_order} ${t(
                    'orders.orders',
                  ).toLowerCase()}`}</Anchor>
                ) : (
                  '--'
                )}
              </div>
              <div>
                {total_sale ? currencyFormatter.format(total_sale) : '--'}
              </div>
            </>
          );
        },
      },
      {
        accessor: 'unpaid',
        Header: t('orders.unpaid'),
        Cell: (row: any) => {
          const { total_unpaid_order, total_unpaid_sale } = row?.sale_report;
          return (
            <>
              <div>
                {total_unpaid_order ? (
                  <Anchor
                    size="sm"
                    component={Link}
                    to={`/orders/?payment_status=unpaid&customer_id=${row?._id}`}
                  >
                    {`${total_unpaid_order} ${t(
                      'orders.orders',
                    ).toLowerCase()}`}
                  </Anchor>
                ) : (
                  '--'
                )}
              </div>
              <div>
                {total_unpaid_sale
                  ? currencyFormatter.format(total_unpaid_sale)
                  : '--'}
              </div>
            </>
          );
        },
      },
      {
        accessor: 'title_type',
        Cell: (row: any) => t(getCustomerTitle(row.title_type)),
        Header: t('filter.role'),
      },
      {
        accessor: 'referred_by',
        Header: t('customer.referred_by'),
        Cell: (row: any) => {
          const { referred_by } = row;
          const fullName = referred_by
            ? `${referred_by?.first_name} ${referred_by?.last_name}`
            : undefined;
          return fullName ? (
            <Anchor
              size="sm"
              component={Link}
              to={`/customers/${referred_by?._id}`}
            >
              {fullName}
            </Anchor>
          ) : (
            <>--</>
          );
        },
      },
      {
        accessor: 'created_by',
        Header: t('general.created_by'),
        Cell: (row: any) => {
          const { created_by } = row;
          const fullName = created_by
            ? `${created_by?.first_name} ${created_by?.last_name}`
            : undefined;
          return fullName ? (
            // <Link to={`/system-users/${created_by?._id}`}>{fullName}</Link>
            fullName
          ) : (
            <>--</>
          );
        },
      },
      {
        accessor: 'created_on',
        Cell: (row: any) => dateFormatter(row?.created_on),
        Header: t('general.created_date'),
      },
      {
        accessor: 'status',
        Header: t('general.status'),
        Cell: (row: any) => {
          const { status } = row;
          return <StatusBadge fullWidth status={status} />;
        },
      },
      {
        accessor: 'actions',
        Cell: (row: any) => {
          return (
            <DropdownButton
              button={
                <Button variant="subtle" color="gray" size="xs">
                  <IconFA icon={'ellipsis'} />
                </Button>
              }
              dropdownData={[
                {
                  key: 'edit',
                  label: t('general.edit'),
                  onClick: () => setSelectedUser(row),
                },
                { isDivider: true },
                {
                  key: 'cancel_order',
                  label: t('general.delete'),
                  color: 'red',
                  onClick: () => {
                    setDeleteCustomer(row);
                  },
                },
              ]}
            />
          );
        },
      },
    ],
    [],
  );
  return (
    // <SkeletonWrapper skeleton={<ListPageSkeleton />} show={isLoading}>
    <PageLayout
      title={t('navigation.customers')}
      breadCrumds={[{ title: t('navigation.customers') }]}
    >
      <form>
        <Grid>
          <Grid.Col span={{ base: 12 }}>
            <SectionWrapper>
              <SectionHeader
                title={t('navigation.customers')}
                actionButtons={
                  <>
                    <Button
                      onClick={() => setShowExportModal(true)}
                      leftSection={<IconFA icon={faDownload} />}
                      variant="subtle"
                    >
                      {t('general.export')}
                    </Button>
                    <Button
                      onClick={() => setSelectedUser({})}
                      leftSection={<IconFA icon={faPlus} />}
                    >
                      {t('general.add')}{' '}
                      {t('orders.customer').toLocaleLowerCase()}
                    </Button>
                  </>
                }
              />
              <SectionBody>
                <Flex gap={'sm'}>
                  <TableSearchBox
                    searchSelections={[
                      {
                        label: t('products.search_by_name'),
                        value: 'name',
                      },
                      {
                        label: t('products.search_by_phone'),
                        value: 'phone_number',
                      },
                      {
                        label: t('products.search_by_email'),
                        value: 'email',
                      },
                      {
                        label: t('products.search_by_business_name'),
                        value: 'business_name',
                      },
                      {
                        label: t('products.search_by_business_phone'),
                        value: 'business_phone',
                      },
                      {
                        label: t('products.search_by_address'),
                        value: 'address',
                      },
                    ]}
                  />
                  <FilterButton onClick={() => setShowFilter(true)} />
                  <SortButton />
                </Flex>
              </SectionBody>
              <CommonTable
                loading={isFetching}
                columns={columns}
                data={customers}
                pagination={{ limit, page, total, onPaginationChange }}
              />
            </SectionWrapper>
          </Grid.Col>
          <CustomerFilter
            onReset={onReset}
            onSave={form.onSubmit(handleSaveFilter)}
            form={form}
            opened={showFilter}
            onClose={() => setShowFilter(false)}
          />
          <DeleteCustomerModal
            onShow={!!deleteCustomer}
            name={
              !deleteCustomer?.first_name && !deleteCustomer?.first_name
                ? t('orders.customer').toLowerCase()
                : `${deleteCustomer?.first_name || '-'} ${
                    deleteCustomer?.last_name || '-'
                  }`
            }
            onClose={() => setDeleteCustomer(undefined)}
            onDelete={onDelete}
            isLoading={!!deletingCustomer}
          />
          <ExportCustomerModal
            visible={showExportModal}
            onHide={() => setShowExportModal(false)}
            current_items={count}
          />
          <CustomerDetailsModal
            opened={!!selectedUser}
            onClose={() => setSelectedUser(undefined)}
            value={selectedUser}
          />
        </Grid>
      </form>
    </PageLayout>
    // </SkeletonWrapper>
  );
};
export default CustomersPage;
