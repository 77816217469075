import React, { ReactNode } from 'react';
import { Drawer, Title } from '@mantine/core';

type PropType = {
  children?: ReactNode;
  title?: string;
  opened: boolean;
  onClose: () => void;
};
const CommonDrawer = (props: PropType) => {
  // ### CONSTANTs
  const { children, title = '', opened, onClose } = props;
  // ### FUNCTIONs
  // ### RENDERs
  return (
    <Drawer
      // zIndex navbar=3
      zIndex={101}
      opened={opened}
      onClose={onClose}
      title={<Title order={4}>{title}</Title>}
      position="right"
    >
      {children}
    </Drawer>
  );
};
export default CommonDrawer;
