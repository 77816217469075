import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { apiCallV2 } from './ApiCallV2';
import { dataFetchingQueryConfig } from 'config';
import { StatusEnum } from 'types';
import { toast } from 'react-toastify';
import { t } from 'i18next';
const config = dataFetchingQueryConfig;

export const usePartnerList = (params: any) =>
  useQuery({
    queryKey: ['usePartnerList', params],
    queryFn: () =>
      apiCallV2({
        method: 'get',
        url: 'admin/partners',
        params,
      }),
    ...config,
  });

export const useCreatePartner = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params: any) => {
      const url = `admin/partners`;
      return apiCallV2({
        url,
        method: 'POST',
        data: params,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['usePartnerList'] as any);
      toast.success(t('general.create_success'));
    },
    onError: (res: any) => {
      toast.error(res?.description?.message);
    },
  });
};

export const useUpdatePartner = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params: any) => {
      const { _id, ...data } = params;
      const url = `admin/partners/${_id}`;
      return apiCallV2({
        url,
        method: 'PUT',
        data: data,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['usePartnerList'] as any);
      toast.success(t('general.update_success'));
    },
    onError: (res: any) => {
      toast.error(res?.description?.message);
    },
  });
};
export const useDeletePartner = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: string) => {
      const url = `admin/partners/${id}`;
      return apiCallV2({
        url,
        method: 'DELETE',
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['usePartnerList'] as any);
      toast.success(t('general.delete_success'));
    },
    onError: () => {
      toast.error(t('general.delete_fail'));
    },
  });
};

export const usePartnerDetails = (id?: string) => {
  const url = `admin/partners/` + id;
  return useQuery<any>({
    queryKey: ['usePartnerDetails', id],
    queryFn: () =>
      apiCallV2({
        url,
        method: 'GET',
      }),
    enabled: !!id,
    ...config,
  });
};
