import { StatusEnum } from './common.type';

export type InformationItem = {
  _id?: string;
  slug?: string;
  title?: {
    en?: string;
    vi?: string;
  };
  subtitle?: {
    en?: string;
    vi?: string;
  };
  content?: {
    en?: string;
    vi?: string;
  };
  image_urls?: string[];
  icon?: string;
  created_on?: number;
  updated_on?: number;
  status: StatusEnum;
  is_featured?: boolean;
  section?: ArticleSectionsEnum;
  type?: InformationSectionsEnum[];
};

export enum InformationPageTypeEnum {
  HELP_ARTICLES = 'help-articles',
  INFORMATION = 'information',
}

export enum InformationSectionsEnum {
  // ALL = 'all',
  PAGES = 'pages',
  HOME_HEADER = 'home_header',
  // HOME_BODY = 'home_body',
  // PRODUCT_DETAILS = 'product_details',
  // SHOPPING_CART = 'shopping_cart',
}

export const InformationTabTypes = {
  // [InformationSectionsEnum.ALL]: {
  //   label: 'orders.order_all',
  // },
  [InformationSectionsEnum.PAGES]: {
    label: 'form.information.type_guide_pages',
  },
  [InformationSectionsEnum.HOME_HEADER]: {
    label: 'form.information.type_guide_home_header',
  },
  // [InformationSectionsEnum.HOME_BODY]: {
  //   label: 'form.information.type_guide_home_body',
  // },
  // [InformationSectionsEnum.PRODUCT_DETAILS]: {
  //   label: 'form.information.type_guide_product_details',
  // },
  // [InformationSectionsEnum.SHOPPING_CART]: {
  //   label: 'form.information.type_guide_shopping_cart',
  // },
};

export enum ArticleSectionsEnum {
  // ALL = 'all',
  SHOPPING_WITH_NAILZY = 'shopping_with_nailzy',
  GENERAL_INFORMATION = 'general_information',
  PAYMENTS = 'payments',
  ORDERS_SHIPPING = 'orders_shipping',
  RETURNS_REFUNDS = 'returns_refunds',
  OFFERS_PROMOTIONS = 'offers_promotions',
}

export const ArticleTabTypes = {
  // [ArticleSectionsEnum.ALL]: {
  //   label: 'orders.order_all',
  // },
  [ArticleSectionsEnum.SHOPPING_WITH_NAILZY]: {
    label: 'form.information.articles.shopping_with_nailzy',
  },
  [ArticleSectionsEnum.GENERAL_INFORMATION]: {
    label: 'form.information.articles.general_information',
  },
  [ArticleSectionsEnum.PAYMENTS]: {
    label: 'form.information.articles.payments',
  },
  [ArticleSectionsEnum.ORDERS_SHIPPING]: {
    label: 'form.information.articles.orders_shipping',
  },
  [ArticleSectionsEnum.RETURNS_REFUNDS]: {
    label: 'form.information.articles.returns_refunds',
  },
  [ArticleSectionsEnum.OFFERS_PROMOTIONS]: {
    label: 'form.information.articles.offers_promotions',
  },
};

export const INFORMATION_TYPES = {
  pages: {
    value: 'pages',
    label: 'form.information.type_guide_pages',
    // image: InformationGuideImage2,
  },
  home_header: {
    value: 'home_header',
    label: 'form.information.type_guide_home_header',
    // image: InformationGuideImage1,
  },
  // home_body: {
  //   value: 'home_body',
  //   label: 'form.information.type_guide_home_body',
  //   // image: InformationGuideImage3,
  // },
  // product_details: {
  //   value: 'product_details',
  //   label: 'form.information.type_guide_product_details',
  //   // image: InformationGuideImage4,
  // },
  // shopping_cart: {
  //   value: 'shopping_cart',
  //   label: 'form.information.type_guide_shopping_cart',
  //   // image: InformationGuideImage5,
  // },
};
export const ARTICLES_TYPES = {
  shopping_with_nailzy: {
    value: 'shopping_with_nailzy',
    label: 'form.information.articles.shopping_with_nailzy',
    // image: InformationGuideImage2,
  },
  general_information: {
    value: 'general_information',
    label: 'form.information.articles.general_information',
    // image: InformationGuideImage1,
  },
  payments: {
    value: 'payments',
    label: 'form.information.articles.payments',
    // image: InformationGuideImage3,
  },
  orders_shipping: {
    value: 'orders_shipping',
    label: 'form.information.articles.orders_shipping',
    // image: InformationGuideImage4,
  },
  returns_refunds: {
    value: 'returns_refunds',
    label: 'form.information.articles.returns_refunds',
    // image: InformationGuideImage5,
  },
  offers_promotions: {
    value: 'offers_promotions',
    label: 'form.information.articles.offers_promotions',
    // image: InformationGuideImage5,
  },
};
