const en = {
  translation: {
    account: {
      account: 'Account',
      account_name: 'Account holder name',
      account_number: 'Account number',
      bank_details: 'Bank details',
      bank_name: 'Bank name',
      change_password: 'Change password',
      profile_security: 'Profile & Security',
      routing_number: 'Routing number',
      sales_rep_code: 'Sales rep code',
      security: 'Security',
      security_desc: 'You can add or update your sign-in methods.',
      social_links: 'Social links',
      social_links_desc:
        'Sign in faster by linking your social media to your account.',
      store_desc: 'Please provide your store information.',
      store_details: 'Store details',
    },
    admin_panel: 'Admin panel',
    affiliates: {
      account_display_name: 'Display name',
      account_name: 'Account name',
      add_affiliate: 'Add affiliate partner',
      affiliate: 'Affiliate',
      affiliate_code: 'Affiliate code',
      affiliate_link: 'Affiliate link',
      affiliates: 'Affiliates',
      affiliates_decs:
        'Manage and track affiliate partnerships, including commission rates, performance, and payouts.',
      commission_rate: 'Commission rate',
      customer_name: 'Customer name',
      delete_affiliate: 'Delete affiliate',
      edit_affiliate: 'Edit affiliate',
      extra_discount: 'Extra discount',
      reference: 'Reference',
      social_links: 'Social links',
      store_link: 'Store link',
      view_orders: 'View orders',
    },
    customer: {
      amount_spent: 'Amount spent',
      auth_provider: 'Auth providers',
      business_name: 'Business name',
      business_phone: 'Business phone',
      create_customer: 'Add customer',
      delete_customer: 'Delete customer',
      delete_customer_note:
        'Note: Deleting this customer will permanently delete all data and information associated with their account.',
      delete_customer_reason: 'Reason for deleting',
      edit_customer: 'Edit customer',
      export_customer: 'Export customers',
      nail_supply: 'Nail Supplier',
      nail_tech: 'Nail Technician',
      other: 'Other',
      please_select_add_customer: 'Please select customer or add new address',
      referral_code: 'Referral code',
      referral_link: 'Referral link',
      referrals: 'Referrals',
      referred_by: 'Referred by',
      role_type: 'Role type',
      salon_owner: 'Salon Owner',
      send_email_reset_pass: 'Send email reset password',
      send_email_reset_pass_fail: 'Send email failed!',
      send_email_reset_pass_success: 'Send email successfully!',
    },
    dashboard: {
      best_seller_items: 'Best-selling items',
      best_selling: 'Best-selling products',
      commissions: 'Commissions',
      conversion_rate: 'Conversion rate',
      conversion_rate_decs:
        'The percentage of product views that result in a purchase',
      custom: 'Custom',
      customer_analytics: 'Customer analytics',
      dashboard: 'Dashboard',
      earnings: 'Earnings',
      earnings_desc:
        'The total number of earnings generated from completed orders',
      ending_coupon: 'Coupons ending soon',
      ending_promotion: 'Promotions ending soon',
      estimated_commissions: 'Estimated commissions',
      estimated_commissions_desc:
        'The total number of commissions estimated from paid orders',
      failed_delivery_order: 'Orders unable to be delivered',
      greeting: "Here's a summary of your store's performance today.",
      items_sold: 'Sold items',
      items_sold_decs: 'The total number of items sold',
      last_x_day: 'Last {{days}} days',
      marketing_center: 'Marketing center',
      need_accept: 'Orders to be accepted',
      need_approve: 'Product reviews to be approved',
      need_fulfill: 'Orders to be fulfilled',
      net_sales: 'Net sales',
      net_sales_desc: 'The total value of items sold',
      notifications: 'Notifications',
      order_analytics: 'Order analytics',
      pending_affiliate: 'Affiliate accounts to be approved',
      pending_products: 'Products to be approved',
      product_views: 'Product views',
      product_views_decs: 'The total number of unique product views',
      rejected_products: 'Rejected products to be revised',
      store_visits: 'Store visits',
      store_visits_decs: 'The number of visits to your store',
      thing_to_do: 'Things to do',
      today: 'Today',
      today_orders: "Today's orders",
      today_sales: "Today's sales",
      total_buyer: 'Total buyers',
      total_buyer_desc: 'The total number of customers who have made purchases',
      total_clicks: 'Total clicks',
      total_clicks_desc: 'Total number of clicks on affiliate links',
      total_customers: 'New accounts',
      total_customers_desc: 'The total number of newly registered accounts',
      total_events: 'Total events',
      total_events_desc: 'Total number of posted events',
      total_jobs: 'Total nail jobs',
      total_jobs_desc: 'Total number of posted jobs',
      total_orders: 'Total orders',
      total_orders_desc: 'The total number of paid orders',
      total_paid_orders: 'Paid orders',
      total_paid_orders_desc: 'The total number of paid orders',
      total_paid_sales: 'Paid sales',
      total_paid_sales_desc: 'The total value of paid orders',
      total_payouts: 'Total payouts',
      total_payouts_desc: 'Total amount paid out to you',
      total_posts: 'Total posts',
      total_posts_desc: 'Total number of posts',
      total_potential_buyer: 'Potential buyers',
      total_potential_buyer_desc:
        'The total number of customers who have placed orders but not yet completed payments',
      total_products: 'Total products',
      total_products_desc: 'Total number of listed products',
      total_referred_customer: 'Referred customers',
      total_referred_customer_desc:
        'The total number of new accounts referred by other customers',
      total_returning_buyer: 'Returning buyers',
      total_returning_buyer_desc:
        'The total number of customers who have made more than one purchase',
      total_sales: 'Total sales',
      total_sales_desc: 'The total amount of sales',
      total_salons: 'Total salons for sale',
      total_salons_desc: 'Total number of posted salons',
      total_techs: 'Total tech profiles',
      total_techs_desc: 'Total number of posted techs',
      total_unpaid_orders: 'Unpaid orders',
      total_unpaid_orders_desc:
        'The total number of unpaid orders (including awaiting payment, payment failed, and unpaid orders)',
      total_unpaid_sales: 'Unpaid sales',
      total_unpaid_sales_desc:
        'The total value of unpaid orders (including awaiting payment, payment failed, and unpaid orders)',
      unit_price: 'Unit price',
      waiting_payment: 'Orders waiting for payments',
      welcome: 'Welcome',
    },
    draft_carts: {
      add_draft_carts: 'Add draft cart',
      draft_cart: 'Draft cart',
      draft_carts: 'Draft carts',
      save_draft: 'Save draft',
    },
    error: {
      back_home: 'Back home',
      contact_us: 'contact us.',
      error_403: 'Error 403',
      error_403_content:
        "Oops, you don't have permission to access this page. If you think this is a mistake,",
      error_403_message: 'Access denied.',
      error_404: 'Error 404',
      error_404_content:
        "Make sure the address is correct and that the page hasn't moved. If you think this is a mistake,",
      error_404_message: "The page you're looking for is not found.",
      error_500: 'Error 500',
      error_500_content:
        'Try refreshing the page, or going back and attempting the action again. If this problem persists,',
      error_500_message: ' Whoops, something went wrong!',
    },
    filter: {
      filters: 'Filters',
      joined_date_range: 'Joined date range',
      role: 'Role',
    },
    forgot_password: {
      a_mail_sent_to_reset_password:
        'Please check your mailbox to reset your password',
      create_new: 'and create your new password.',
      forgot_password: 'Forgot password',
      login_to: 'log in to your account',
      password_reset: 'Your password is reset!',
      please_code:
        'Please enter the 4-digit verification code sent to your email',
      please_enter:
        'Please enter your email below to receive a verification code.',
      reset_password: 'Reset password',
      send_code: 'Send code',
      you_can: 'You can now',
    },
    form: {
      account: {
        profile_picture: 'Profile picture',
        security_subtitle: 'You can add or update your sign-in methods.',
        social_linking_subtitle:
          'Sign in faster by linking your social media to your account.',
        social_linking_title: 'Social links',
        update_profile_error: 'Update profile error',
      },
      change_mail: {
        input_email: 'Input email',
        input_email_to_change: 'Enter the email you want to change',
        input_mail_to_confirm: 'Input your password to confirm',
      },
      change_phone: {
        add_phone_fail: 'Add phone number fail',
        add_phone_success: 'Add phone number successful',
        code_has_been_sent:
          'Verification code has been sent to the phone number',
        input_your_phone_number: 'Input your phone number',
        otp_form_resent_code_after: 'Resend code after',
        phone_number_has_been_changed: 'The phone number has been changed',
        phone_number_you_want_to_change:
          'Input the phone number you want to change',
        send_otp_error: 'Send verification code error',
        update_phone_fail: 'Update phone number fail',
        update_phone_success: 'Update phone number successful',
        verify_OTP_code: 'Verify OTP code',
        verify_successful: 'Verify successful',
      },
      common: {
        drop_file_here: 'Drop your files here',
        field_code: '{{field}} code',
        field_logo: '{{field}} logo',
        field_name: '{{field}} name',
        field_status: '{{field}} status',
        type: 'Type',
      },
      coupon: {
        all_customers: 'All customers',
        all_products: 'All products',
        apply_to: 'Apply to',
        coupon: 'Coupon',
        coupon_code: 'Coupon code',
        customer_eligibility: 'Customer eligibility',
        discount_value: 'Discount value',
        excludes: 'Exceptions',
        excludes_by: 'Exclude by',
        fixed_amount: 'Fixed amount',
        limit: 'Limit',
        minimum_purchase_amount: 'By minimum purchase amount',
        minimum_purchase_requirements: 'Minimum purchase requirements',
        multiple_uses_per_customer: 'Multiple uses per customer',
        no_exception: 'No exceptions',
        no_target: 'No target',
        numbers_of_uses: 'Numbers of uses',
        one_use_per_customer: 'One use per customer',
        percentage: 'Percentage',
        please_select_target: 'Please select target!',
        purchase_quantity: 'By minimum purchase quantity',
        quantity: 'Quantity',
        requirements: 'Requirements',
        selection: 'Target selection',
        specific_brand: 'Specific brand',
        specific_brands: 'Specific brands',
        specific_cat: 'Specific category',
        specific_cates: 'Specific categories',
        specific_customers: 'Specific customers',
        specific_product: 'Specific product',
        specific_promotion: 'Specific promotion',
        specific_seller: 'Specific seller',
        specific_sellers: 'Specific sellers',
        specific_subcat: 'Specific subcategory',
        target: 'Target',
        target_customer: 'Target customer',
        usage_limit: 'Usage limit',
        valid_from: 'Valid from',
        valid_until: 'Valid until',
      },
      information: {
        articles: {
          general_information: 'General information',
          offers_promotions: 'Offers & Promotions',
          orders_shipping: 'Orders & Shipping',
          payments: 'Payments',
          returns_refunds: 'Returns & Refunds',
          shopping_with_nailzy: 'Shopping with Nailzy',
        },
        content: 'Content',
        english: 'English',
        information: 'Information',
        section: 'Section',
        subtitle: 'Subtitle',
        title: 'Title',
        type: 'Placement',
        type_guide: 'Instructions for choosing where to place',
        type_guide_detail_home_body:
          'This will be displayed in the middle of the homepage.',
        type_guide_detail_home_header:
          'This will be displayed in the header (top part of the website).',
        type_guide_detail_pages: 'This will be used for the information page.',
        type_guide_detail_product_details:
          'This will be displayed in the product page.',
        type_guide_detail_shopping_cart:
          'This will be displayed in the shopping cart page.',
        type_guide_home_body: 'On homepage body',
        type_guide_home_header: 'On page header',
        type_guide_pages: 'In information page',
        type_guide_product_details: 'In product detail page',
        type_guide_shopping_cart: 'In shopping cart page',
        vietnamese: 'Vietnamese',
      },
      invalid: {
        duplicate_variant_name: 'duplicate variant name',
        duplicate_variants: '{{name}} has duplicate in 1 product',
        extra_space: '{{name}} has extra spaces',
        greater_than_equal: 'must be greater than or equal',
        greater_than_equal_0: 'must be greater than 0',
        invalid: '{{fieldName}} is invalid!',
        load_fail: 'Load {{field}} fail!',
        only_allow_number: '{{name}} only allow number',
        required: '{{fieldName}} is required!',
      },
      store: {
        banner_desc: 'Banners will be displayed in your store page.',
        business_type: 'Business type',
        business_type_company: 'Company',
        business_type_individual: 'Individual',
        document: 'Document',
        documents: 'Documents',
        fbn: 'Fulfilled by Nailzy (FBN)',
        fulfillment_type: 'Fulfillment type',
        image_limit_error: 'Number of images is limit to 6',
        legal_business_name: 'Legal business name',
        seller_stocks_and_fulfill: 'Fulfilled by seller',
        store_details: 'Store details',
        store_status: 'Store status',
        store_status_desc:
          'You can provide documents to verify your store here.',
        tax_id: 'Tax ID (EIN, ITIN, or SSN)',
        upload_document: 'Upload documents',
        upload_images_condition_1: 'Dimensions: 2000 px width x 769 px height',
        upload_images_condition_2: 'Maximum: 6 images, file size 2.0 MB each',
        upload_images_condition_3: 'Supported file types: JPG, PNG, WEPB',
      },
    },
    general: {
      action_required: 'Action required',
      active: 'Active',
      add: 'Add',
      add_note: 'Add note',
      added: 'Added',
      all: 'All',
      apply: 'Apply',
      applying: 'Applying...',
      approve: 'Approve',
      approved: 'Approved',
      arrange: 'Arrange',
      at: 'at',
      back: 'Back',
      banner: 'Banner',
      browse: 'Browse',
      bulk_actions: 'Bulk actions',
      business_name_asc: 'Business name: A to Z',
      business_name_desc: 'Business name: Z to A',
      by: 'by',
      cancel: 'Cancel',
      canceled: 'Canceled',
      cant_undone: '? This can’t be undone.',
      change: 'Change',
      change_status: 'Change status',
      close: 'Close',
      closed: 'Closed',
      code: 'Code',
      code_asc: 'Code: A to Z',
      code_desc: 'Code: Z to A',
      completed: 'Completed',
      confirm: 'Confirm',
      confirm_save_change: 'Please save your changes before proceeding.',
      content: 'Content',
      continue: 'Continue',
      copied: 'Copied',
      copy_failed: 'Failed to copy',
      create: 'Create',
      create_fail: 'Created failed!',
      create_success: 'Created successful!',
      created_asc: 'Created: Oldest first',
      created_by: 'Created by',
      created_date: 'Created on',
      created_desc: 'Created: Newest first',
      current: 'Current',
      days_ago: 'days ago',
      default: 'Default',
      delete: 'Delete',
      delete_confirm: 'Are you sure you want to ',
      delete_confirm_2: 'Are you sure you want to delete',
      delete_fail: 'Deleted failed!',
      delete_success: 'Deleted successful!',
      deleted: 'Deleted',
      deleting: 'Deleting...',
      description: 'Description',
      discard: 'Discard',
      discard_all: 'Discard unsaved changes',
      discard_all_desc:
        'Are you sure you want to discard unsaved changes? You’ll lose any information you have entered.',
      download: 'Download',
      draft: 'Draft',
      drop_files: 'Drop your files here',
      dropzone_decs_1: 'Drag here or click to select',
      dropzone_decs_2: 'Each file should not exceed 5 MB',
      duplicate: 'Duplicate',
      edit: 'Edit',
      edit_history: 'Edit history',
      edit_note: 'Edit note',
      end_date: 'Until',
      error: 'Error',
      export: 'Export',
      export_desc: 'Do you want to export',
      export_desc_2: 'on the current page to a spreadsheet file (XLSX format)?',
      export_fail: 'Exported failed!',
      export_success: 'Exported successful!',
      exporting: 'Exporting...',
      featured: 'Featured',
      filter: 'Filter',
      first_name_asc: 'First name: A to Z',
      first_name_desc: 'First name: Z to A',
      fixed: 'Fixed',
      from: 'From',
      generate_code: 'Generate code',
      hot_brand: 'Hot brand',
      icon: 'Icon',
      image: 'Image',
      images: 'Images',
      images_desc: 'or drag and drop your image here',
      images_desc_2: 'Maximum 10 files to be uploaded',
      import: 'Import',
      import_desc: 'in bulk',
      import_desc_2:
        ' a spreadsheet template (XLSX format) and fill out with correct information.',
      import_desc_3:
        'Then you can upload your XLSX file below to do bulk import',
      import_fail: 'Imported failed!',
      import_success: 'Imported successful!',
      imported: 'Imported',
      importing: 'Importing...',
      invalid_file: 'Invalid file',
      joined_on: 'Joined',
      last_update_on: 'Last updated on',
      limit: 'Limit',
      link: 'Link',
      loading: 'Loading...',
      main_image: 'Main image',
      make_as_default: 'Make as default',
      month_ago: 'months ago',
      name: 'Name',
      name_asc: 'Name: A to Z',
      name_desc: 'Name: Z to A',
      next: 'Next',
      no_data: 'No data...',
      notes: 'Notes',
      number_sold_asc: 'Sold: Lowest first',
      number_sold_desc: 'Sold: Highest first',
      of: 'of',
      official_brand: 'Official brand',
      or: 'or',
      page: 'Page',
      pending: 'Pending',
      percentage: 'Percentage',
      phone: 'Phone number',
      previous: 'Previous',
      profile_picture: 'Profile picture',
      published_date: 'Published on',
      quantity: 'Quantity',
      quantity_asc: 'Quantity: Lowest first',
      quantity_desc: 'Quantity: Highest first',
      rating_asc: 'Rating: Lowest first',
      rating_desc: 'Rating: Highest first',
      reject: 'Reject',
      rejected: 'Rejected',
      remove: 'Remove',
      removing: 'Removing...',
      reorder: 'Re-order',
      reports: 'Reports',
      reset: 'Reset',
      rows_per_page: 'Rows per page',
      save: 'Save',
      save_and_publish: 'Save & publish',
      save_as_draft: 'Save as draft',
      save_changes: 'Save changes',
      saving: 'Saving...',
      scheduled: 'Scheduled',
      search: 'Search...',
      select: 'Select',
      select_all: 'Select all',
      select_date_range: 'Select date range',
      select_date_time: 'Select date & time',
      select_icon: 'Select icon',
      select_order_status: 'Select order status',
      select_payment_status: 'Select payment status',
      select_role: 'Select role',
      select_type: 'Select type',
      selected: 'selected',
      sent: 'Sent',
      ships_from_nailzy: 'Ships from Nailzy',
      slug: 'Slug',
      slug_exist: 'Slug is existed',
      slug_required: 'Slug is required',
      sort: 'Sort',
      start_date: 'From',
      status: 'Status',
      team: 'Team',
      title: 'Title',
      to: 'to',
      to_be_confirmed: 'To be confirmed',
      total: 'Total',
      type: 'Type',
      type_here: 'Type here...',
      unlink: 'Unlink',
      update: 'Update',
      update_fail: 'Updated failed!',
      update_success: 'Updated successful!',
      updated_asc: 'Updated: Oldest first',
      updated_date: 'Updated on',
      updated_desc: 'Updated: Newest first',
      upload: 'Upload',
      upload_errors: 'Upload errors',
      upload_and_save: 'Upload & save',
      upload_images: 'Upload images',
      upload_images_desc:
        'You can upload a compressed file in ZIP format containing your images below.',
      upload_images_desc_2:
        'The filename of the images must be matched with data of the column "Filename" in the bulk import template.',
      upload_import: 'Upload to import',
      uploading: 'Uploading...',
      used: 'Used',
      validated: 'Validated',
      validity_period: 'Validity period',
      value: 'Value',
      value_asc: 'Value: Lowest first',
      value_desc: 'Value: Highest first',
      verify: 'Verify',
      verifying: 'Verifying...',
      view: 'View',
      view_less: 'View less',
      view_more: 'View more',
      view_web: 'View on website',
      write: 'Write',
    },
    inquiries: {
      edit_inquiry: 'Edit inquiry',
      inquiries: 'Inquiries',
      inquiries_decs:
        'View submissions & inquiries about services that customers are interested in.',
      inquiry: 'Inquiry',
    },
    login: {
      admin_login: 'Admin & staff log in',
      admin_please: 'Please log in to access the admin panel.',
      already_seller: 'Already a seller?',
      back_login: 'Back to log in',
      code_sent_to: 'A code has been sent to',
      enter_verification_code: 'Please enter the verification code',
      forgot_password: 'Forgot password?',
      login: 'Log in',
      login_with_password: 'Log in with password',
      login_without_password: 'Log in without password',
      logout: 'Log out',
      no_account: `Don't have a seller account?`,
      or_continue_with: 'Or continue with',
      register: 'Register',
      register_free: 'Register for free',
      seller_login: 'Seller log in',
      seller_please: 'Please log in to access the seller panel.',
      signed_out: 'Logged out',
      thank_you: 'Thank you for using Nailzy!',
    },
    navigation: {
      add_cart: 'Add cart',
      add_coupon: 'Add coupon',
      announcements: 'Announcements',
      articles: 'Articles',
      article: 'Article',
      banner_details: 'Banner details',
      banners: 'Banners',
      cart_details: 'Cart details',
      carts: 'Carts',
      coupon: 'Coupon',
      coupon_details: 'Coupon details',
      coupons: 'Coupons',
      customer_details: 'Customers details',
      customers: 'Customers',
      discount_details: 'Discount details',
      discounts: 'Discounts',
      edit_banner: 'Edit banner',
      edit_cart: 'Edit cart',
      edit_coupon: 'Edit coupon',
      edit_discount: 'Edit discount',
      event_signups: 'Event signups',
      events: 'Events',
      faqs: 'FAQs',
      flash_sale: 'Flash sales',
      help_articles: 'Help articles',
      help_center: 'Help center',
      home: 'Home',
      information: 'Information',
      jobs: 'Nail jobs',
      just_arrived: 'Just arrived',
      language: 'Tiếng Việt',
      pages: 'Pages',
      postings: 'Postings',
      posts: 'Posts',
      promotion: 'Promotion',
      promotions: 'Promotions',
      salons: 'Salons for sale',
      seller_details: 'Seller details',
      sellers: 'Sellers',
      settings: 'Settings',
      shipping: 'Shipping',
      skills: 'Skills',
      staff: 'Staff',
      staff_details: 'Staff details',
      stock_clearance: 'Stock clearance',
      taxes: 'Taxes',
      techs: 'Tech profiles',
      top_picks: 'Top picks',
      user_details: 'User details',
      users: 'Users',
      warehouses: 'Warehouse',
    },
    orders: {
      abandoned_checkout: 'Abandoned checkout',
      accept: 'Accept',
      accept_order: 'Accept order',
      accept_shipping: 'Fulfill order',
      actions: 'Actions',
      add_multi_package: 'Add multi-package shipment',
      address_book: 'Address book',
      affiliate_discount: 'Affiliate discount',
      all_sellers: 'All sellers',
      already_bought_label:
        'You have already bought the below shipping label on',
      already_chose_label:
        'You have already selected the below shipping rate on',
      already_entered_custom:
        'You have already entered the below custom fulfillment on',
      already_entered_tracking_info:
        'You have already entered the below tracking information on',
      amount: 'Amount',
      awaiting_fulfillment: 'Awaiting fulfillment',
      awaiting_payment: 'Awaiting payment',
      bad_reviews: 'Bad reviews',
      better_price: 'Found a better price',
      billing_address: 'Billing address',
      business_address: 'Business',
      business_day: 'business day(s)',
      business_days: 'business days',
      buy_shipping_label: 'Buy shipping label',
      cancel_and_refund: 'Cancel order and refund',
      cancel_order: 'Cancel order',
      canceled: 'Canceled',
      change_of_mind: 'Change of mind',
      change_status: 'Change status',
      choose_shipping_rate: 'Choose shipping rate',
      completed: 'Completed',
      coupon_discount: 'Coupon discount',
      current_page: 'Current page',
      custom_fulfillment: 'Custom fulfillment',
      custom_note: 'Notes',
      customer: 'Customer',
      customer_request: 'Customer request',
      customer_selected_method: 'Customer selected this method at checkout',
      discount: 'Discount',
      discounts: 'Discounts',
      dup_coupon_code: 'Duplicate coupon',
      duplicate_order: 'Duplicate order',
      edit_custom_fulfillment: 'Edit custom fulfillment',
      edit_history: 'Edit history',
      edit_order: 'Edit order',
      edit_shipping: 'Edit tracking information',
      edit_shipping_information: 'Edit shipping information',
      edit_shipping_rate: 'Edit shipping rate',
      edit_tracking_info: 'Edit tracking information',
      enter_custom_fulfillment: 'Enter custom fulfillment',
      enter_order_note: 'Enter order note',
      enter_tracking_info: 'Enter tracking information',
      est_delivery: 'Estimated delivery',
      expensive_shipping: 'Shipping costs too expensive',
      export_orders: 'Export orders',
      fetching_pricing: 'Fetching total pricing...Please wait!',
      fetching_shipment: 'Fetching shipping rates...Please wait!',
      flat_rate: 'Flat Rate',
      forgot_coupon: 'Forgot to apply coupons',
      fraud_analysis: 'Fraud analysis',
      free_shipping: 'Free shipping',
      fulfill_order: 'Fulfill order',
      fulfill_type: 'Fulfillment type',
      fulfilling_shipment: 'Fulfilling order...Please wait!',
      fulfillment_status: 'Fulfillment status',
      get_payment_receipt: 'Get payment receipt',
      grand_total: 'Order total',
      high_risk: 'High risk',
      item: 'Item',
      item_discount: 'Item discount',
      item_total: 'Item total',
      items: 'Items',
      items_discount: 'Item(s) discount',
      items_total: 'Item(s) total',
      long_shipping: 'Shipping time too long',
      manage_package: 'Manage package template',
      mark_as_delivered: 'Mark order as delivered',
      marketplace: 'Marketplace',
      mass: 'Total weight',
      minimum_place_order: 'Minimum order amount is $0.5',
      msrp_discount: 'MSRP discount',
      nailzy_coupon: 'Nailzy coupon',
      no_discount: 'No discount',
      no_order: 'No order found...',
      normal_risk: 'Normal risk',
      order: 'Order',
      order_all: 'All',
      order_canceled: 'Canceled',
      order_comments: 'Order notes',
      order_completed: 'Completed',
      order_date: 'Order date',
      order_date_range: 'Order date range',
      order_delivered: 'Delivered',
      order_details: 'Order details',
      order_failed_delivery: 'Failed delivery',
      order_mistake: 'Order placed by mistake',
      order_number: 'Order #',
      order_partially_shipped: 'Partially shipped',
      order_pending: 'Pending',
      order_process: 'Order timeline',
      order_processing: 'Processing',
      order_shipped: 'Shipped',
      order_status: 'Order status',
      order_success: 'Order placed successfully!',
      order_without_paying: 'Create order without paying',
      orders: 'Orders',
      other: 'Other',
      out_of_stock: 'Out of stock',
      package: 'Package',
      package_and_weight: 'Package details',
      package_info: 'Package details',
      packing_list_will_print:
        'The packing list will be printed immediately after clicking confirm',
      paid: 'Paid',
      partially_paid: 'Partially paid',
      partially_shipped: 'Partially shipped',
      pay_with_credit: 'Pay with credit card',
      payment_error: 'Payment error',
      payment_failed: 'Payment failed',
      payment_information: 'Payment information',
      payment_issue: 'Payment issue',
      payment_method: 'Payment method',
      payment_status: 'Payment status',
      payment_success: 'Payment successfully!',
      pending: 'Pending',
      please_fetch_data: 'Please fetch data',
      please_provide_shipping: 'Please provide shipping information',
      please_select_shipping: 'Please select shipping method',
      pos_coupon: 'POS coupon',
      print_invoice: 'Print invoice',
      print_packing_list: 'Print packing list',
      print_shipping_label: 'Print shipping label',
      process_order: 'Process order',
      product: 'Product',
      products: 'Products',
      promotion_discount: 'Promotion discount',
      provider: 'Provider',
      quantity: 'Quantity',
      reason: 'Reason',
      refulfill_order: 'Re-fulfill order',
      refund: 'Refund',
      refunded: 'Refunded',
      residential_address: 'Residential',
      sales: 'Sales',
      sales_tax: 'Sales tax',
      search_by_code: 'By order #',
      search_by_cus_name: 'By customer name',
      select_coupons: 'Select coupons',
      select_customer: 'Select customer',
      select_items: 'Select {{total}} items',
      select_shipment: 'Select fulfillment type',
      select_status: 'Select status',
      seller_coupon: 'Seller coupon',
      send_noti_to_customer: 'Send notification to customer',
      send_order_status: 'Send order status via SMS',
      send_payment_link: 'Send payment link to customer',
      send_payout: 'Send payout',
      shipment: 'Shipment type',
      shipment_custom: 'Entered custom fulfillment',
      shipment_manual: 'Entered tracking information',
      shipment_shippo: 'Bought shipping label',
      shipped: 'Shipped',
      shipping: 'Shipping',
      shipping_address: 'Shipping address',
      shipping_carrier: 'Shipping carrier',
      shipping_information: 'Shipping information',
      shipping_label: 'Shipping label',
      shipping_method: 'Shipping method',
      shipping_rates: 'Shipping rates',
      size: 'Dimensions',
      sku: 'SKU',
      source_admin: 'Admin panel',
      source_app: 'Mobile app',
      source_from: 'Order source',
      source_web: 'Website',
      store: 'Store',
      subtotal: 'Subtotal',
      summary: 'Summary',
      sync_tracking_status: 'Sync tracking status',
      taxes: 'Taxes',
      total: 'Total',
      total_pricing: 'Total',
      total_weight_with_package: 'Weight (with package)',
      tracking_number: 'Tracking number',
      unit_price: 'Unit price',
      unpaid: 'Unpaid',
      variant: 'Variant',
      view_order: 'View order',
      void_custom_fulfillment: 'Void custom fulfillment',
      void_information: 'Void tracking information',
      void_label: 'Void shipping label',
      write_comment: 'Write comment',
      wrong_address: 'Wrong shipping address',
      you_want_accept_order: 'Do you want to accept this order #{{code}} ?',
      you_want_cancel_order: 'Do you want to cancel this order #{{code}} ?',
    },
    packages: {
      add_package: 'Add package',
      default_package: 'Default package',
      default_package_label:
        'Used to calculate live shipping rates at checkout.',
      edit_package: 'Edit package',
      height: 'Height',
      length: 'Length',
      name: 'Name',
      package: 'Package',
      package_name: 'Package name',
      packages: 'Packages',
      packages_decs:
        'Predefined package templates used when you buy shipping labels.',
      weight: 'Weight',
      width: 'Width',
    },
    partners: {
      add_partner: 'Add partner',
      edit_partner: 'Edit partner',
      partner: 'Partner',
      partner_desc:
        'Manage and assign roles for partners, allowing for streamlined collaboration and clear responsibilities.',
      partner_name: 'Partner name',
      partners: 'Partners',
      update_partner: 'Update partner',
    },
    payouts: {
      balance: 'Balance',
      balance_details: 'Balance by orders',
      balance_seller: 'Seller',
      completed_date: 'Completed date',
      earnings: 'Earnings',
      finances: 'Finances',
      net: 'Net',
      no_payout: 'No payout...',
      paid: 'Paid',
      paid_date: 'Paid date',
      pay: 'Pay',
      payout: 'Payout',
      payout_details: 'Payouts of order',
      payout_period: 'Payout period',
      payouts: 'Payouts',
      platform_fee: 'Platform fee 10%',
      transaction_fee: 'Transaction fee 3%',
      transaction_id: 'Transaction ID',
      update_payout: 'Update payout',
    },
    products: {
      activity: 'Activity',
      add_product: 'Add product',
      add_variant: 'Add another variant',
      allow_purchase: 'Allow purchase',
      approve_product: 'Approve product',
      approve_product_desc:
        'Are you sure you want to approve the selected products? Once approved, these products will be published on the app. Sellers must resubmit them for review if any updates are made.',
      arrange_variants: 'Arrange variants',
      availability: 'Availability',
      availability_desc:
        'To hide this variant on your store, unselect "Available on store".',
      available_on_store: 'Available on store',
      available_to_buy: 'Available on store',
      banner: 'Banner',
      barcode: 'Barcode',
      brand: 'Brand',
      brand_desc:
        'Select GENERIC for unbranded products. Please contact us if you want to add your brand.',
      brands: 'Brands',
      brands_not_found: 'Brands not found',
      build_import_subtitle:
        'Upload multiple products at once by filling out products default in a spreadsheet file.',
      bulk_import: 'Bulk import',
      bulk_update: 'Bulk update',
      can_draft: "Product can't be saved as draft or closed",
      can_draft_desc:
        'This product can only be saved as draft or closed if all its variants are set to unavailable. Ensure all variants have the "Available on store" option switched off.',
      can_publish: "Product can't be published",
      can_publish_desc:
        'This product can only be saved as active and published in the app if it has at least one active variant. Ensure at least one variant has the "Available on store" option switched on.',
      categories: 'Categories',
      categories_not_found: 'Categories not found',
      category: 'Category',
      close_product: 'Close product',
      correction: 'Correction',
      count: 'Count',
      created_user: 'Created user',
      damage: 'Damage',
      date: 'Date',
      delete_product: 'Delete product',
      dimensions: 'Dimensions',
      dimensions_lwh: 'Dimensions (Length, Width, Height)',
      download_template: 'Download template',
      edit_product: 'Edit product',
      export: 'Export',
      export_import: 'Export & Import',
      export_product_desc:
        'Use pre-defined templates for bulk product updates efficiently.',
      file_id: 'File ID',
      file_is_uploaded: 'File is uploaded',
      file_name: 'Filename',
      generate_export: 'Generate export',
      import: 'Import',
      import_id: 'Import ID',
      import_inventory: 'Inventory data',
      import_pricing: 'Product data',
      import_product_desc_1: 'Max size: 5 MB only in XLSX, XLS, or CSV file.',
      import_product_desc_2:
        'Please upload the latest template to avoid import errors.',
      import_product_desc_3:
        'Please do not edit your product until the tasks have been completed.',
      import_status: 'Import status',
      inventory: 'Inventory',
      inventory_adjustment: 'Inventory adjustment',
      inventory_count: 'Inventory count',
      inventory_desc: `To update stock inventory information: Stock quantity`,
      inventory_low_level: 'Inventory low level',
      inventory_low_level_desc: `Show a low-stock message "Hurry up! Only X in stock..." when the inventory count is below this level. Default is 5 if not set.`,
      make_all_as: 'Make all variants as',
      material: 'Material',
      no_category: 'No category...',
      no_information: 'No information...',
      no_product: 'No product...',
      no_promotion: 'No promotion...',
      no_sub_category: 'No subcategory...',
      order_canceled: 'Order canceled',
      order_created: 'Order created',
      original_price: 'Original price',
      original_price_desc: `To show a discount price, move the product’s original price into "Original price". Enter a lower value into "Price".`,
      other: 'Other',
      price: 'Price',
      pricing: 'Pricing',
      pricing_desc: `To update product pricing information: Price, Original price, SKU, Barcode`,
      product: 'Product',
      product_descriptions: 'Product description',
      product_details: 'Product details',
      product_id: 'Product ID',
      product_name: 'Product name',
      product_pending_alert: 'Product pending approval',
      product_pending_alert_desc:
        'This product is pending admin approval and cannot be updated at this time. You can make changes once it is approved.',
      product_rejected_alert: 'Product rejected',
      product_rejected_alert_desc:
        'This product has been rejected due to policy violations. Please revise and resubmit it for approval.',
      product_reviews: 'Product reviews',
      product_selected: 'product(s) selected',
      product_slug: 'Product slug',
      product_status: 'Product status',
      product_submit_approval: 'Submit for approval',
      product_submit_approval_desc:
        'Are you sure you want to submit the selected products for admin approval? Once submitted, these products cannot be updated while under review.',
      product_submit_failed: 'Submit failed',
      product_submit_success: 'Submit successfully',
      product_variants: 'Product variants',
      products: 'Products',
      purchase_limit: 'Purchase limit',
      purchase_limit_desc:
        'Set a limit how many a customer can buy within a certain period',
      purchase_limit_per_type_day: 'per day',
      purchase_limit_per_type_month: 'per month',
      purchase_limit_per_type_week: 'per week',
      quantity: 'Quantity',
      ratings: 'Ratings',
      reason: 'Reason',
      reject_product: 'Reject product',
      reject_product_desc:
        'Are you sure you want to reject the selected products? Once rejected, these products will not be published on the app. Sellers will need to update and resubmit them for review.',
      returns: 'Returns',
      search_by_address: 'By address',
      search_by_business_name: 'By business name',
      search_by_business_phone: 'By business phone',
      search_by_code: 'By code',
      search_by_desc: 'By description',
      search_by_email: 'By email',
      search_by_name: 'By name',
      search_by_note: 'By note',
      search_by_phone: 'By phone',
      search_by_value: 'By value',
      select_brand: 'Select brand',
      select_category: 'Select category',
      select_seller: 'Select seller',
      select_store: 'Select store',
      select_subcategory: 'Select subcategory',
      sellers_not_found: 'Sellers not found',
      sellers_with_multi_warehouse: 'Sellers with multiple warehouses',
      sellers_without_warehouse: 'Sellers without warehouses',
      service: 'Service',
      shipping: 'Shipping',
      similar_products: 'Similar products',
      size: 'Size',
      sku: 'SKU',
      sold: 'Sold',
      start_import_flow: 'Start import flow',
      stock: 'Stock',
      stock_item_activity_correction: 'Correction',
      stock_item_activity_count: 'Count',
      stock_item_activity_damage: 'Damage',
      stock_item_activity_initial_inventory: 'Initial inventory',
      stock_item_activity_other: 'Other',
      stock_item_activity_returns: 'Return',
      stock_item_activity_transfer: 'Transfer',
      stock_item_type_add: 'Add',
      stock_item_type_deduct: 'Deduct',
      stock_item_type_update: 'Update',
      store: 'Store',
      stores: 'Stores',
      subcategories: 'Subcategories',
      subcategories_not_found: 'Subcategories not found',
      subcategory: 'Subcategory',
      template: 'Template',
      transfer: 'Transfer',
      upload_file: 'Upload file',
      validated_success: 'Validated successfully',
      validated_with_errors: 'Validated with errors',
      variant: 'Variant',
      variant_barcode: 'Barcode (UPC, GTIN, ISBN, etc.)',
      variant_description: 'Variant description',
      variant_id: 'Variant ID',
      variant_name: 'Variant name',
      variant_sku: 'SKU (Stocking Keeping Unit)',
      variant_slug: 'Variant slug',
      variants: 'Variants',
      variants_error_at: 'An error occurred in the variants: {{indexes}}',
      videos_limit_tooltip: 'Max 50 MB each video',
      visible_marketplace: 'Visible on Marketplace',
      warehouse: 'Warehouse',
      warehouse_not_found: 'Warehouse not found',
      weight: 'Weight',
      weight_desc: 'Used to calculate shipping costs at checkout.',
    },
    promotions: {
      add_banner: 'Add banner',
      add_sale: 'Add flash sale',
      all_selected_promotions: 'All selected promotions',
      apply_time: 'Apply time',
      arrange_banners: 'Arrange banners',
      available_stock: 'Available stock',
      banner: 'Banners',
      banner_desc: 'Banner will be displayed in the marketplace homepage.',
      bonus_gift: 'Bonus gifts',
      bonus_gift_desc:
        'A free gift that is added to the cart when a customer purchases a specific product or spends a certain amount.',
      combo: 'Combo deals',
      combo_desc:
        'Combo is a set of products that are sold together at a discount.',
      coupon_code: 'Coupons',
      coupon_code_desc:
        'A coupon code is a string of characters that customers can enter at checkout to apply a discount to their order.',
      create_promotion: 'Create new promotions',
      customer_can_buy_any: 'Customer can buy any',
      delete_item: 'Delete',
      description: 'Description',
      discount: 'Flash sales',
      discount_amount: 'Discount amount',
      discount_desc:
        'Flash sale is a limited-time discount on a product or products.',
      discount_price: 'Discount price',
      edit_promotion: 'Edit promotions',
      end_date: 'Valid until',
      existing_variants: 'Items already in another promotion',
      find_by_brand: 'Find by brand',
      find_by_cate: 'Find by category',
      find_by_name: 'Search by item name or SKU',
      find_by_seller: 'Find by seller',
      for_new_user: 'For new user',
      item_discount: 'Item discounts',
      item_discount_desc:
        'A discount on a product or products, which can be applied to a specific product or all products in a category.',
      item_per_order: 'Limit item',
      max_purcharse_product: 'Maximum purchase product',
      original_price: 'Original price',
      product_list: 'Product list',
      product_s: 'product(s)',
      promotion_name: 'Promotion name',
      promotion_sku: 'Items',
      promotion_slug: 'Promotion slug',
      remove_items: 'Remove {{items}} items',
      save_and_select_product: 'Save and select products',
      save_info: 'Save information',
      select_products: 'Select products',
      selected_products: 'Seleted items',
      selling_price: 'Selling price',
      shipping_discount: 'Shipping discounts',
      shipping_discount_desc:
        'A discount on shipping, which can be applied to all shipping methods or specific shipping methods.',
      start_date: 'Valid from',
      status: 'Status',
      status_type: 'Time status',
      sub_title: 'Subtitle',
      title: 'Title',
      total_item: 'Total item',
      type: 'Type',
    },
    push_notifications: {
      message: 'Message',
      push_notifications: 'Push notifications',
      send_notification: 'Send push notification',
      title: 'Title',
    },
    register: {
      after_that: `After that, we'll charge`,
      and: 'and',
      and_only: 'and only',
      become_our: 'Become Nailzy',
      calculate: 'Calculate selling fees',
      contact: 'Contact seller support',
      create_a: 'Create a',
      customer: 'Customer service & support',
      customer_content:
        'We provide exceptional customer service and support, with a dedicated team and comprehensive knowledge base to help you.',
      discount: 'Discount',
      each_order: 'for each order. ',
      easy: 'Easy to use',
      easy_content:
        'Easily manage product listings, inventory, and sales from any device with our user-friendly platform for on-the-go store management.',
      fast: 'Fast & free to join',
      fast_content:
        'At Nailzy.com, we offer a seamless process for opening an online store. Our registration process is completely free and easy.',
      for_your: 'for your first 3 months of selling on Nailzy.',
      form: {
        account_registration: 'Account',
        address: 'Address',
        back: 'Back',
        business_email: 'Business email',
        business_name_placeholder: 'Salon name, store name',
        business_type: 'Business type',
        company: 'Company',
        confirm_new_password: 'Confirm new password',
        confirm_password: 'Confirm password',
        create_account: 'Create account',
        current_password: 'Current password',
        department: 'Department',
        didnt_otp: `Didn't get the code?`,
        done: 'Done',
        email: 'Email',
        email_existed: 'Email existed',
        email_verification: 'Email verification',
        first_name: 'First name',
        individual: 'Individual',
        last_name: 'Last name',
        legal_business_name: 'Legal business name',
        mobile: 'Mobile phone',
        new_password: 'New password',
        our_team:
          'A representative will reach out to you in the coming days to help you get started with the onboarding process. Stay tuned!',
        password: 'Password',
        phone: 'Phone',
        please_otp:
          'Please enter the 4-digit verification code sent to your email',
        read_agree: `I've read and agree to the`,
        register_content: `Please provide your account information.`,
        resend_otp: 'Resend',
        seller_agreement: 'Seller Agreement',
        seller_information: 'Seller information',
        store_address: 'Store address',
        store_email: 'Store email',
        store_logo: 'Store logo',
        store_name: 'Store name',
        store_phone: 'Store phone',
        tax_id: 'Tax ID (EIN, ITIN, or SSN)',
        thank_you: 'Thank you for joining as Nailzy seller!',
        verification_code: 'Verification code',
        verify_email: 'Verify email',
      },
      free: 'FREE',
      how_to_sell: 'How to sell on Nailzy.com marketplace',
      increased: 'Increased visibility & reach',
      increased_content:
        'List your nail products on Nailzy.com for targeted visibility and reach to a larger audience of professionals and enthusiasts.',
      marketing: 'Marketing & promotions',
      marketing_content:
        'Boost your sales with our marketing tools by creating discounts, promotions, and campaigns, reaching a wider customer base.',
      net_sales: 'Net sales',
      order_total: 'Order total',
      platform: '10% for the platform fee',
      platform_fee: 'Platform fee 10%',
      sales_tax: 'Sales tax',
      sell_on: 'Sell on Nailzy.com marketplace',
      seller_account: 'seller account in only',
      selling_partner: 'selling partner',
      shipping: 'Shipping',
      simple: '3 simple steps',
      step_1: 'Register a seller account',
      step_1_1: 'Fill out your account information',
      step_1_2: 'Provide your business documents',
      step_1_3: 'Get approved and start selling',
      step_2: 'List products',
      step_2_1: 'Upload a single product',
      step_2_2: 'Import bulk products',
      step_3: 'Manage orders',
      step_3_1: 'Get notified when you receive an order',
      step_3_2: 'Process orders and print shipping labels',
      step_3_3: 'Ship your items to the customer',
      step_4: 'Get paid',
      step_4_1: 'Get a monthly report of your sales',
      step_4_2: 'Get paid via direct deposit or checks',
      subtotal: 'Subtotal',
      the_fees:
        'The fees are calculated based on your total sales after accounting for any discounts, shipping fees, and sales taxes.',
      there_are: 'There are',
      to_sell: 'to sell your products on our platform and get paid.',
      transaction: '3% for the transaction fee',
      transaction_fee: 'Transaction fee 3%',
      unique: 'Unique features',
      unique_content:
        'We offer many features for nail professionals and businesses, including job postings, technician profiles, salon sales, and classes.',
      why_sell: 'Why Sell on Nailzy.com Marketplace?',
      why_sell_content:
        'Nailzy.com is a pioneer app providing a one-stop-shop for all things related to the nail industry: find a job, hire a tech, or sell nail salons. Nailzy marketplace is the Amazon of the nail industry, providing manufacturers and suppliers of nail products with a platform to promote and sell their products.',
      your_payout: 'Your payout',
      zero_fees: 'ZERO platform fee',
    },
    reviews: {
      acceptable: 'Acceptable',
      add_review_success: 'Add review success',
      delivery_packaging: 'Shipping & packaging',
      dissatisfied: 'Dissatisfied',
      edit_review: 'Edit review',
      enthusiastic_seller: 'Seller',
      good: 'Good',
      other_reason: 'Other',
      product_quality: 'Product quality',
      rating: 'Rating',
      reasonable_price: 'Price',
      review: 'Review',
      reviews_count: 'reviews',
      satisfied: 'Satisfied',
      tags: 'Tags',
      very_satisfied: 'Very satisfied',
    },
    seller_panel: 'Seller panel',
    sellers: {
      add_seller: 'Add seller',
      completed_orders: 'Completed Orders',
      completed_orders_decs: 'The number of orders marked as completed',
      completed_sales: 'Completed Sales',
      completed_sales_decs: 'The total value of all completed orders',
      create_warehouse: 'Create warehouse',
      delete_seller: 'Delete seller',
      edit_bank: 'Edit bank information',
      edit_seller: 'Edit seller profile',
      edit_store: 'Edit store details',
      edit_warehouse: 'Edit warehouse',
      no_seller_found: 'No seller found!',
      seller: 'Seller',
      seller_profile: 'Seller profile',
      seller_selected: 'sellers selected',
      store_address: 'Store address',
      total_orders: 'Total Orders',
      total_orders_decs: 'The total number of orders',
      total_sales: 'Total Sales',
      total_sales_decs: 'The total value of all orders',
      warehouse: 'Warehouse',
    },
    shipping: {
      add_flat_rate: 'Add flat rate',
      amount: 'Amount',
      amount_estimated: 'Amount & estimated delivery days',
      base_on_price: 'Based on order price',
      base_on_weight: 'Based on order weight',
      calculated: 'To be calculated',
      carrier_name: 'Carrier name',
      condition_pricing: 'Conditional pricing',
      conditions: 'Conditions',
      confirm_update_shipping_rate: 'Do you want to change shipping rate to ',
      countries_and_states: 'Countries & states',
      delete_shipping_rate: 'Delete shipping rate',
      estimated_days: 'Estimated delivery day',
      flat_rate: 'Flat rates',
      flat_rate_desc: 'Charge a specific shipping amount at checkout.',
      live_rate: 'Live rates',
      live_rate_desc:
        'Shipping fees automatically determined at checkout by a third-party service.',
      manage_shipping_zones: 'Manage shipping zones',
      max_price: 'Maximum price',
      max_price_must_grater_than_min:
        'Max price must be greater than or equal to Min price',
      max_weight: 'Maximum weight',
      max_weight_must_grater_than_min:
        'Max weight must be greater than or equal to Min weight',
      min_price: 'Minimum price',
      min_price_must_grater_than_0:
        'Min price must be greater than or equal to 0',
      min_weight: 'Minimum weight',
      min_weight_must_grater_than_0:
        'Min weight must be greater than or equal to 0',
      rate_name: 'Rate name',
      shipping_desc:
        'Config shipping fees at checkout and manage available shipping zones.',
      shipping_rates: 'Shipping rates',
      shipping_zones: 'Shipping zones',
      shipping_zones_desc: 'Where orders can be shipped to.',
      states: 'States',
      update_flat_rate: 'Update flat rate',
      update_shipping_rate: 'Change shipping rate',
    },
    skills: {
      add_skill: 'Add skill',
      no_skill_found: 'No skill found!',
      skill: 'Skill',
      skill_name: 'Skill name',
      skill_selected: 'skills selected',
    },
    system_users: {
      action_delete: 'DELETE',
      action_get: 'VIEW',
      action_post: 'CREATE',
      action_put: 'UPDATE',
      add_role: 'Add role',
      add_system_user: 'Add system user',
      edit_role: 'Edit role',
      edit_system_user: 'Edit system user',
      is_super_admin: 'Is Super Admin?',
      permissions: 'Permissions',
      role_and_permission: 'Roles & permissions',
      role_name: 'Role name',
      roles: 'Roles',
      roles_decs:
        'With roles, you can manage permissions by granting or denying access to specific features.',
      system_users: 'System users',
      system_users_decs:
        'Manage and assign roles and permissions for internal individuals.',
    },
    warehouses: {
      inventory_quantity: 'Inventory quantity',
      page_sub_title:
        'Manage the places you stock inventory, fulfill order, and sell products.',
      product_variant: 'Total product variants',
      warehouse_address: 'Address',
      warehouse_name: 'Warehouse name',
      warehouse_status: 'Warehouse status',
    },
  },
};

export default en;
