import { Anchor, Badge, Button, Flex, Grid, Select } from '@mantine/core';
import { CommonTableColumn, PackageItem, PackageTypeEnum } from 'types';
import { useDeletePackage, useGetPackages } from 'apis/PackagesApi';
import { useUpdateSellerProfile } from 'apis/SellersApi';
import {
  SectionBody,
  SectionFooter,
  SectionHeader,
  SectionWrapper,
} from 'components/MantineUI/CommonSection';
import { CommonTable } from 'components/MantineUI/CommonTable';
import DropdownButton from 'components/MantineUI/Buttons/DropdownButton';
import IconFA from 'components/common/IconFA';
import { useURLParams } from 'hooks/useURLParams';
import { t } from 'i18next';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { usePaginationParams } from 'hooks/usePaginationParams';
import PackageDetailModal from './components/PackageDetailModal';
import { imageUrlToId } from 'pages/dashboard/components/helpers';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';
import PageLayout from 'components/MantineUI/PageLayout';
import SkeletonWrapper from 'components/MantineUI/Skeleton/SkeletonWrapper';
import SectionPageSkeleton from 'components/MantineUI/Skeleton/SectionPageSkeleton';
import { useUserProfile } from 'apis/AuthApis';
import DeleteWarningModal from 'components/common/DeleteWarningModal';

export const getPackageTypeName = (title: string) => {
  switch (title) {
    case PackageTypeEnum.BOX:
      return t('Box');
    case PackageTypeEnum.ENVELOPE:
      return t('Envelope');
    default:
      return t('Box');
  }
};

type PropType = {
  children?: ReactNode;
  className?: string;
};

const PackageListPage = () => {
  // ### CONSTANTs
  const queryClient = useQueryClient() as any;
  const { params }: any = useURLParams();
  // seller profile
  const {
    data: profileResponse,
    isFulfillNailzy: isFBN,
    isFulfillSeller: isSellerFulfillType,
    isSeller,
  } = useUserProfile();
  // const isSeller = user?.type === APP_ROLES.seller;
  const [selectedDefaultPackage, setSelectedDefaultPackage] = useState<any>();
  const [packageToDelete, setPackageToDelete] = useState<any>(false);

  // package list
  const { page, limit, onPaginationChange } = usePaginationParams();

  const {
    data: res,
    isFetching,
    isSuccess,
    isLoading,
  }: any = useGetPackages({
    ...params,
    page: page,
    limit: limit,
    fulfill_type: isSeller ? undefined : 'nailzy',
  });
  const data = res?.data?.results || [];
  const packageOptions = data?.map((item: PackageItem) => ({
    value: item._id,
    label: `${item.name}: ${item.length} x ${item.width} x ${item.height} ${item.unit}, ${item.weight} ${item.weight_unit}`,
  }));
  const { mutate: updateProfile, isPending } = useUpdateSellerProfile();

  const seller = profileResponse?.seller;
  const [selectedPackage, setSelectedPackage] = useState<
    PackageItem | null | {}
  >(null);
  useEffect(() => {
    setSelectedDefaultPackage(seller?.default_package_id);
  }, [seller]);
  // ### FUNCTIONs
  const handleSelectedDefaultPackage = (value: any) => {
    setSelectedDefaultPackage(value);
  };

  const { mutate: deletePackage } = useDeletePackage();
  const onDelete = () => {
    deletePackage(packageToDelete?._id, {
      onSuccess: () => {
        toast.success(t('general.delete_success'));
        setPackageToDelete(null);
        setTimeout(() => {
          queryClient.invalidateQueries(['useGetPackages']);
        }, 500);
      },
    });
  };

  const handleSaveDefaultPackage = () => {
    const clonedSeller = structuredClone(seller);
    clonedSeller.logo_id = imageUrlToId(clonedSeller?.logo_url);
    clonedSeller.banner_ids = clonedSeller.banner_urls?.map((x: any) =>
      imageUrlToId(x),
    );
    clonedSeller.legal_business_name = clonedSeller?.legal_business_name || '';
    delete clonedSeller?.logo_url;
    delete clonedSeller?.banner_urls;
    delete clonedSeller?.updated_on;
    delete clonedSeller?.created_on;

    delete clonedSeller?.address?._id;
    delete clonedSeller?.status;
    delete clonedSeller?.slug;
    delete clonedSeller?._id;

    updateProfile(
      {
        first_name: profileResponse?.first_name,
        last_name: profileResponse?.last_name,
        profile_image_id: imageUrlToId(profileResponse?.profile_image_url),
        seller: {
          ...clonedSeller,
          default_package_id: selectedDefaultPackage,
        },
      } as any,
      {
        onSuccess: () => {
          toast.success(t('general.update_success'));
        },
        onError: () => {
          toast.error(t('general.update_fail'));
        },
      },
    );
  };
  const handleResetDefaultPackage = () => {
    setSelectedDefaultPackage(seller?.default_package_id);
  };

  // ### RENDERs

  const columns: CommonTableColumn[] = useMemo(
    () => [
      {
        accessor: 'name',
        Header: t('packages.package_name'),
        Cell: (rowData: any) => {
          const { name, is_default } = rowData;
          return (
            <Flex gap={'xs'}>
              <Anchor onClick={() => setSelectedPackage(rowData)} size="sm">
                {name}
              </Anchor>
              {is_default ? (
                <Badge color="gray" variant="light">
                  {t('general.default')}
                </Badge>
              ) : null}
            </Flex>
          );
        },
      },
      {
        accessor: 'type',
        Cell: (row: any) => (row.type ? getPackageTypeName(row.type) : '--'),
        Header: t('general.type'),
      },
      {
        accessor: 'size',
        Cell: (row: any) =>
          `${row?.length || 0} x ${row?.width || 0} x ${row?.height || 0} ${
            row?.unit || ''
          }`,
        Header: t('orders.size'),
      },
      {
        accessor: t('products.weight'),
        Cell: (row: any) => `${row?.weight || 0} ${row?.weight_unit || ''}`,
        Header: t('products.weight'),
      },
      {
        accessor: 'actions',
        Cell: (rowData) => {
          return isFBN ? null : (
            <DropdownButton
              button={
                <Button variant="subtle" color="gray" size="xs">
                  <IconFA icon={'ellipsis'} />
                </Button>
              }
              dropdownData={[
                {
                  key: 'edit',
                  label: t('general.edit'),
                  onClick: () => setSelectedPackage(rowData),
                },
                { isDivider: true },
                {
                  key: 'cancel_order',
                  label: t('general.delete'),
                  color: 'red',
                  onClick: () => {
                    setPackageToDelete(rowData);
                  },
                },
              ]}
            />
          );
        },
      },
    ],
    [],
  );
  return (
    <SkeletonWrapper show={isLoading} skeleton={<SectionPageSkeleton />}>
      <PageLayout
        title={t('packages.packages')}
        breadCrumds={[{ title: t('packages.packages') }]}
      >
        <Grid>
          <Grid.Col span={{ base: 12, sm: isSellerFulfillType ? 8 : 12 }}>
            <SectionWrapper>
              <SectionHeader
                actionButtons={
                  isFBN ? null : (
                    <Button
                      onClick={() => {
                        setSelectedPackage({});
                      }}
                    >
                      <span className="d-sm-inline-block">{`${t(
                        'general.add',
                      )} ${t('packages.package').toLocaleLowerCase()}`}</span>
                    </Button>
                  )
                }
                title={t('packages.packages')}
                subtitle={t('packages.packages_decs')}
              ></SectionHeader>
              <SectionBody>
                <CommonTable
                  data={data}
                  columns={columns}
                  loading={isFetching}
                  pagination={{
                    page,
                    limit,
                    total: res?.data?.total,
                    onPaginationChange,
                  }}
                />
              </SectionBody>
            </SectionWrapper>
          </Grid.Col>
          {isSellerFulfillType ? (
            <Grid.Col span={{ base: 12, sm: 4 }}>
              <SectionWrapper>
                <SectionHeader
                  isSubSection
                  title={t('packages.default_package')}
                  subtitle={t('packages.default_package_label')}
                />
                <SectionBody>
                  <Select
                    data={packageOptions}
                    value={selectedDefaultPackage}
                    onChange={handleSelectedDefaultPackage}
                    placeholder="Select..."
                    clearable
                  />
                </SectionBody>
                <SectionFooter>
                  <Button
                    onClick={handleSaveDefaultPackage}
                    loading={isPending}
                    disabled={!selectedDefaultPackage}
                  >
                    {t('general.save')}
                  </Button>
                  <Button variant="default" onClick={handleResetDefaultPackage}>
                    {t('general.reset')}
                  </Button>
                </SectionFooter>
              </SectionWrapper>
            </Grid.Col>
          ) : null}
        </Grid>

        {/* MODAL */}
        <PackageDetailModal
          packageData={selectedPackage as PackageItem}
          onClose={() => setSelectedPackage(null)}
        />
        <DeleteWarningModal
          name={packageToDelete?.name}
          onShow={!!packageToDelete}
          onClose={() => setPackageToDelete(null)}
          onDelete={onDelete}
        />
      </PageLayout>
    </SkeletonWrapper>
  );
};
export default PackageListPage;
