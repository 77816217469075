import { StatusEnum } from './common.type';

export enum InquiryAdminSort {
  CREATED_ON_DESC = 'created_on_desc',
  CREATED_ON_ASC = 'created_on_asc',
  UPDATED_ON_DESC = 'updated_on_desc',
  UPDATED_ON_ASC = 'updated_on_asc',
  FIRST_NAME_DESC = 'first_name_desc',
  FIRST_NAME_ASC = 'first_name_asc',
  BUSINESS_NAME_DESC = 'business_name_asc',
  BUSINESS_NAME_ASC = 'business_name_desc',
}

export type InquiryItem = {
  _id: string;
  customer_id: string;
  seller_id: string;
  source_from: string;
  status: StatusEnum;
  first_name: string;
  last_name: string;
  business_name: string;
  phone_number: string;
  email: string;
  message: string;
  items: [
    {
      _id: string;
      product_id: string;
      product_name: string;
      product_slug: string;
      variant_id: string;
      variant_name: string;
      variant_slug: string;
      price: number;
      discount_price: number;
    },
  ];
  created_on: number;
  updated_on: number;
};
