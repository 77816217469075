import { Text } from '@mantine/core';
import { ShippingInformationTypeEnum } from 'constants/orders';
import { useTranslation } from 'react-i18next';

type PropType = {
  shipment?: any;
};

const CustomShippingItems = (props: PropType) => {
  // ### CONSTANTs
  const { t } = useTranslation();
  const { shipment } = props;

  // ### FUNCTIONs
  const hasShipmentWithCustom =
    shipment?.type === ShippingInformationTypeEnum.Custom;

  // ### RENDERs
  return hasShipmentWithCustom ? (
    <>
      <Text fw={'600'}>{t('orders.custom_fulfillment')}</Text>
      <Text size="sm">{shipment?.note ?? ''}</Text>
    </>
  ) : null;
};
export default CustomShippingItems;
