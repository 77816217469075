import { CommonTableColumn } from './table.type';

export type OrderItem = any;
export type OrderListProps = {
  data: OrderItem;
  total?: number;
  page?: number;
  limit?: number;
  customColumn?: CommonTableColumn[];
  hideHeader?: boolean;
  hideSort?: boolean;
  hideFilter?: boolean;
  hideCard?: boolean;
};

export enum OrderPaymentStatusEnum {
  ALL = '',
  UNPAID = 'unpaid',
  PAID = 'paid',
  REFUNDED = 'refunded',
  AWAITING_PAYMENT = 'awaiting_payment',
  PAYMENT_FAILED = 'payment_failed',
}

export enum OrderStatusEnum {
  ALL = 'all',
  PENDING = 'pending',
  PROCESSING = 'processing',
  SHIPPED = 'shipped',
  DELIVERED = 'delivered',
  COMPLETED = 'completed',
  CANCELED = 'canceled',
  FAILED_DELIVERY = 'failed_delivery',
}

export type OrderStatusType = {
  [key in OrderStatusEnum]: {
    label: string;
    bg: string;
    icon: string;
  };
};

export const OrderStatuses: OrderStatusType = {
  [OrderStatusEnum.ALL]: {
    label: 'orders.order_all',
    bg: '',
    icon: '',
  },
  [OrderStatusEnum.PENDING]: {
    label: 'orders.order_pending',
    bg: 'warning',
    icon: 'clock',
  },
  [OrderStatusEnum.PROCESSING]: {
    label: 'orders.order_processing',
    bg: 'warning',
    icon: 'rotate',
  },
  [OrderStatusEnum.SHIPPED]: {
    label: 'orders.order_shipped',
    bg: 'info',
    icon: 'truck',
  },
  [OrderStatusEnum.DELIVERED]: {
    label: 'orders.order_delivered',
    bg: 'success',
    icon: 'check-circle',
  },
  [OrderStatusEnum.COMPLETED]: {
    label: 'orders.order_completed',
    bg: 'success',
    icon: 'check-circle',
  },
  [OrderStatusEnum.FAILED_DELIVERY]: {
    label: 'orders.order_failed_delivery',
    bg: 'danger',
    icon: 'triangle-exclamation',
  },
  [OrderStatusEnum.CANCELED]: {
    label: 'orders.order_canceled',
    bg: 'gray',
    icon: 'ban',
  },
};

export const OrderTabStatuses = {
  [OrderStatusEnum.ALL]: {
    label: 'orders.order_all',
  },
  [OrderPaymentStatusEnum.AWAITING_PAYMENT]: {
    label: 'orders.awaiting_payment',
  },
  [OrderStatusEnum.PENDING]: {
    label: 'orders.order_pending',
  },
  [OrderStatusEnum.PROCESSING]: {
    label: 'orders.order_processing',
  },
  [OrderStatusEnum.SHIPPED]: {
    label: 'orders.order_shipped',
  },
  [OrderStatusEnum.DELIVERED]: {
    label: 'orders.order_delivered',
  },
  [OrderStatusEnum.COMPLETED]: {
    label: 'orders.order_completed',
  },
  [OrderStatusEnum.FAILED_DELIVERY]: {
    label: 'orders.order_failed_delivery',
  },
  [OrderStatusEnum.CANCELED]: {
    label: 'orders.order_canceled',
  },
};

export enum OrderSourceEnum {
  WEB = 'web',
  APP = 'app',
  ADMIN = 'admin',
}
export enum OrderFulfillTypeEnum {
  NAILZY = 'nailzy',
  SELLER = 'seller',
}

export enum CartStatusEnum {
  CLOSED = 'closed',
  DRAFT = 'draft',
  COMPLETED = 'completed',
}

export type CartStatusType = {
  [key in CartStatusEnum]: {
    label: string;
    bg: string;
    icon: string;
  };
};

export const CartStatuses: CartStatusType = {
  [CartStatusEnum.CLOSED]: {
    icon: 'archive',
    bg: 'gray',
    label: 'general.closed',
  },
  [CartStatusEnum.DRAFT]: {
    icon: 'save',
    bg: 'info',
    label: 'general.draft',
  },
  [CartStatusEnum.COMPLETED]: {
    icon: 'check-circle',
    bg: 'success',
    label: 'general.completed',
  },
};

export enum ShippingStatus {
  UNKNOWN = 'UNKNOWN',
  PRE_TRANSIT = 'PRE_TRANSIT',
  TRANSIT = 'TRANSIT',
  DELIVERED = 'DELIVERED',
  RETURNED = 'RETURNED',
  FAILURE = 'FAILURE',
}
