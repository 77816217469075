import { Box, LoadingOverlay } from '@mantine/core';
import React, { ReactNode } from 'react';
type PropType = {
  children?: ReactNode;
  loading?: boolean;
};
const LoadingOverlayWrapper = (props: PropType) => {
  // ### CONSTANTs
  const { children, loading = false } = props;
  // ### FUNCTIONs
  // ### RENDERs
  return (
    <Box pos="relative">
      <LoadingOverlay
        zIndex={2}
        visible={loading}
        overlayProps={{ radius: 'sm', blur: 1 }}
        loaderProps={{ type: 'dots' }}
      />
      {children}
    </Box>
  );
};
export default LoadingOverlayWrapper;
