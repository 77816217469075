import { apiCallV2 } from 'apis/ApiCall';
import { syncImagePaths } from 'apis/ImagesApis';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

export const useAdminPromotions = (params = null, key = '') => {
  const url = 'admin/promotions';
  const { appImages } = useSelector((state) => state.image);
  const { data, isLoading, refetch, isRefetching, isFetching } = useQuery({
    queryKey: ['useAdminPromotions', params, key],
    queryFn: () => apiCallV2({ url, params }),
    onSuccess: async (res) => {
      let array = [];
      if (res?.data?.results?.length) {
        for (let i = 0; i < res?.data?.results.length; i++) {
          const promotions = res?.data?.results[i];
          if (promotions?.images?.length) {
            for (let y = 0; y < promotions?.images.length; y++) {
              const img = promotions?.images[y];
              if (!appImages?.[img]) {
                array?.push(img);
              }
            }
          }
        }
        syncImagePaths(array);
      }
    },
  });
  return {
    promotions: data?.data?.results || [],
    total: data?.data?.total || 0,
    data,
    loading: isLoading,
    refetch,
    isFetching,
  };
};

export const useCreatePromotion = (data) => {
  return useMutation({
    mutationFn: (data) => {
      const url = `/admin/promotions`;
      return apiCallV2({
        method: 'POST',
        url,
        data,
      });
    },
  });
};

export const useUpdatePromotions = (id) => {
  return useMutation({
    mutationFn: (data) => {
      const url = '/admin/promotions';
      return apiCallV2({
        method: 'put',
        url,
        data,
      });
    },
  });
};

export const useAddPromotionProducts = () => {
  return useMutation({
    mutationFn: (data) => {
      const url = `/admin/promotion-products`;
      return apiCallV2({
        method: 'post',
        url,
        data,
      });
    },
  });
};

export const useUpdatePromotionProducts = () => {
  return useMutation({
    mutationFn: (data) => {
      const url = `/admin/promotion-products`;
      return apiCallV2({
        method: 'put',
        url,
        data,
      });
    },
  });
};

export const useDeletePromotion = () => {
  return useMutation({
    mutationFn: (id) => {
      const url = `/admin/promotions/` + id;
      return apiCallV2({
        method: 'delete',
        url,
      });
    },
  });
};

export const useGetPromotionProducts = (id = '') => {
  const { appImages } = useSelector((state) => state.image);
  return useQuery({
    queryKey: ['useGetPromotionProducts', id],
    enabled: !!id,
    queryFn: () =>
      apiCallV2({
        url: 'admin/promotion-products/' + id,
      }),
    onSuccess: async (res) => {
      let array = [];
      if (res?.data?.length) {
        for (let i = 0; i < res?.data?.length; i++) {
          const product = res?.data[i];
          if (!appImages[product?.image_url]) array.push(product?.image_url);
          if (product?.variants?.length) {
            for (let y = 0; y < product?.variants.length; y++) {
              const variant = product?.variants[y];
              if (variant?.images?.[0] && !appImages?.[variant?.images?.[0]]) {
                array?.push(variant?.images?.[0]);
              }
            }
          }
        }
        syncImagePaths(array);
      }
    },
  });
};

export const useDeletePromotionProducts = () => {
  return useMutation({
    mutationFn: (data) => {
      const url = `/admin/promotion-products`;
      return apiCallV2({
        method: 'delete',
        url,
        data,
      });
    },
  });
};
