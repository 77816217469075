import { useGetReferralHistory } from 'apis/CustomersApi';
import { Link, useParams } from 'react-router-dom';
import { useMemo } from 'react';
import { CommonTableColumn } from 'types';
import { t } from 'i18next';
import { Anchor } from '@mantine/core';
import { dateFormatter } from 'helpers/utils';
import { CommonTable } from 'components/MantineUI/CommonTable';
import { usePaginationParams } from 'hooks/usePaginationParams';

type Props = {
  customer?: any;
};

const ReferralHistoryTab = ({ customer }: Props) => {
  const { id } = useParams();
  const { data }: any = useGetReferralHistory(id);
  const histories = data?.data?.results ?? [];
  const total = data?.data?.total || 0;
  const pagination = usePaginationParams();

  const columns: CommonTableColumn[] = useMemo(
    () => [
      {
        accessor: 'name',
        Header: t('general.name'),
        Cell: (row) => {
          const { first_name, last_name } = row;
          const fullName =
            first_name && last_name ? `${first_name} ${last_name}` : undefined;
          return (
            <Anchor component={Link} to={`/customers/${row?._id}`} size="sm">
              {fullName ? fullName : <>--</>}
            </Anchor>
          );
        },
      },
      {
        accessor: 'phone',
        Header: t('register.form.phone'),
        Cell: (row) => (row.phone_number ? row.phone_number : '--'),
      },
      {
        accessor: 'email',
        Cell: (row) => (row.email ? row.email : '--'),
        Header: t('register.form.email'),
      },
      {
        accessor: 'created_on',
        Cell: (row) => dateFormatter(row?.created_on),
        Header: t('general.created_date'),
      },
    ],
    [],
  );

  return (
    <CommonTable
      data={histories}
      columns={columns}
      pagination={{
        ...pagination,
        total,
      }}
    />
  );
};

export default ReferralHistoryTab;
