import { Button, Grid } from '@mantine/core';
import { StatusEnum } from 'types';
import ProductSelect from 'components/MantineUI/Selects/ProductSelect';
import React, { ReactNode, useEffect, useState } from 'react';
import { SectionFooter } from 'components/MantineUI/CommonSection';
import { t } from 'i18next';
import { useURLParams } from 'hooks/useURLParams';
import { DatePickerInput, DatesRangeValue } from '@mantine/dates';
import dayjs from 'dayjs';
import StatusSelect from 'components/MantineUI/Selects/StatusSelect';
type PropType = {
  onClose?: () => void;
};
type InquiryFilter = {
  status?: StatusEnum;
  product_id?: string;
  product_name?: string;
  'date_range[from]'?: number;
  'date_range[to]'?: number;
  date_range?: DatesRangeValue;
};

const InquiryFilter = (props: PropType) => {
  const { onClose } = props;
  const { params, handleParamsChange }: any = useURLParams();
  // ### CONSTANTs
  const [filter, setFilter] = useState<InquiryFilter>({});
  // ### FUNCTIONs
  useEffect(() => {
    setFilter({
      product_id: params?.product_id,
      product_name: params?.product_name,
      status: params?.status,
      date_range:
        params?.['date_range[from]'] && params?.['date_range[to]']
          ? [
              dayjs.unix(params?.['date_range[from]']).toDate(),
              dayjs.unix(params?.['date_range[to]']).toDate(),
            ]
          : undefined,
    });
  }, []);

  const onApply = () => {
    handleParamsChange({
      page: 1,
      product_id: filter?.product_id || undefined,
      product_name: filter?.product_name || undefined,
      status: filter?.status || undefined,
      'date_range[from]': filter?.date_range?.[0]
        ? dayjs(filter?.date_range?.[0]).unix()
        : undefined,
      'date_range[to]': filter?.date_range?.[1]
        ? dayjs(filter?.date_range?.[1]).unix()
        : undefined,
    });
    onClose?.();
  };
  const onReset = () => {
    setFilter({});
    handleParamsChange({
      page: 1,
      product_id: undefined,
      product_name: undefined,
      'date_range[from]': undefined,
      'date_range[to]': undefined,
      status: undefined,
    });
    onClose?.();
  };
  // ### RENDERs
  return (
    <Grid>
      <Grid.Col span={{ base: 12 }}>
        <ProductSelect
          params={{ type: 'service' }}
          onChange={(value) =>
            setFilter((prev) => ({
              ...prev,
              product_id: value?.value,
              product_name: value?.label,
            }))
          }
          value={{
            value: filter?.product_id || '',
            label: filter?.product_name || '',
          }}
        />
      </Grid.Col>
      <Grid.Col span={{ base: 12 }}>
        <DatePickerInput
          allowSingleDateInRange
          type="range"
          value={filter?.date_range}
          label={t('orders.order_date_range')}
          placeholder={t('general.select')}
          onChange={(v) => setFilter((prev) => ({ ...prev, date_range: v }))}
          maxDate={new Date()}
        />
      </Grid.Col>
      <Grid.Col span={{ base: 12 }}>
        <StatusSelect
          required={false}
          hideDraft
          nativeSelect={false}
          hidePending={false}
          value={filter?.status}
          onChange={(value: any) => {
            setFilter((prev) => ({
              ...prev,
              status: value,
            }));
          }}
        />
      </Grid.Col>
      <Grid.Col span={{ base: 12 }}>
        <SectionFooter>
          <Button onClick={onApply}>{t('general.apply')}</Button>
          <Button onClick={onReset} variant="default">
            {t('general.reset')}
          </Button>
        </SectionFooter>
      </Grid.Col>
    </Grid>
  );
};
export default InquiryFilter;
