import { GenericAbortSignal } from 'axios';

export enum HTTPMethods {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export type ApiCallParams = {
  method?: HTTPMethods | string;
  url: string;
  headerOptions?: any;
  params?: any;
  data?: any;
  signal?: GenericAbortSignal;
  redirect404Error?: boolean;
};

export type TResponse<T> = {
  data: T;
  status: boolean;
  message?: string;
};

export type TResponseList<T> = TResponse<{
  results: T[];
  total: number;
}>;
