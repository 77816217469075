import React, { ReactNode, useMemo } from 'react';
import UploadWrapper from './UploadWrapper';
import { Text } from '@mantine/core';
import IconFA from 'components/common/IconFA';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
type PropType = {
  children?: ReactNode;
  className?: string;
  onFileChange?: (files: Blob[] | MediaSource[]) => void;
  resize?: { width: number; height: number };
  maxFiles?: number;
  acceptType?: 'images' | 'files' | 'videos';
};
const CommonDropZone = (props: PropType) => {
  // ### CONSTANTs
  const { t } = useTranslation();

  const {
    onFileChange,
    className = '',
    resize,
    acceptType,
    maxFiles,
    children,
  } = props;
  // ### FUNCTIONs
  // ### RENDERs
  const render = (type = 'defautl') => {
    const isAccept = type === 'accept';
    const isReject = type === 'reject';
    const bg = isAccept
      ? 'bg-sky-50'
      : isReject
      ? 'bg-red-100'
      : 'hover:bg-neutral-100';
    return (
      <div
        className={classNames(
          bg,
          'w-full flex flex-col cursor-pointer border-dashed border p-4 rounded-md items-center',
        )}
      >
        <div className="flex flex-row items-center">
          <IconFA
            color={
              isAccept
                ? 'var(--mantine-color-blue-text)'
                : isReject
                ? 'var(--mantine-color-red-text)'
                : ''
            }
            className={`text-[30px] me-3`}
            icon={isAccept ? 'upload' : isReject ? 'ban' : 'image'}
            type="light"
          />
          <div className="flex flex-col items-center justify-center self-center">
            <Text
              c={isAccept ? 'blue' : isReject ? 'red' : ''}
              fw={500}
              ta={'center'}
              size="sm"
            >
              {t('general.dropzone_decs_1')}
            </Text>
            <Text
              c={isAccept ? 'blue.3' : isReject ? 'red.3' : 'gray.5'}
              size="xs"
              ta={'center'}
            >
              {t('general.dropzone_decs_2')}
            </Text>
          </div>
        </div>
      </div>
    );
  };
  const acceptZone = useMemo(() => render('accept'), []);
  const rejectZone = useMemo(() => render('reject'), []);
  const mainZone = useMemo(() => render(), []);

  return (
    <UploadWrapper
      className={`cursor-pointer ${className}`}
      acceptZone={acceptZone}
      rejectZone={rejectZone}
      onFileChange={onFileChange}
      resize={resize}
      acceptType={acceptType}
      maxFiles={maxFiles}
    >
      {children ? children : mainZone}
    </UploadWrapper>
  );
};
export default CommonDropZone;
