import { Button, Grid, Modal } from '@mantine/core';
import { InquiryItem, StatusEnum } from 'types';
import { t } from 'i18next';
import StatusSelect from 'components/MantineUI/Selects/StatusSelect';
import { useEffect, useState } from 'react';
import { SectionFooter } from 'components/MantineUI/CommonSection';
import { useUpdateInquiry } from 'apis/Inquiries';
type PropType = {
  opened: boolean;
  onClose: () => void;
  data?: InquiryItem;
};
const InquiryModals = (props: PropType) => {
  // ### CONSTANTs
  const { opened = false, onClose, data } = props;
  const [status, setStatus] = useState<StatusEnum | undefined>(data?.status);
  const { mutate: updateInquiry, isPending: updating } = useUpdateInquiry();
  useEffect(() => {
    setStatus(data?.status);
  }, [data]);
  // ### FUNCTIONs
  const onSave = () => {
    if (data?._id) {
      updateInquiry(
        {
          id: data?._id,
          status: status as StatusEnum,
        },
        {
          onSuccess: () => {
            onClose();
          },
        },
      );
    }
  };
  // ### RENDERs

  return (
    <Modal
      centered
      size={'lg'}
      onClose={onClose}
      opened={opened}
      title={t('inquiries.edit_inquiry')}
    >
      <Grid>
        <Grid.Col span={{ base: 12 }}>
          <StatusSelect
            hidePending={false}
            hideDraft
            value={status}
            onChange={(value) => setStatus(value?.target?.value as StatusEnum)}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12 }}>
          <SectionFooter updatedTime={data?.updated_on}>
            <Button loading={updating} onClick={onSave}>
              {t('general.save')}
            </Button>
            <Button onClick={onClose} variant="default">
              {t('general.cancel')}
            </Button>
          </SectionFooter>
        </Grid.Col>
      </Grid>
    </Modal>
  );
};
export default InquiryModals;
