import { ProductStatus } from 'pages/products/add-product/components';
import { ProductStatusEnum } from './product.type';

export type KeyLabelPair = {
  key: string;
  label: string;
};
export type ValueLabelPair = {
  key: string;
  label: string;
};
export enum StatusEnum {
  ACTIVE = 'active',
  DRAFT = 'draft',
  CLOSED = 'closed',
  PENDING = 'pending',
}

export enum AddressTypeEnum {
  BUSSINESS = 'business',
  RESIDENTIAL = 'residential',
}
export type AddressInfo = {
  _id?: string;
  zip_code?: string | number;
  country?: string;
  state?: string;
  city?: string;
  lat?: string;
  lon?: string;
  location?: string;
  address_line1?: string;
  address_line2?: string;
  phone?: string;
  business_name?: string;
  f_name?: string;
  l_name?: string;
  type?: AddressTypeEnum;
  is_default?: boolean;
};

export const STATUS_INFO = {
  [StatusEnum.ACTIVE]: {
    icon: 'check-circle',
    color: 'green',
    label: 'general.active',
  },
  [StatusEnum.DRAFT]: {
    icon: 'save',
    color: 'blue',
    label: 'general.draft',
  },
  [StatusEnum.CLOSED]: {
    icon: 'archive',
    color: 'secondary',
    label: 'general.closed',
  },
  [StatusEnum.PENDING]: {
    icon: 'clock',
    color: 'yellow',
    label: 'general.pending',
  },
  [ProductStatusEnum.REJECTED]: {
    icon: 'ban',
    color: 'red',
    label: 'general.rejected',
  },
};

export enum BannerTargetType {
  NO_TARGET = 'no_target',
  CATEGORY = 'category',
  SUBCATEGORY = 'sub_category',
  BRAND = 'brand',
  SELLER = 'seller',
  PRODUCT = 'product',
  PROMOTION = 'promotion',
}

export type BannerTarget = {
  type: BannerTargetType;
  category_id?: string;
  seller_id?: string;
  brand_id?: string;
  product_id?: string;
};

export const BANNER_TARGET_INFO = {
  [BannerTargetType.NO_TARGET]: {
    label: 'form.coupon.no_target',
    type_label: 'form.coupon.no_target',
    id_field: 'no_target',
  },
  [BannerTargetType.CATEGORY]: {
    label: 'form.coupon.specific_cat',
    type_label: 'products.category',
    id_field: 'category_id',
  },
  [BannerTargetType.SUBCATEGORY]: {
    label: 'form.coupon.specific_subcat',
    type_label: 'products.subcategory',
    id_field: 'sub_category_id',
  },
  [BannerTargetType.BRAND]: {
    label: 'form.coupon.specific_brand',
    type_label: 'products.brand',
    id_field: 'brand_id',
  },
  [BannerTargetType.SELLER]: {
    label: 'form.coupon.specific_seller',
    type_label: 'products.store',
    id_field: 'seller_id',
  },
  [BannerTargetType.PRODUCT]: {
    label: 'form.coupon.specific_product',
    type_label: 'products.product',
    id_field: 'product_id',
  },
  [BannerTargetType.PROMOTION]: {
    label: 'form.coupon.specific_promotion',
    type_label: 'navigation.promotion',
    id_field: 'promotion_id',
  },
};

export type BannerFilterFormType = {
  status?: StatusEnum;
  type?: BannerTargetType;
  is_new_user?: boolean;
  start_date?: any;
  end_date?: any;
};

export enum ArrangeModels {
  BRAND = 'brand',
  CATEGORY = 'category',
  PRODUCT_VARIANT = 'product_variant',
  INFORMATION = 'information',
  PROMOTION = 'promotion',
  BANNER = 'banner',
}
