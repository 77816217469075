import { ReactNode } from 'react';
import { BreadCrumsProps } from 'types';
import useDocumentTitle from 'hooks/useDocumentTitle';
import BreadCrumbs from './BreadCrumbs';

type Props = {
  children?: ReactNode;
  breadCrumds: BreadCrumsProps[];
  title?: string;
  rightSection?: ReactNode;
};

const PageLayout = ({
  breadCrumds,
  children,
  title = '',
  rightSection,
}: Props) => {
  useDocumentTitle(title);
  return (
    <>
      <BreadCrumbs data={breadCrumds} rightSection={rightSection} />
      {children}
    </>
  );
};
export default PageLayout;
