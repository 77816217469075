import { Table, Text } from '@mantine/core';
import LoadingOverlayWrapper from 'components/MantineUI/LoadingOverlayWrapper';
import { t } from 'i18next';
import { ReactNode, useMemo } from 'react';
import { CommonPaginationProps, CommonTableColumn } from 'types';
import CommonPagination from './Pagination';

type Props = {
  data: any[];
  columns: CommonTableColumn[];
  EmptyComponent?: ReactNode;
  loading?: boolean;
  pagination?: CommonPaginationProps;
  onPaginationChange?: (page: number, limit: number) => void;
};

const CommonTable = ({
  data = [],
  columns = [],
  EmptyComponent,
  loading = false,
  pagination,
}: Props) => {
  const rows = useMemo(
    () =>
      data?.map((element, rowIndex) => (
        <Table.Tr key={rowIndex}>
          {columns?.map((col, colIndex) => {
            const rowSpan = col?.RowSpan?.(element, rowIndex);
            return typeof rowSpan === 'number' && rowSpan === 0 ? null : (
              <Table.Td rowSpan={rowSpan || 1} key={colIndex}>
                {col?.Cell?.(element, rowIndex)}
              </Table.Td>
            );
          })}
        </Table.Tr>
      )),
    [data, columns],
  );
  const emptyComp = useMemo(() => {
    if (!data?.length) {
      if (EmptyComponent) return EmptyComponent;
      return (
        <div className="w-full py-5 items-center flex justify-center">
          <Text size="md">{t('general.no_data')}</Text>
        </div>
      );
    }
    return null;
  }, [EmptyComponent, data]);
  return (
    <>
      <LoadingOverlayWrapper loading={loading}>
        <Table.ScrollContainer minWidth={500}>
          <Table striped highlightOnHover horizontalSpacing="sm">
            <Table.Thead>
              <Table.Tr>
                {columns?.map((col, index) => {
                  return <Table.Th key={index}>{col?.Header}</Table.Th>;
                })}
              </Table.Tr>
            </Table.Thead>
            {/* <LoadingOverlayWrapper loading={true}> */}
            <Table.Tbody className="w-full">{rows}</Table.Tbody>
            {/* </LoadingOverlayWrapper> */}
          </Table>
        </Table.ScrollContainer>
        {emptyComp}
        {pagination && data?.length ? (
          <CommonPagination className="mt-3" pagination={pagination} />
        ) : null}
      </LoadingOverlayWrapper>
    </>
  );
};

export default CommonTable;
