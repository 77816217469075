import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { apiCallV2 } from './ApiCallV2';
import { dataFetchingQueryConfig } from 'config';
import { StatusEnum } from 'types';
import { toast } from 'react-toastify';
import { t } from 'i18next';
const config = dataFetchingQueryConfig;

export const useInquiriesList = (params: any) =>
  useQuery({
    queryKey: ['useInquiriesList', params],
    queryFn: () =>
      apiCallV2({
        method: 'get',
        url: 'admin/inquiries',
        params,
      }),
    ...config,
  });

export const useUpdateInquiry = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, status }: { id: string; status: StatusEnum }) => {
      const url = `admin/inquiries/${id}`;
      return apiCallV2({
        url,
        method: 'PUT',
        data: { status },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['useInquiriesList'] as any);
      toast.success(t('general.update_success'));
    },
    onError: () => {
      toast.error(t('general.update_fail'));
    },
  });
};
export const useDeleteInquiry = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: string) => {
      const url = `admin/inquiries/${id}`;
      return apiCallV2({
        url,
        method: 'DELETE',
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['useInquiriesList'] as any);
      toast.success(t('general.delete_success'));
    },
    onError: () => {
      toast.error(t('general.delete_fail'));
    },
  });
};
