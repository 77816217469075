import { Alert, Anchor, Badge, Flex, Text } from '@mantine/core';
import { ShippingStatus } from 'types';
import IconFA from 'components/common/IconFA';
import CopyButton from 'components/MantineUI/Buttons/CopyButton';
import { ShippingInformationTypeEnum } from 'constants/orders';
import { getCarrierName, getTrackingUrl } from 'helpers/utils';
import { t } from 'i18next';
import { Link } from 'react-router-dom';

type PropType = {
  shipment?: any;
};

const TrackinginfoItems = (props: PropType) => {
  // ### CONSTANTs
  const { shipment } = props;
  // ### FUNCTIONs
  const hasShipmentWithManual =
    shipment?.type === ShippingInformationTypeEnum.Manual;

  const hasTrackingStatus =
    shipment?.type === ShippingInformationTypeEnum.Manual &&
    shipment?.shippo_track;

  const carrierId = shipment?.carrier_id;
  const trackingNumber = shipment?.tracking_number;
  const serviceLevel = shipment?.shippo_track?.servicelevel;
  const trackingStatus = shipment?.shippo_track?.tracking_status?.status;
  const trackingStatusDetails =
    shipment?.shippo_track?.tracking_status?.status_details;
  const trackingStatusSubstatus =
    shipment?.shippo_track?.tracking_status?.substatus;

  const isFailedDelivery =
    trackingStatus === ShippingStatus.RETURNED ||
    trackingStatusSubstatus?.action_required;

  // ### RENDERs

  return hasShipmentWithManual ? (
    <>
      <Text fw={'600'}>{t('orders.tracking_number')}</Text>

      <Flex align={'center'}>
        <Anchor
          size="sm"
          fw={'600'}
          target="_blank"
          component={Link}
          to={getTrackingUrl(carrierId, trackingNumber)}
        >
          {trackingNumber}
        </Anchor>
        <CopyButton value={trackingNumber} />
        {hasTrackingStatus ? (
          <Badge color="blue" variant="light">
            {trackingStatus}
          </Badge>
        ) : null}
      </Flex>
      <Text size="sm">
        {getCarrierName(carrierId)} {serviceLevel?.name}{' '}
      </Text>
      {trackingStatusDetails ? (
        <>
          <Text size="sm">
            {t('general.status')}: {trackingStatusDetails}
          </Text>
          {isFailedDelivery ? (
            <Alert
              title={t('general.action_required')}
              icon={<IconFA icon={'triangle-exclamation'} />}
              color="red"
              className="my-2"
            >
              {trackingStatusSubstatus?.text}
            </Alert>
          ) : null}
        </>
      ) : null}
    </>
  ) : null;
};
export default TrackinginfoItems;
