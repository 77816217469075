import {
  Combobox,
  InputError,
  Loader,
  Text,
  TextInput,
  useCombobox,
} from '@mantine/core';
import { useInfiniteQuery } from '@tanstack/react-query';
import { apiCallV2 } from 'apis/ApiCall';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { t } from 'i18next';
import IconFA from 'components/common/IconFA';
import { faAnglesUpDown } from '@fortawesome/pro-light-svg-icons';
import * as lodash from 'lodash';
export type SelectItem = {
  label: string;
  value: string;
};
export type SingleSelectProps = {
  route?: string;
  optionTransformer?: (data: any) => any[];
  className?: string;
  label?: string;
  placeholder?: string;
  searchField?: string;
  required?: boolean;
  disabled?: boolean;
  errorMessage?: string;
  limit?: number;
  renderOption?: any;
  params?: any;
};
const SingleSelect = (
  props: SingleSelectProps & {
    selected: SelectItem | null;
    onChange: (item?: SelectItem) => void;
  },
) => {
  // ### CONSTANTs
  const controller = new AbortController();
  const {
    route = '',
    className = '',
    selected,
    label,
    placeholder,
    onChange,
    required,
    errorMessage,
    renderOption,
    limit = 25,
    params = {},
    searchField,
    disabled,
    optionTransformer = (data) =>
      data?.results?.map((x: any) => ({
        label: x?.name,
        value: x?._id,
      })),
  } = props;
  // const [page, setPage] = useState(1);
  const [data, setData] = useState<any[]>([]);
  const [search, setSearch] = useState<string>('');
  const [searchParams, setSearchParams] = useState<string>('');
  const LIMIT = limit;

  const debounceSearch = useCallback(
    lodash.debounce((search) => setSearchParams(search), 500),
    [],
  );

  const queryParams = useMemo(() => {
    let obj = { limit: LIMIT, ...params };

    if (searchField && searchParams && searchParams !== selected?.label) {
      obj = {
        ...obj,
        [searchField]: searchParams,
      };
    }
    return obj;
  }, [searchParams]);

  const {
    data: res,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteQuery({
    queryKey: [route, 'infinity', queryParams],
    initialPageParam: 1,
    queryFn: ({ pageParam = 1 }) =>
      apiCallV2({
        url: route,
        params: { ...queryParams, page: pageParam },
        signal: controller.signal,
      } as any),
    getNextPageParam: (lastPage: any, pages) => {
      const hasMore = lastPage?.data?.total > pages.length * LIMIT;
      const nextPage = pages.length + 1;
      return hasMore ? nextPage : undefined;
    },
    enabled: !!route && !disabled,
  });

  useEffect(() => {
    debounceSearch(search);
  }, [search]);

  useEffect(() => {
    setSearch(selected?.label || '');
  }, [selected]);

  useEffect(() => {
    let newData: any[] = [];
    res?.pages?.map((page: any) =>
      optionTransformer(page?.data)?.map((item: any) => newData.push(item)),
    );
    setData(newData);
  }, [res]);
  // ### FUNCTIONs
  // ### RENDERs
  const generalProps = {
    key: route,
    error: !!errorMessage,
    required: required,
    label: label,
    searchable: true,
    // disabled: isLoading,
    disabled: disabled,
    placeholder: placeholder,
    data: data,
    rightSection: isLoading ? <Loader size={15} /> : undefined,
  };
  const handleDropdownScroll = (event: any) => {
    const target = event.currentTarget;

    if (target.scrollHeight - target.scrollTop <= target.clientHeight + 1) {
      if (hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    }
  };

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });
  const options = data.map((item, index) => {
    const isSelected = item?.value === selected?.value;
    return (
      <>
        {item?.group ? (
          <Combobox.Group label={item?.group}>
            {item?.items?.map((child: SelectItem) => {
              const isChildSelected = child?.value === selected?.value;
              return (
                <Combobox.Option
                  onClick={() => onClickOption(child)}
                  value={child?.value}
                  key={child?.value}
                >
                  {renderOption ? (
                    renderOption(child, isChildSelected)
                  ) : (
                    <Text size="sm" c={isChildSelected ? 'primary' : ''}>
                      {child?.label}
                    </Text>
                  )}
                </Combobox.Option>
              );
            })}
          </Combobox.Group>
        ) : (
          <Combobox.Option
            onClick={() => onClickOption(item)}
            value={item?.value}
            key={item?.value}
          >
            {renderOption ? (
              renderOption(item, isSelected)
            ) : (
              <Text size="sm" c={isSelected ? 'primary' : ''}>
                {item?.label}
              </Text>
            )}
          </Combobox.Option>
        )}
        {hasNextPage && index === data?.length - 1 ? (
          <Combobox.Option disabled value="loading" key={'loading'}>
            <div className="w-full flex items-center gap-2 justify-center">
              <Loader size={12} />
              {t('general.loading')}
            </div>
          </Combobox.Option>
        ) : null}
      </>
    );
  });
  const onClickOption = (option: SelectItem) => {
    setSearch(option?.label);
    onChange?.(selected?.value === option?.value ? undefined : option);
    combobox.targetRef.current?.blur();
    combobox.closeDropdown();
  };
  return (
    <div className={className}>
      <Combobox position="bottom" store={combobox}>
        <Combobox.Target>
          <TextInput
            {...generalProps}
            defaultValue={selected?.label}
            value={search}
            disabled={isLoading}
            onBlur={() => setSearch(selected?.label || '')}
            onChange={(e) => setSearch(e.target.value)}
            onFocus={() => combobox.openDropdown()}
            rightSection={
              isLoading ? (
                <Loader size={12} />
              ) : (
                <Text size="10">
                  <IconFA color={'black'} icon={faAnglesUpDown} />
                </Text>
              )
            }
          />
        </Combobox.Target>

        <Combobox.Dropdown>
          <Combobox.Options
            onScroll={handleDropdownScroll}
            mah={200}
            style={{ overflowY: 'auto' }}
          >
            {isLoading ? (
              <Combobox.Empty>
                <Loader size={12} /> {t('general.loading')}
              </Combobox.Empty>
            ) : options?.length ? (
              options
            ) : (
              <Combobox.Empty>{t('general.no_data')}</Combobox.Empty>
            )}
          </Combobox.Options>
        </Combobox.Dropdown>
      </Combobox>
      {errorMessage ? (
        <InputError className="mt-1">{errorMessage}</InputError>
      ) : null}
    </div>
  );
};
export default SingleSelect;
