import React from 'react';
import IconFA from '../IconFA';
import { getColor } from 'helpers/utils';
import { faSquare } from '@fortawesome/pro-light-svg-icons';
import { faSquareCheck } from '@fortawesome/pro-solid-svg-icons';

type Props = {
  checked: boolean;
  onChange?: (value: boolean) => void;
  disabled?: boolean;
};

const CheckBox = ({ checked, onChange, disabled }: Props) => {
  return (
    <div
      onClick={(e) => {
        if (!disabled) {
          e?.preventDefault();
          onChange?.(!checked);
        }
      }}
    >
      {checked ? (
        <IconFA
          icon={faSquareCheck}
          color={disabled ? getColor('gray-400') : getColor('primary')}
        />
      ) : (
        <IconFA icon={faSquare} />
      )}
    </div>
  );
};
export default CheckBox;
