import {
  DATE_FORMAT_VI,
  DATE_TIME_FORMAT_VI,
  DEFAULT_DATE_FORMAT,
  DEFAULT_DATE_TIME_FORMAT,
} from 'constants/constants';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { t } from 'i18next';
import { getI18n } from 'react-i18next';
// eslint-disable-next-line no-undef
const relativeTime = require('dayjs/plugin/relativeTime');
// eslint-disable-next-line no-undef
const updateLocale = require('dayjs/plugin/updateLocale');
import Resizer from 'react-image-file-resizer';
import { toast } from 'react-toastify';

dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);

// File url
const FILE_URL = process.env.REACT_APP_FILE_URL;

export const isString = (value) => typeof value === 'string';

export const isIterableArray = (array) =>
  Array.isArray(array) && !!array.length;

//===============================
// Breakpoints
//===============================
export const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1540,
};

export const getItemFromStore = (key, defaultValue, store = localStorage) => {
  try {
    return store.getItem(key) === null
      ? defaultValue
      : JSON.parse(store.getItem(key));
  } catch {
    return store.getItem(key) || defaultValue;
  }
};

export const setItemToStore = (key, payload, store = localStorage) =>
  store.setItem(key, payload);

export const getStoreSpace = (store = localStorage) =>
  parseFloat(
    (
      escape(encodeURIComponent(JSON.stringify(store))).length /
      (1024 * 1024)
    ).toFixed(2),
  );

//===============================
// Cookie
//===============================
export const getCookieValue = (name) => {
  const value = document.cookie.match(
    '(^|[^;]+)\\s*' + name + '\\s*=\\s*([^;]+)',
  );
  return value ? value.pop() : null;
};

export const createCookie = (name, value, cookieExpireTime) => {
  const date = new Date();
  date.setTime(date.getTime() + cookieExpireTime);
  const expires = '; expires=' + date.toUTCString();
  document.cookie = name + '=' + value + expires + '; path=/';
};

export const numberFormatter = (number, fixed = 2) => {
  // Nine Zeroes for Billions
  return Math.abs(Number(number)) >= 1.0e9
    ? (Math.abs(Number(number)) / 1.0e9).toFixed(fixed) + 'B'
    : // Six Zeroes for Millions
    Math.abs(Number(number)) >= 1.0e6
    ? (Math.abs(Number(number)) / 1.0e6).toFixed(fixed) + 'M'
    : // Three Zeroes for Thousands
    Math.abs(Number(number)) >= 1.0e3
    ? (Math.abs(Number(number)) / 1.0e3).toFixed(fixed) + 'K'
    : Math.abs(Number(number)).toFixed(fixed);
};

//===============================
// Colors
//===============================
export const hexToRgb = (hexValue) => {
  let hex;
  hexValue.indexOf('#') === 0
    ? (hex = hexValue.substring(1))
    : (hex = hexValue);
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
    hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b),
  );
  return result
    ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16),
      ]
    : null;
};

export const rgbColor = (color = colors[0]) => `rgb(${hexToRgb(color)})`;
export const rgbaColor = (color = colors[0], alpha = 0.5) =>
  `rgba(${hexToRgb(color)},${alpha})`;

export const colors = [
  '#2c7be5',
  '#00d97e',
  '#e63757',
  '#39afd1',
  '#fd7e14',
  '#02a8b5',
  '#727cf5',
  '#6b5eae',
  '#ff679b',
  '#f6c343',
];

export const themeColors = {
  primary: '#2c7be5',
  secondary: '#748194',
  success: '#00d27a',
  info: '#27bcfd',
  warning: '#f5803e',
  danger: '#e63757',
  light: '#f9fafd',
  dark: '#0b1727',
};

export const grays = {
  white: '#fff',
  100: '#f9fafd',
  200: '#edf2f9',
  300: '#d8e2ef',
  400: '#b6c1d2',
  500: '#9da9bb',
  600: '#748194',
  700: '#5e6e82',
  800: '#4d5969',
  900: '#344050',
  1000: '#232e3c',
  1100: '#0b1727',
  black: '#000',
};

export const darkGrays = {
  white: '#fff',
  1100: '#f9fafd',
  1000: '#edf2f9',
  900: '#d8e2ef',
  800: '#b6c1d2',
  700: '#9da9bb',
  600: '#748194',
  500: '#5e6e82',
  400: '#4d5969',
  300: '#344050',
  200: '#232e3c',
  100: '#0b1727',
  black: '#000',
};

export const getGrays = (isDark) => (isDark ? darkGrays : grays);

export const rgbColors = colors.map((color) => rgbColor(color));
export const rgbaColors = colors.map((color) => rgbaColor(color));

export const getColor = (name, dom = document.documentElement) => {
  return getComputedStyle(dom).getPropertyValue(`--falcon-${name}`).trim();
};

//===============================

// Echarts
//===============================
export const getPosition = (pos, params, dom, rect, size) => ({
  top: pos[1] - size.contentSize[1] - 10,
  left: pos[0] - size.contentSize[0] / 2,
});
//===============================
// E-Commerce
//===============================
export const calculateSale = (base, less = 0, fix = 2) =>
  (base - base * (less / 100)).toFixed(fix);
export const getTotalPrice = (cart, baseItems) =>
  cart.reduce((accumulator, currentValue) => {
    const { id, quantity } = currentValue;
    const { price, sale } = baseItems.find((item) => item.id === id);
    return accumulator + calculateSale(price, sale) * quantity;
  }, 0);
export const getSubtotal = (items) =>
  items.reduce((acc, curr) => curr.price * curr.quantity + acc, 0);
export const getDiscountPrice = (total, discount) =>
  total - total * (discount / 100);

export const getProductsQuantity = (products) =>
  products.reduce((acc, product) => product.quantity + acc, 0);

//===============================
// Helpers
//===============================
export const getPaginationArray = (totalSize, sizePerPage) => {
  const noOfPages = Math.ceil(totalSize / sizePerPage);
  const array = [];
  let pageNo = 1;
  while (pageNo <= noOfPages) {
    array.push(pageNo);
    pageNo = pageNo + 1;
  }
  return array;
};

export const capitalize = (str) =>
  (str.charAt(0).toUpperCase() + str.slice(1)).replace(/-/g, ' ');

/**
 * Example
 * @param {string} str = brand_name
 * @param {string} splitter = "_"
 * @returns Brand Name
 */
export const titleCase = (str, splitter) => {
  if (typeof str != 'string') return str;
  return str
    .split(splitter)
    .map((e) => capitalize(e))
    .join(' ');
};

export const camelize = (str) => {
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
    if (+match === 0) return ''; // or if (/\s+/.test(match)) for white spaces
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });
};

export const dashed = (str) => {
  return str.toLowerCase().replaceAll(' ', '-');
};

//routes helper

export const flatRoutes = (childrens) => {
  const allChilds = [];

  const flatChild = (childrens) => {
    childrens.forEach((child) => {
      if (child.children) {
        flatChild(child.children);
      } else {
        allChilds.push(child);
      }
    });
  };
  flatChild(childrens);

  return allChilds;
};

export const getFlatRoutes = (children) =>
  children.reduce(
    (acc, val) => {
      if (val.children) {
        return {
          ...acc,
          [camelize(val.name)]: flatRoutes(val.children),
        };
      } else {
        return {
          ...acc,
          unTitled: [...acc.unTitled, val],
        };
      }
    },
    { unTitled: [] },
  );

export const routesSlicer = ({ routes, columns = 3, rows }) => {
  const routesCollection = [];
  routes.map((route) => {
    if (route.children) {
      return route.children.map((item) => {
        if (item.children) {
          return routesCollection.push(...item.children);
        }
        return routesCollection.push(item);
      });
    }
    return routesCollection.push(route);
  });

  const totalRoutes = routesCollection.length;
  const calculatedRows = rows || Math.ceil(totalRoutes / columns);
  const routesChunks = [];
  for (let i = 0; i < totalRoutes; i += calculatedRows) {
    routesChunks.push(routesCollection.slice(i, i + calculatedRows));
  }
  return routesChunks;
};

export const getPageName = (pageName) => {
  return window.location.pathname.split('/').slice(-1)[0] === pageName;
};

export const copyToClipBoard = async (textFieldRef) => {
  const textField = textFieldRef.current;
  // textField.focus();
  // textField.select();
  // document.execCommand('copy');
  try {
    await navigator.clipboard.writeText(textField.value);
  } catch (error) {
    toast.error('Failed to copy!', { theme: 'colored' });
    throw new Error('Failed to copy: ', error);
  }
};

export const onCopyToClipBoard = async (text) => {
  try {
    await navigator.clipboard.writeText(text);
    toast.success(t('general.copied'));
  } catch (error) {
    toast.error(t('general.copy_failed'));
  }
};

export const reactBootstrapDocsUrl = 'https://react-bootstrap.github.io';

export const pagination = (currentPage, size) => {
  const pages = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  let prev = currentPage - 1 - Math.floor(size / 2);

  if (currentPage - 1 - Math.floor(size / 2) < 0) {
    prev = 0;
  }
  if (currentPage - 1 - Math.floor(size / 2) > pages.length - size) {
    prev = pages.length - size;
  }
  const next = prev + size;

  return pages.slice(prev, next);
};

export const tooltipFormatter = (params) => {
  let tooltipItem = ``;
  params.forEach((el) => {
    tooltipItem =
      tooltipItem +
      `<div class='ms-1'> 
        <h6 class="text-700"><span class="fas fa-circle me-1 fs--2" style="color:${
          el.borderColor ? el.borderColor : el.color
        }"></span>
          ${el.seriesName} : ${
        typeof el.value === 'object' ? el.value[1] : el.value
      }
        </h6>
      </div>`;
  });
  return `<div>
            <p class='mb-2 text-600'>
              ${
                dayjs(params[0].axisValue).isValid()
                  ? dayjs(params[0].axisValue).format('MMMM DD')
                  : params[0].axisValue
              }
            </p>
            ${tooltipItem}
          </div>`;
};

export const addIdField = (items) => {
  return items.map((item, index) => ({
    id: index + 1,
    ...item,
  }));
};

// get file size

export const getSize = (size) => {
  if (size < 1024) {
    return `${size} Byte`;
  } else if (size < 1024 * 1024) {
    return `${(size / 1024).toFixed(2)} KB`;
  } else {
    return `${(size / (1024 * 1024)).toFixed(2)} MB`;
  }
};

/* Get A Random Number */
export const getRandomNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min) + min);
};

/* get Dates between */

export const getDates = (
  startDate,
  endDate,
  interval = 1000 * 60 * 60 * 24,
) => {
  const duration = endDate - startDate;
  const steps = duration / interval;
  return Array.from(
    { length: steps + 1 },
    (v, i) => new Date(startDate.valueOf() + interval * i),
  );
};

/* Get Past Dates */
export const getPastDates = (duration) => {
  let days;

  switch (duration) {
    case 'week':
      days = 7;
      break;
    case 'month':
      days = 30;
      break;
    case 'year':
      days = 365;
      break;

    default:
      days = duration;
  }

  const date = new Date();
  const endDate = date;
  const startDate = new Date(new Date().setDate(date.getDate() - (days - 1)));
  return getDates(startDate, endDate);
};

// Add id to items in array
export const addId = (items) =>
  items.map((item, index) => ({
    id: index + 1,
    ...item,
  }));

//
export const getTimeDuration = (startDate, endDate, format = '') => {
  return dayjs.duration(endDate.diff(startDate)).format(format);
};

// Get Percentage
export const getPercentage = (number, percent) => {
  return (Number(number) / 100) * Number(percent);
};

//get chunk from array
export const chunk = (arr, chunkSize = 1, cache = []) => {
  const tmp = [...arr];
  if (chunkSize <= 0) return cache;
  while (tmp.length) cache.push(tmp.splice(0, chunkSize));
  return cache;
};

// Slugify text
export const slugifyText = (str) =>
  str
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/--+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '');

// Text truncation
export const textTruncate = (str, num = 50) => {
  if (typeof str !== 'string') return str;

  // If the length of str is less than or equal to num
  // just return str--don't truncate it.
  if (str.length <= num) return str;

  // Return str truncated with '...' concatenated to the end of str.
  return str.slice(0, num) + '...';
};

// Currency number formatter
export const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

// Amount number formatter without zeros
export const amountFormatter = (num) => {
  if (!num) return num;

  return '$' + num.toLocaleString();
};
export function formatNumber(input) {
  // Check if the input is a string and convert it to a number if needed
  if (typeof input === 'string') {
    // Try to parse the string to a number
    input = parseFloat(input.replace(/,/g, ''));

    // Check if the parsing was successful
    if (isNaN(input)) {
      console.error('Invalid number string');
      return null; // Return null or handle the error as needed
    }
  }

  // Format the number with commas
  var formattedNumber = input.toLocaleString();

  return formattedNumber;
}

// Phone number formatter
export const phoneFormatter = (str) => {
  if (!str) return str;

  // Remove non-numeric characters
  const cleanedNumber = str.replace(/\D/g, '');

  // Check if US phone number (starts with +1 and has 10 digits)
  if (cleanedNumber.startsWith('1') && cleanedNumber.length === 11) {
    // Format the US phone number
    const areaCode = cleanedNumber.slice(1, 4);
    const firstThree = cleanedNumber.slice(4, 7);
    const lastFour = cleanedNumber.slice(7);
    return `+1 (${areaCode}) ${firstThree}-${lastFour}`;
  } else {
    // Not a US phone number, return original number
    return str;
  }
};

// Phone number formatter as ***-***-****
export const phoneUnFormatter = (str) => {
  if (!str) return str;

  let result = str.replace(/\D/g, '');

  if (result.length === 11) result = result.slice(1);

  return result;
};

// Convert unix timestamps to local date
export const dateFormatter = (num) => {
  if (!num) return num;

  const locale = getI18n().language;

  let format;
  switch (locale) {
    case 'vi':
      format = DATE_FORMAT_VI;
      break;
    case 'en':
    default:
      format = DEFAULT_DATE_FORMAT;
      break;
  }

  // return local date;
  return dayjs.unix(num).locale(locale).format(format);
};

// Convert unix timestamps to local date time
export const dateTimeFormatter = (num) => {
  if (!num) return num;

  const locale = getI18n().language;

  let format;
  switch (locale) {
    case 'vi':
      format = DATE_TIME_FORMAT_VI;
      break;
    case 'en':
    default:
      format = DEFAULT_DATE_TIME_FORMAT;
      break;
  }

  // return local date time;
  return dayjs.unix(num).locale(locale).format(format);
};

// Calculate percentage difference between two numbers
export const percentageDifference = (newValue, oldValue) => {
  if (newValue > 0 && oldValue > 0) {
    return Math.ceil(((newValue - oldValue) / oldValue) * 100);
  }
  if (newValue === 0 || oldValue === 0) {
    return Math.ceil(((newValue + 1 - (oldValue + 1)) / (oldValue + 1)) * 100);
  } else {
    return null;
  }
};

// Download the file from exporting URL
export const downloadFile = (url) => {
  const filename = url.substring(url.lastIndexOf('/') + 1);
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    })
    .catch((error) => {
      console.error(`Error downloading ${url}`, error);
    });
};

// Open the file from exporting URL
export const openFile = (url) => {
  const a = document.createElement('a');
  a.target = '_blank';
  a.href = url;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
};

// Area formatter
export const areaFormatter = (num) => {
  if (!num) return num;

  return parseInt(num).toLocaleString() + ' sqft';
};

/**
 * @deprecated
 * Should be replaced with "resizeImageWithSize" soon
 */
// Resize Image
export const resizeImage = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      1200,
      1200,
      'WEBP',
      60,
      0,
      (uri) => {
        resolve(uri);
      },
      'file',
    );
  });

export const resizeImageWithSize = (
  file,
  size = { maxWidth: 1200, maxHeight: 1200 },
) => {
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      size.maxWidth,
      size.maxHeight,
      file.type || 'WEBP',
      75,
      0,
      (uri) => {
        resolve(uri);
      },
      'file',
      // size.maxWidth,
      // size.maxHeight
    );
  });
};

// Convert unix timestamps in seconds to human date
export const relativeTimestamp = (unixTimestamp) => {
  if (!unixTimestamp) return unixTimestamp;

  return dayjs.unix(unixTimestamp).fromNow();
};
// export const updatedTimestamp = unixTimestamp => {
//   if (unixTimestamp > 0) {
//     dayjs.unix(unixTimestamp).format('M/D/YYYY');
//   }
//   return unixTimestamp;
// };

// Convert string DD/MM/YYYY to MM/DD/YYYY
export const localeDate = (dateStr) => {
  if (!dateStr) return dateStr;

  const [day, month, year] = dateStr.split('/');
  return `${month}/${day}/${year}`;
};

// Hide the last 4 digits of phone number
export const hidePhone = (str) => {
  if (!str) return str;

  return str.replace(/\d{3}(\d{4})$/, 'XXX$1');
};

// Hide part of email address
export const hideEmail = (str) => {
  if (!str) return str;

  return `${str.substring(0, 3)}...${str.substring(str.indexOf('@'))}`;
};

/**
 * The serialize function responsible for creating
 * an object of { key: value } pairs from the
 * url search params in the form that make up the query.
 * @param {URL search params} searchParams
 * @returns {Object}
 */
export const serializeSearchParams = (searchParams) =>
  Object.fromEntries(searchParams.entries());

export const getItemLink = (item, itemId) => {
  const WEB_URL = process.env.REACT_APP_WEBSITE_URL;
  return `${WEB_URL}/${item}/${itemId}`;
};

export const getCategoryLink = (cateId, subCateId) => {
  if (!cateId) return '';
  const WEB_URL = process.env.REACT_APP_WEBSITE_URL;
  let url = `${WEB_URL}/products?category_ids[]=${cateId}`;
  if (subCateId) url += `&sub_category_ids[]=${subCateId}`;
  return url;
};

export const getBrandLink = (brandId) => {
  if (!brandId) return '';
  const WEB_URL = process.env.REACT_APP_WEBSITE_URL;
  return `${WEB_URL}/products?brand_ids[]=${brandId}`;
};

export const getInformationLink = (id) => {
  if (!id) return '';
  const WEB_URL = process.env.REACT_APP_WEBSITE_URL;
  return `${WEB_URL}/pages/${id}`;
};

// Check if file is image
export const isImage = (file) => {
  const isFile = file instanceof File;
  if (!isFile) return false;
  const isImageType =
    file['type']?.includes?.('image') || file['mineType']?.includes?.('image');
  return isImageType;
};

// Helper return IMG url which has pathname, url save in db, as a parameter
export const getImageURL = (pathname) => {
  if (typeof pathname !== 'string') throw new Error('Invalid pathname');
  return `${FILE_URL}/${pathname}`;
};

/**
 * Function helper helps rename the title of the property
 * to camel case.
 * @param {string} title as a string name
 * @returns string title with first letter capitalized
 */
export const renameTitle = (title) => {
  if (typeof title !== 'string') return title;
  const trimTitle = title.trim();
  const camelizeTitle =
    trimTitle.charAt(0).toLocaleUpperCase() + trimTitle.slice(1);

  return camelizeTitle;
};

export const buildSlug = (str) =>
  str
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '');

export const handleMoneyChange = (value) => {
  if (value && value?.includes('.')) {
    if (value.split('.')[1].length >= 2) {
      var num = parseFloat(value);
      var cleanNum = num.toFixed(2);
      // Check if the cleaned value is a number
      if (!isNaN(cleanNum)) {
        return cleanNum;
      } else {
        // Return null or any other value to indicate it's not a valid number
        return null;
      }
    }
  }
  return value;
};

export const handleMaxValue = (value, max) => {
  try {
    const numericValue = typeof value === 'string' ? parseFloat(value) : value;
    const numericMax = typeof max === 'string' ? parseFloat(max) : max;
    if (isNaN(numericValue) || isNaN(numericMax)) {
      throw new Error('Invalid input: Not a valid number');
    }
    return numericValue > numericMax
      ? numericMax
      : numericValue < 0
      ? 0
      : numericValue;
  } catch (error) {
    return value;
  }
};

export const formatAddress = (value) => {
  let address_line1 = undefined;

  if (value?.street_number && value?.route) {
    address_line1 = `${value?.street_number?.long_name} ${value?.route?.short_name}`;
  } else {
    address_line1 =
      value?.street_number?.long_name ||
      value?.route?.long_name ||
      value?.sublocality_level_2?.long_name ||
      value?.sublocality_level_1?.long_name ||
      value?.premise?.long_name ||
      value?.subpremise?.long_name;
  }

  const address_line2 =
    value?.premise?.long_name ||
    value?.subpremise?.long_name ||
    value?.sublocality_level_2?.long_name ||
    value?.sublocality_level_1?.long_name;

  return {
    zip_code: value?.postal_code?.long_name,
    country: value?.country?.short_name,
    state: value?.administrative_area_level_1?.short_name,
    city:
      value?.locality?.long_name ||
      value?.administrative_area_level_2?.long_name,
    lat: value?.lat,
    lon: value?.lon,
    location: value?.location,
    address_line1,
    address_line2,
  };
};

export const getTrackingUrl = (provider, trackingNumber) => {
  switch (provider) {
    case 'usps':
      return `https://tools.usps.com/go/TrackConfirmAction_input?qtc_tLabels1=${trackingNumber}`;
    case 'ups':
      return `https://www.ups.com/track?loc=en_US&requester=ST&trackNums=${trackingNumber}`;
    case 'fedex':
      return `https://www.fedex.com/wtrk/track/?cntry_code=us&tracknumbers=${trackingNumber}`;
    case 'dhl_express':
      return `https://www.dhl.com/us-en/home/tracking/tracking-express.html?submit=1&tracking-id=${trackingNumber}`;
    case 'dhl_ecommerce':
      return `https://webtrack.dhlecs.com/orders?trackingNumber=${trackingNumber}`;
    default:
      return '';
  }
};

export const getCarrierName = (provider) => {
  switch (provider) {
    case 'usps':
      return 'USPS';
    case 'ups':
      return 'UPS';
    case 'fedex':
      return 'FedEx';
    case 'dhl_express':
      return 'DHL Express';
    case 'dhl_ecommerce':
      return 'DHL eCommerce';
    default:
      return '';
  }
};

export const parseIntSafe = (param) => {
  if (isNaN(param)) return undefined;
  return Number(param);
};

export const generateRandomNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};
export const removeEmptyFields = (obj) => {
  for (let key in obj) {
    if (obj[key] === null || obj[key] === undefined || obj[key] === '') {
      delete obj[key];
    } else if (typeof obj[key] === 'object') {
      removeEmptyFields(obj[key]);
      if (keysFromObject(obj[key]).length === 0) {
        delete obj[key];
      }
    }
  }
  return obj;
};
export const objectToParams = (obj) => {
  const params = new URLSearchParams();
  keysFromObject(obj).forEach((key) => {
    if (Array.isArray(obj[key])) {
      obj[key].forEach((value, index) => {
        params.append(`${key}[${index}]`, value);
      });
    } else {
      params.append(key, obj[key]);
    }
  });

  return params.toString();
};

export const checkPermission = (profile, action, method = 'GET') => {
  return (
    profile?.type === 'seller' ||
    profile?.permission?.is_super_admin ||
    profile?.permission?.actions?.includes(`${action}.${method}`)
  );
};

export const getFileInfo = (mimeType) => {
  if (!mimeType) return { isNonType: true };
  const mimeTypes = {
    'image/jpeg': 'jpg',
    'image/png': 'png',
    'image/gif': 'gif',
    'image/webp': 'webp',
    'image/svg+xml': 'svg',
    'text/html': 'html',
    'text/css': 'css',
    'text/javascript': 'js',
    'application/json': 'json',
    'application/pdf': 'pdf',
    'application/zip': 'zip',
    'audio/mpeg': 'mp3',
    'audio/ogg': 'ogg',
    'video/mp4': 'mp4',
    'video/webm': 'webm',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      'docx',
    // Add more mappings as needed
  };

  const isImage = mimeType.startsWith('image/');
  const ext = (
    mimeTypes[mimeType?.replace('image/image/', 'image/')] || 'unknown'
  ).toUpperCase();

  return { ext, isImage };
};

export const keysFromObject = (obj) => Object.keys(obj);

export const valuesFromObject = (obj) => Object.values(obj);

export const entriesFromObject = (obj) => Object.entries(obj);

export const buildFullName = (item) =>
  `${item?.first_name || '-'} ${item?.last_name || '-'}`;
