import { ShippingInformationTypeEnum } from 'constants/orders';
import React from 'react';
import { OrderStatusEnum } from 'types';
import { Grid } from '@mantine/core';
import ShippingLabelItems from './ShippingLabelItems';
import PackageParcelItems from './PackageParcelItems';
import TrackingNumberItems from './TrackingNumberItems';
import CustomShippingItems from './CustomShippingItems';

const FulfillSummary = ({ data }: any) => {
  const shipment = data?.shipment;
  const isFulfilled =
    data?.order_status !== OrderStatusEnum.PROCESSING &&
    shipment &&
    Object.keys(shipment).length > 0;
  const isShippo = shipment?.type === ShippingInformationTypeEnum.Shippo;
  const isManual = shipment?.type === ShippingInformationTypeEnum.Manual;
  const isCustom = shipment?.type === ShippingInformationTypeEnum.Custom;

  const renderShippo = () => {
    return (
      <>
        <Grid.Col span={{ base: 12, lg: 'auto' }}>
          <ShippingLabelItems shipment={shipment} />
        </Grid.Col>

        {shipment?.parcels?.length ? (
          <Grid.Col span={{ base: 12, lg: 5 }}>
            <PackageParcelItems parcels={shipment?.parcels} />
          </Grid.Col>
        ) : null}
      </>
    );
  };

  const renderManual = () => {
    return (
      <>
        <Grid.Col span={{ base: 12, md: 12 }}>
          <TrackingNumberItems shipment={shipment} />
        </Grid.Col>
      </>
    );
  };

  const renderCustom = () => {
    return (
      <Grid.Col span={{ base: 12, md: 12 }}>
        <CustomShippingItems shipment={shipment} />
      </Grid.Col>
    );
  };
  return (
    <React.Fragment>
      {isFulfilled ? (
        <>
          <Grid className="mx-1 my-3 p-3 bg-200 rounded-3 gap-4">
            {isShippo
              ? renderShippo()
              : isManual
              ? renderManual()
              : isCustom
              ? renderCustom()
              : null}
          </Grid>
        </>
      ) : null}
    </React.Fragment>
  );
};

export default FulfillSummary;
