const partnerMenuRoutes = [
  {
    label: 'Affiliate program',
    icon: 'chart-pie',
    to: '/',
  },
  {
    label: 'Account',
    icon: 'user-circle',
    to: '/account',
  },
];

export default partnerMenuRoutes;
