import { StatusEnum } from './common.type';
import { CustomerItem } from './customer.type';

export type ProductItem = {
  _id?: string;
  name?: string;
  slug?: string;
  desc?: string;
  image_url?: string;
  total_variant?: number;
  number_sold?: number;
  average_rating?: number;
  brand?: any;
  category?: any;
  sub_category?: any;
  status: StatusEnum;
};

export enum WeightUnitEnum {
  LB = 'lb',
  OZ = 'oz',
}

export enum DimensionsUnit {
  IN = 'in',
  FT = 'ft',
}
export enum PurchaseLimitPerEnum {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
}

export type VariantItem = {
  _id?: string;
  name: string;
  slug: string;
  sku?: string;
  images: string[];
  selling_price: number;
  original_price: number;
  barcode?: string;
  weight: number;
  weight_unit: WeightUnitEnum; // lb, oz
  dimensions?: {
    length: number;
    width: number;
    height: number;
    unit: DimensionsUnit; // in, ft
  };
  material?: string;
  size?: string;
  status: StatusEnum; // active, draft, closed
  number_sold: number;
  low_stock_threshold?: number;
  position: number;
  description?: string;
  is_purchase_limit: boolean;
  purchase_limit?: {
    quantity: number;
    per_type: PurchaseLimitPerEnum; // day, week, month
  };
  available_stock: number;
  quantity: number;
};
export type ProductReviewItem = {
  _id: string;
  title: string;
  content: string;
  status: StatusEnum;
  image_urls: string[];
  tags: string[];
  rating: number;
  product: ProductItem;
  variant: VariantItem;
  customer: CustomerItem;
  is_featured: boolean;
  updated_on: number;
  created_on: number;
};

export enum ProductStatusEnum {
  ALL = 'all',
  ACTIVE = 'active',
  DRAFT = 'draft',
  CLOSED = 'closed',
  PENDING = 'pending',
  REJECTED = 'rejected',
}

export const ProductTabStatuses = {
  [ProductStatusEnum.ALL]: {
    label: 'general.all',
  },
  [ProductStatusEnum.ACTIVE]: {
    label: 'general.active',
  },
  [ProductStatusEnum.DRAFT]: {
    label: 'general.draft',
  },
  [ProductStatusEnum.PENDING]: {
    label: 'general.pending',
  },
  [ProductStatusEnum.REJECTED]: {
    label: 'general.rejected',
  },
  [ProductStatusEnum.CLOSED]: {
    label: 'general.closed',
  },
};
