import {
  Button,
  Flex,
  Grid,
  InputError,
  InputLabel,
  NativeSelect,
  NumberInput,
  Switch,
  Text,
  TextInput,
} from '@mantine/core';
import { DatePickerInput, DateTimePicker, DateValue } from '@mantine/dates';
import { Form, useForm, yupResolver } from '@mantine/form';
import { PromotionType } from 'types';
import { usePromotionDetail } from 'apis/Promotion';
import CropImageModal from 'components/common/CropImageModal';
import SelectIconComponent from 'components/common/SelectIconComponent';
import {
  SectionBody,
  SectionFooter,
  SectionHeader,
  SectionWrapper,
} from 'components/MantineUI/CommonSection';
import RichTextInput from 'components/MantineUI/Inputs/RichTextInput';
import SlugInput from 'components/MantineUI/Inputs/SlugInput';
import PageLayout from 'components/MantineUI/PageLayout';
import StatusSelect from 'components/MantineUI/Selects/StatusSelect';
import { t } from 'i18next';
import DragDropImages from 'pages/products/add-product/components/DragDropImages';
import { ReactNode, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import dayjs from 'dayjs';
import { imageUrlToId } from 'pages/dashboard/components';
import useRequestImage from 'hooks/file/useGenerateImageId';
import {
  useCreatePromotion,
  useUpdatePromotions,
} from 'hooks/marketplace/promotion';
import { toast } from 'react-toastify';
import { buildSlug } from 'helpers/utils';
import { useQueryClient } from '@tanstack/react-query';
import { useURLParams } from 'hooks/useURLParams';

type PropType = {
  children?: ReactNode;
  className?: string;
};

enum LoadingType {
  SAVE = 'save',
  SELECT = 'select',
}

const MAX_IMAGES = 6;
const PromotionDetails = (props: PropType) => {
  // ### CONSTANTs
  const { children, className = '' } = props;
  const [isChanged, setIsChanged] = useState(false);
  const [loading, setLoading] = useState<LoadingType>();
  const [imgToCrop, setImageToCrop] = useState(null);
  const { id } = useParams();
  const { params: paramsUrl } = useURLParams() as any;
  const { data, isLoading }: any = usePromotionDetail(id as string);
  const isAdd = !data?.data?._id;
  const navigate = useNavigate();
  const { createImgIDs } = useRequestImage();
  const { mutate: createPromotion }: any = useCreatePromotion();
  const { mutate: updatePromotion }: any = useUpdatePromotions(id);
  const queryClient = useQueryClient();
  const promotionScheme = yup.object({
    name: yup
      .string()
      .required(
        t('form.invalid.required', { fieldName: t('promotions.title') }),
      ),
    slug: yup.string().required(
      t('form.invalid.required', {
        fieldName: t('promotions.promotion_slug'),
      }),
    ),
    desc: yup.string().nullable(),
    sub_title: yup.string(),
    start_date: yup
      .string()
      .required(
        t('form.invalid.required', { fieldName: t('promotions.start_date') }),
      ),
    end_date: yup
      .string()
      .required(
        t('form.invalid.required', { fieldName: t('promotions.end_date') }),
      ),
    images: yup
      .array()
      .required(t('form.invalid.required', { fieldName: t('general.images') })),
  });
  const form = useForm({
    initialValues: {
      is_new_user: true,
      type: paramsUrl?.type || PromotionType.BANNER,
      slug: '',
      name: '',
      desc: '',
      sub_title: '',
      start_date: undefined,
      end_date: undefined,
      images: undefined,
      icon: '',
      is_max_purchase_product: false,
    } as any,
    validate: yupResolver(promotionScheme),
  });
  const { getInputProps, getValues, setValues, errors } = form;
  const values = getValues();

  // ### FUNCTIONs
  useEffect(() => {
    if (!isAdd) {
      setValues({
        is_new_user: data?.data?.is_new_user,
        type: data?.data?.type,
        slug: data?.data?.slug,
        name: data?.data?.name,
        suffix_text: data?.data?.suffix_text,
        desc: data?.data?.desc,
        is_max_purchase_product: !!data?.data?.max_purchase_product,
        max_purchase_product: data?.data?.max_purchase_product,
        sub_title: data?.data?.sub_title,
        start_date: data?.data?.start_date
          ? dayjs.unix(data?.data?.start_date).toDate()
          : null,
        end_date:
          data?.data?.end_date && dayjs.unix(data?.data?.end_date).toDate(),
        images: data?.data?.images?.map?.((x: any) => x?.image_url),
        icon: data?.data?.icon,
        status: data?.data?.status,
      });
    }
  }, [data]);

  const { getRootProps, getInputProps: getInputPropsDZ } = useDropzone({
    accept: 'image/*',
    maxFiles: 1,
    onDrop: async (acceptedFiles) => {
      const file: any = await URL.createObjectURL(acceptedFiles?.[0]);
      setImageToCrop(file);
    },
  });
  const onBack = () => {
    if (isChanged) {
      //   return onOpenDiscard();
    }
    navigate('/promotions');
  };

  const onSaveImage = async (croppedImage: any) => {
    // setIsChanged(true);
    let images = [...(values.images || [])];
    images.push(croppedImage);
    setValues({ images: images?.slice(0, 6) });
    setImageToCrop(null);
  };
  const onSave = async () => {
    await form.validate();
    if (form.isValid()) {
      handleSubmitBtn(LoadingType.SAVE);
    }
  };
  const onSaveAndSelect = async () => {
    await form.validate();
    if (form.isValid()) {
      handleSubmitBtn(LoadingType.SELECT);
    }
  };

  const handleSubmitBtn = async (loadingType: LoadingType) => {
    setLoading(loadingType);
    const {
      images,
      name,
      slug,
      desc,
      suffix_text,
      start_date,
      end_date,
      status,
      icon,
      type,
      is_new_user,
      max_purchase_product,
      is_max_purchase_product,
    } = values;
    try {
      const image_ids = await createImgIDs(images, 'promotions', false, {
        maxWidth: 2000,
        maxHeight: 769,
      });
      const params: any = {
        name: name,
        slug: slug,
        is_new_user: is_new_user,
        desc: desc || (isAdd ? undefined : null),
        suffix_text: suffix_text,
        max_purchase_product: max_purchase_product,
        is_max_purchase_product: !!(
          max_purchase_product && is_max_purchase_product
        ),
        start_date: dayjs(start_date).unix(),
        end_date: dayjs(end_date).unix(),
        images:
          image_ids?.map?.((x: any, i: any) => ({
            is_main: !i,
            media_id: imageUrlToId(x),
          })) || [],
        type: type,
        status: status,
        icon: icon || undefined,
        _id: isAdd ? undefined : id,
      };
      if (isAdd) {
        createPromotion(params, {
          onSuccess: (res: { data: { _id: any } }) => {
            if (res?.data) {
              if (loadingType === LoadingType.SELECT) {
                navigate(`/promotions/${res?.data?._id}/add-products`);
              } else {
                navigate(`/promotions`);
              }
              toast.success(t('general.create_success'));
              queryClient.invalidateQueries([
                'usePromotionDetail',
                'useAdminPromotions',
              ] as any);
              setLoading(undefined);
            }
          },
          onError: (res: any) => {
            toast.error(res?.message);
            setLoading(undefined);
          },
        });
      } else {
        updatePromotion(
          { promotions: [params] },
          {
            onSuccess: (res: { status: any }) => {
              if (res?.status) {
                if (loadingType === LoadingType.SELECT) {
                  navigate(`/promotions/${id}/add-products`);
                }
                toast.success(t('general.update_success'));
                queryClient.invalidateQueries([
                  'usePromotionDetail',
                  'useAdminPromotions',
                ] as any);
                setLoading(undefined);
              }
            },
            onError: (res: any) => {
              toast.error(res?.message);
              setLoading(undefined);
            },
          },
        );
      }
    } catch (err: any) {
      setLoading(undefined);
    }
  };

  // ### RENDERs
  const title = isAdd ? t('promotions.create_promotion') : data?.data?.name;
  return (
    <PageLayout
      title={title}
      breadCrumds={[
        {
          title: t('navigation.promotions'),
          href: '/promotions',
        },
        { title: title },
      ]}
    >
      <SectionWrapper>
        <SectionHeader
          title={
            isAdd
              ? t('promotions.create_promotion')
              : t('promotions.edit_promotion')
          }
        />
        <SectionBody>
          <Form form={form}>
            <Grid>
              <Grid.Col span={{ base: 6 }}>
                <NativeSelect
                  {...getInputProps('type')}
                  label={t('general.type')}
                  data={[
                    {
                      label: t('navigation.banners'),
                      value: 'banner',
                    },
                    {
                      label: t('navigation.flash_sale'),
                      value: 'for_sale',
                    },
                  ]}
                />
              </Grid.Col>

              <Grid.Col span={{ base: 6 }}>
                <InputLabel>{t('promotions.for_new_user')}</InputLabel>
                <Switch
                  {...getInputProps('is_new_user')}
                  checked={values?.is_new_user}
                />
              </Grid.Col>
              <Grid.Col span={{ base: 6 }}>
                <TextInput
                  maxLength={30}
                  {...getInputProps('name')}
                  label={t('promotions.title')}
                  required
                  placeholder="Black Friday Sale"
                  onChange={(e) =>
                    setValues({
                      name: e.target.value,
                      slug: isAdd ? buildSlug(e.target.value) : values?.slug,
                    })
                  }
                />
              </Grid.Col>
              <Grid.Col span={{ base: 6 }}>
                <SlugInput
                  required
                  type={'promotion'}
                  value={values?.slug}
                  defaultValue={values?.slug}
                  placeholder="promotion-slug"
                  prefix="https://nailzy.com/promotions/"
                  onChange={(event) => {
                    // setIsChanged(true);
                    setValues({ slug: event.target.value });
                  }}
                  errorMessage={errors?.slug}
                />
              </Grid.Col>
              <Grid.Col span={{ base: 6 }}>
                <TextInput
                  maxLength={30}
                  {...getInputProps('suffix_text')}
                  label={t('promotions.sub_title')}
                  placeholder="Up to 20% OFF"
                />
              </Grid.Col>
              <Grid.Col span={{ base: 6 }}>
                <SelectIconComponent
                  icon={values?.icon || ''}
                  onIconChange={(icon: string) => {
                    // setIsChanged(true);
                    setValues({ icon });
                  }}
                />
              </Grid.Col>

              <Grid.Col span={{ base: 12 }}>
                <InputLabel>{t('general.description')}</InputLabel>
                <RichTextInput
                  value={values?.desc}
                  onChange={(text) => setValues({ desc: text })}
                />
              </Grid.Col>
              <Grid.Col span={{ base: 6 }}>
                {/* <DateTimePicker
                  required
                  minDate={new Date()}
                  label={t('promotions.start_date')}
                  placeholder={t('general.select')}
                  {...getInputProps('start_date')}
                  onChange={(e) => {
                    setValues({
                      start_date: e,
                      end_date: null,
                    });
                  }}
                /> */}

                <DateTimePicker
                  minDate={new Date()}
                  required
                  {...getInputProps('start_date')}
                  onChange={(e: any) => {
                    const now = new Date(); // Get the current date and time
                    const selectedDate = new Date(e); // Convert e to a Date object (if not already)
                    setValues({
                      start_date: selectedDate < now ? now : selectedDate, // Use current time if e is in the past
                      end_date: '', // Provide a valid default value for `end_date`, e.g., an empty string
                    });
                  }}
                  valueFormat="DD MMM YYYY hh:mm A"
                  label={t('promotions.start_date')}
                  placeholder={t('general.select_date_time')}
                />
              </Grid.Col>
              <Grid.Col span={{ base: 6 }}>
                <DateTimePicker
                  required
                  minDate={
                    values?.start_date
                      ? new Date(
                          new Date(values.start_date).setDate(
                            new Date(values.start_date).getDate() + 1,
                          ),
                        )
                      : new Date()
                  }
                  {...getInputProps('end_date')}
                  valueFormat="DD MMM YYYY hh:mm A"
                  label={t('promotions.end_date')}
                  placeholder={t('general.select_date_time')}
                />
                {/* <DateTimePicker
                  required
                  minDate={values?.start_date ? values?.start_date : new Date()}
                  label={t('promotions.end_date')}
                  placeholder={t('general.select')}
                  {...getInputProps('end_date')}
                /> */}
              </Grid.Col>
              <Grid.Col span={{ base: 6 }}>
                <StatusSelect
                  value={values?.status}
                  onChange={(e) => setValues({ status: e.target.value })}
                />
              </Grid.Col>
              <Grid.Col span={{ base: 6 }}>
                <InputLabel>{t('promotions.max_purcharse_product')}</InputLabel>
                <Flex gap={'xs'} align={'center'}>
                  <Switch
                    {...getInputProps('is_max_purchase_product')}
                    checked={values?.is_max_purchase_product}
                    label={t('promotions.customer_can_buy_any')}
                    onChange={(e) => {
                      setValues({
                        is_max_purchase_product: e.target.checked,
                        max_purchase_product: e.target.checked
                          ? values?.max_purchase_product
                          : 0,
                      });
                    }}
                  />
                  <NumberInput
                    disabled={!values?.is_max_purchase_product}
                    {...getInputProps('max_purchase_product')}
                    w={100}
                    min={0}
                    placeholder="0"
                  />
                  <Text size="sm">{t('promotions.product_s')}</Text>
                </Flex>
              </Grid.Col>
              <Grid.Col span={{ base: 6 }}></Grid.Col>
              <Grid.Col span={{ base: 12 }}>
                <InputLabel required>{t('general.images')}</InputLabel>
                <DragDropImages
                  direction={'horizontal'}
                  onChange={(array: any) => setValues({ images: array })}
                  containerClassName={'d-flex'}
                  key={`promotion_images`}
                  data={Array.from(Array(MAX_IMAGES).keys())?.map((x, i) => {
                    if (typeof values?.images?.[i] === 'string') {
                      return {
                        _id: imageUrlToId(values?.images?.[i]),
                        value: values?.images?.[i],
                      };
                    } else {
                      const previewUrl = values?.images?.[i]
                        ? URL.createObjectURL(values?.images?.[i] as any)
                        : undefined;
                      return {
                        _id: x,
                        value:
                          values?.images?.[i] &&
                          Object.assign(values?.images?.[i], {
                            preview: previewUrl,
                          }),
                        isEmpty: !values?.images?.[i],
                      };
                    }
                  })}
                  handleRemoveImage={(indexDeleted: number) => {
                    // setIsChanged(true);
                    setValues({
                      images: values.images.filter(
                        (_file: any, index: any) => index !== indexDeleted,
                      ),
                    });
                  }}
                  getRootProps={getRootProps}
                  getInputProps={getInputPropsDZ}
                />
                <InputError className="mt-2">{errors?.images}</InputError>
              </Grid.Col>
            </Grid>
          </Form>
        </SectionBody>
        <SectionFooter updatedTime={data?.data?.updated_on}>
          <Button
            loading={loading === LoadingType.SELECT}
            onClick={onSaveAndSelect}
          >
            {t('promotions.save_and_select_product')}
          </Button>
          <Button
            loading={loading === LoadingType.SAVE}
            onClick={onSave}
            variant="default"
          >
            {t('general.save')}
          </Button>
          <Button variant="default" onClick={onBack}>
            {t('general.back')}
          </Button>
        </SectionFooter>
        <CropImageModal
          imgToCrop={imgToCrop}
          onHide={() => setImageToCrop(null)}
          onSave={(file: any) => onSaveImage(file)}
          cropOptions={{ width: 2000, height: 769 }}
        />
      </SectionWrapper>
    </PageLayout>
  );
};
export default PromotionDetails;
