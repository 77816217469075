import { AffiliateItem } from 'types';
import { useAffiliateDetails } from 'apis/AffiliateApis';
import PageLayout from 'components/MantineUI/PageLayout';
import { t } from 'i18next';
import { useNavigate, useParams } from 'react-router-dom';
import SkeletonWrapper from 'components/MantineUI/Skeleton/SkeletonWrapper';
import SectionPageSkeleton from 'components/MantineUI/Skeleton/SectionPageSkeleton';
import AffiliateDetailsSections from './AffiliateDetailsSections';

const AffiliateDetailsPage = () => {
  // ### CONSTANTs
  const { id } = useParams();
  const navigate = useNavigate();
  const { data, isLoading } = useAffiliateDetails(id);
  const affiliate: AffiliateItem = data?.data;
  // ### FUNCTIONs
  // ### RENDERs
  const title = affiliate?.display_name || '';

  return (
    <SkeletonWrapper skeleton={<SectionPageSkeleton />} show={isLoading}>
      <PageLayout
        title={title}
        breadCrumds={[
          {
            title: t('affiliates.affiliates'),
            href: '/affiliates',
          },
          {
            title: title,
          },
        ]}
      >
        <AffiliateDetailsSections affiliate={affiliate} />
      </PageLayout>
    </SkeletonWrapper>
  );
};
export default AffiliateDetailsPage;
