import {
  Button,
  Flex,
  Grid,
  InputLabel,
  Modal,
  NumberInput,
  Select,
  Switch,
  TextInput,
} from '@mantine/core';
import { AffiliateItem, AffiliateTypeEnum, StatusEnum } from 'types';
import { t } from 'i18next';
import SlugInput from 'components/MantineUI/Inputs/SlugInput';
import StatusSelect from 'components/MantineUI/Selects/StatusSelect';
import { SectionFooter } from 'components/MantineUI/CommonSection';
import { Form, useForm } from '@mantine/form';
import { buildFullName, buildSlug } from 'helpers/utils';
import {
  useAffiliateDetails,
  useCreateAffiliate,
  useUpdateAffiliate,
} from 'apis/AffiliateApis';
import { useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import CustomerSelect from 'components/MantineUI/Selects/CustomerSelect';
import PartnerSelect from 'components/MantineUI/Selects/PartnerSelect';
import LoadingOverlayWrapper from 'components/MantineUI/LoadingOverlayWrapper';
type PropType = {
  opened: boolean;
  onClose: () => void;
  data?: AffiliateItem;
};

export const AffiliateTypeLabels = {
  [AffiliateTypeEnum.PARTNER]: 'partners.partner',
  [AffiliateTypeEnum.CUSTOMER]: 'orders.customer',
};
const AddAffiliateModal = (props: PropType) => {
  // ### CONSTANTs
  const {
    opened = false,
    onClose: onCloseProps,
    data: affiliateInList,
  } = props;
  const {
    data: affiliateDetail,
    isLoading,
    isFetching,
  } = useAffiliateDetails(affiliateInList?._id);
  const data = affiliateDetail?.data;
  const isEdit = affiliateInList?._id && data?._id;
  const queryClient = useQueryClient();
  // ### FUNCTIONs
  const form = useForm({
    initialValues: {
      display_name: undefined,
      slug: undefined,
      commission_rate: undefined,
      discount: undefined,
      status: StatusEnum.ACTIVE,
      is_discount: false,
      type: 'partner',
    } as AffiliateItem & { is_discount: boolean },
  });

  const onClose = () => {
    form.reset();
    onCloseProps?.();
  };
  const { setValues, getInputProps, getValues, onSubmit } = form;
  const values = getValues();
  const { mutate: create, isPending: creating } = useCreateAffiliate();
  const { mutate: update, isPending: updating } = useUpdateAffiliate();
  const onSubmitForm = () => {
    let submitData = {
      display_name: values?.display_name,
      slug: values?.slug,
      commission_rate: parseFloat(
        ((values?.commission_rate || 0) / 100).toFixed(4),
      ),
      discount: values?.discount || null,
      status: values?.status,
      type: values?.type,
      customer_id:
        values?.type === 'customer' ? values?.customer_id : undefined,
      partner_id: values?.type === 'partner' ? values?.partner_id : undefined,
      //   is_discount: values?.is_discount,
    };
    if (isEdit) {
      update(
        { _id: data?._id, ...submitData, type: undefined },
        {
          onSuccess: () => {
            onClose();
            queryClient.invalidateQueries(['useAffiliateDetails'] as any);
          },
        },
      );
    } else {
      create(submitData, {
        onSuccess: () => {
          onClose();
          queryClient.invalidateQueries(['useAffiliateDetails'] as any);
        },
      });
    }
  };

  //   const { data: affiliate } = useAffiliateDetails(data?._id);
  useEffect(() => {
    if (affiliateInList?._id && data?._id) {
      setValues({
        display_name: data?.display_name,
        slug: data?.slug,
        commission_rate: (data?.commission_rate || 0) * 100,
        discount: data?.discount,
        status: data?.status,
        type: data?.type,
        customer_id: data?.type === 'customer' ? data?.owner?._id : undefined,
        customer_name:
          data?.type === 'partner' ? buildFullName(data?.owner) : undefined,
        partner_id: data?.type === 'partner' ? data?.partner?._id : undefined,
        partner_name:
          data?.type === 'partner' ? data?.partner?.full_name : undefined,
        is_discount: !!(data?.discount && data?.discount > 0),
      });
    }
  }, [affiliateInList, data]);
  // ### RENDERs
  return (
    <Modal
      size={'xl'}
      onClose={onClose}
      opened={opened}
      title={
        isEdit ? t('affiliates.edit_affiliate') : t('affiliates.add_affiliate')
      }
    >
      <LoadingOverlayWrapper loading={isLoading || isFetching}>
        <Form form={form} onSubmit={onSubmitForm}>
          <Grid>
            {isEdit ? null : (
              <>
                <Grid.Col span={{ base: 12, sm: 6 }}>
                  <Select
                    required
                    placeholder={t('general.type')}
                    {...getInputProps('type')}
                    label={t('general.type')}
                    value={values?.type}
                    data={[
                      {
                        label: t(AffiliateTypeLabels.partner),
                        value: 'partner',
                      },
                      {
                        label: t(AffiliateTypeLabels.customer),
                        value: 'customer',
                      },
                    ]}
                  />
                </Grid.Col>

                <Grid.Col span={{ base: 12, sm: 6 }}>
                  {values.type === 'customer' ? (
                    <CustomerSelect
                      required
                      onChange={(e) => {
                        setValues({
                          customer_id: e?.value,
                          customer_name: e?.label,
                        });
                      }}
                      value={{
                        value: values?.customer_id || null,
                        label: values?.customer_name || null,
                      }}
                    />
                  ) : (
                    <PartnerSelect
                      required
                      onChange={(e) => {
                        setValues({
                          partner_id: e?.value,
                          partner_name: e?.label,
                        });
                      }}
                      value={{
                        value: values?.partner_id || '',
                        label: values?.partner_name || '',
                      }}
                    />
                  )}
                </Grid.Col>
              </>
            )}

            <Grid.Col span={{ base: 12, sm: 12 }}>
              <TextInput
                maxLength={30}
                required
                label={t('affiliates.account_display_name')}
                placeholder="Nails by Nailzzzz"
                {...getInputProps('display_name')}
                onChange={(e) => {
                  setValues({
                    display_name: e.target.value,
                    slug: isEdit ? values?.slug : buildSlug(e.target.value),
                  });
                }}
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, sm: 12 }}>
              <SlugInput
                key={'slug' + data?._id}
                type={'affiliate_program'}
                defaultValue={data?.slug}
                label={t('affiliates.store_link')}
                placeholder="affiliate-slug"
                prefix="https://nailzy.com/affiliates/"
                value={values?.slug}
                onChange={(event: any) => {
                  setValues({
                    slug: buildSlug(event.target.value),
                  });
                }}
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, sm: 6 }}>
              <NumberInput
                {...getInputProps('commission_rate')}
                label={t('affiliates.commission_rate')}
                placeholder="3%"
                decimalScale={2}
                min={0}
                max={100}
                suffix="%"
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, sm: 6 }}>
              <InputLabel>{t('affiliates.extra_discount')}</InputLabel>
              <Flex gap={'sm'}>
                <Switch
                  checked={values?.is_discount}
                  {...getInputProps('is_discount')}
                  onChange={(e) =>
                    setValues({
                      is_discount: e.target.checked,
                      discount: e.target.checked ? values?.discount : 0,
                    })
                  }
                />
                <NumberInput
                  flex={1}
                  {...getInputProps('discount')}
                  placeholder="0%"
                  decimalScale={2}
                  disabled={!values?.is_discount}
                  min={0}
                  max={100}
                  suffix="%"
                />
              </Flex>
            </Grid.Col>
            <Grid.Col span={{ base: 12, sm: 6 }}>
              <StatusSelect
                hideDraft
                hidePending={false}
                value={values?.status}
                onChange={(e) =>
                  setValues({ status: e.target.value as StatusEnum })
                }
              />
            </Grid.Col>
          </Grid>
          <SectionFooter updatedTime={data?.updated_on}>
            <Button loading={updating || creating} type="submit">
              {t('general.save')}
            </Button>
            <Button variant="default" onClick={() => onClose()}>
              {t('general.cancel')}
            </Button>
          </SectionFooter>
        </Form>
      </LoadingOverlayWrapper>
    </Modal>
  );
};
export default AddAffiliateModal;
