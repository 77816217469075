import { forwardRef, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from '@mantine/core';
import { SectionBody, SectionFooter } from 'components/MantineUI/CommonSection';

/**
 * @param {bool} remove A boolean value to determine if the modal is for removing or deleting an item.
 * @param {string} name The name of the item to be deleted.
 * @param {bool} onShow A boolean value to detemine if the modal should be shown.
 * @param {func} onClose A function handle closing modal.
 * @param {func} onDelete A function handle deleting item.
 * @param {boolean} isLoading A boolean to determine if action is executing
 * @returns
 */
const DeleteWarningModal = (
  {
    remove,
    name = 'this item',
    onShow,
    onClose: onModalClose,
    onDelete,
    isLoading = false,
    title,
  },
  ref,
) => {
  const { t } = useTranslation();
  const [internalShow, setInternalShow] = useState(false);
  const [product, setProduct] = useState(null);
  useImperativeHandle(ref, () => ({
    show,
    hide,
  }));
  const show = (product) => {
    setInternalShow(true);
    setProduct(product);
  };
  const hide = (product) => {
    setInternalShow(false);
  };
  const action = (() => {
    if (remove && isLoading) return t('general.removing');
    if (remove && !isLoading) return t('general.remove');
    if (!remove && isLoading) return t('general.deleting');
    if (!remove && !isLoading) return t('general.delete');
  })();
  const onClose = () => {
    setInternalShow(false);
    onModalClose?.();
  };
  return (
    <Modal
      className="delete-warning-modal"
      opened={internalShow || onShow}
      onClose={onClose}
      centered
      title={
        title
          ? title
          : `${remove ? t('general.remove') : t('general.delete')}
            ${product?.name || name}?`
      }
    >
      <SectionBody>
        {t('general.delete_confirm')}{' '}
        {remove
          ? t('general.remove').toLowerCase()
          : t('general.delete').toLowerCase()}{' '}
        <strong className="text-danger">{product?.name || name}</strong>
        {t('general.cant_undone')}
      </SectionBody>
      <SectionFooter className="mt-3">
        <Button
          color="red"
          loading={isLoading}
          onClick={() => onDelete?.(product)}
        >
          {action}
        </Button>
        <Button variant="default" onClick={onClose}>
          {t('general.cancel')}
        </Button>
      </SectionFooter>
    </Modal>
  );
};

export default forwardRef(DeleteWarningModal);
