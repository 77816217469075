import { Anchor, Flex, Grid, Text } from '@mantine/core';
import { AffiliateItem, AffiliateTypeEnum } from 'types';
import { useAffiliateDetails, useDeleteAffiliate } from 'apis/AffiliateApis';
import {
  SectionBody,
  SectionHeader,
  SectionWrapper,
} from 'components/MantineUI/CommonSection';
import CustomerDetailsCard from 'pages/customers/customer-details/components/CustomerDetailsCard';
import { ReactElement, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import CopyButton from 'components/MantineUI/Buttons/CopyButton';
import IconFA from 'components/common/IconFA';
import { faGlobe } from '@fortawesome/pro-light-svg-icons';
import { buildFullName, phoneFormatter } from 'helpers/utils';
import PayoutsCard from './PayoutsCard';
import ReportCard from './ReportCard';
import AddAffiliateModal, { AffiliateTypeLabels } from './AddAffiliateModal';
import {
  faFacebook,
  faInstagram,
  faTiktok,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import DeleteWarningModal from 'components/common/DeleteWarningModal';
import { useUserProfile } from 'apis/AuthApis';
import { useTranslation } from 'react-i18next';

const AffiliateDetailsSections = ({
  affiliate,
}: {
  affiliate: AffiliateItem;
}) => {
  const { t } = useTranslation();
  // ### CONSTANTs
  const isPartner = affiliate.type === 'partner';
  const navigate = useNavigate();
  const [selectedAffiliate, setSelectedAffiliate] = useState<
    AffiliateItem | undefined
  >();
  const [deleteAffiliate, setDeleteAffiliate] = useState<
    AffiliateItem | undefined
  >();
  // ### FUNCTIONs
  const { mutate: onDeleteAffiliate, isPending: deleting } =
    useDeleteAffiliate();

  const onDelete = () => {
    onDeleteAffiliate(deleteAffiliate?._id, {
      onSuccess: () => {
        setDeleteAffiliate(undefined);
        navigate('/affiliates');
      },
    });
  };
  // ### RENDERs
  const renderInformation = (
    title: string | ReactElement = '--',
    isCopy: boolean = true,
    link: string = '',
  ) => {
    return (
      <Flex align={'center'} justify={'space-between'}>
        {link ? (
          <Anchor href={link} target="_blank">
            {title}
          </Anchor>
        ) : (
          <Text size="sm">{title}</Text>
        )}
        {isCopy && typeof title === 'string' ? (
          <CopyButton value={link || title} />
        ) : null}
        {/* {link ? (
          <IconFA
            color={'gray'}
            icon={faGlobe}
            onClick={() => window.open(link, '_blank')}
          />
        ) : null} */}
      </Flex>
    );
  };
  const title = affiliate?.display_name || '';
  const renderSocialName = (social: { platform: string; url: string }) => {
    const { platform, url } = social;
    let socName = platform;
    let icon: any = faGlobe;
    if (platform.includes('facebook')) {
      icon = faFacebook;
      socName = 'Facebook';
    }
    if (platform.includes('instagram')) {
      icon = faInstagram;
      socName = 'Instagram';
    }
    if (platform.includes('tiktok')) {
      icon = faTiktok;
      socName = 'Tiktok';
    }
    if (platform.includes('youtube')) {
      icon = faYoutube;
      socName = 'Youtube';
    }
    if (platform.includes('other')) {
      socName = t('customer.other');
    }
    return (
      <Flex justify={'space-between'}>
        <Flex gap={'xs'}>
          <IconFA icon={icon} />
          {url ? (
            <Anchor target="_blank" href={url}>
              {socName}
            </Anchor>
          ) : (
            <Text>{socName}</Text>
          )}
        </Flex>
        {url ? <CopyButton value={url} /> : null}
      </Flex>
    );
  };
  const customer = isPartner ? affiliate?.partner : affiliate.owner;
  return (
    <div>
      <Grid>
        <Grid.Col span={{ base: 12, lg: 8 }}>
          <CustomerDetailsCard
            customer={{
              ...affiliate,
              profile_image_url: customer?.profile_image_url,
              first_name: affiliate?.display_name,
              last_name: '',
            }}
            hideActions={isPartner}
            customActions={[
              {
                key: 'edit',
                label: t('affiliates.edit_affiliate'),
                onClick: () => setSelectedAffiliate(affiliate),
              },
              {
                label: t('affiliates.view_orders'),
                key: 'view_orders',
                to: `/orders?page=1&limit=50&affiliate_program_id=${affiliate?._id}&affiliate_name=${affiliate?.display_name}`,
              },
              { isDivider: true },
              {
                key: 'delete',
                color: 'red',
                label: t('affiliates.delete_affiliate'),
                onClick: () => setDeleteAffiliate(affiliate),
              },
            ]}
          />
          <ReportCard affiliate_id={affiliate?._id || ''} />
          <PayoutsCard affiliate_id={affiliate?._id || ''} />
        </Grid.Col>
        <Grid.Col span={{ base: 12, lg: 4 }}>
          <SectionWrapper>
            <SectionHeader
              isSubSection
              title={t('form.information.information')}
            />
            <SectionBody>
              <Text size="sm" c="dimmed">
                {t('general.type')}
              </Text>
              {renderInformation(
                t(AffiliateTypeLabels[affiliate?.type as AffiliateTypeEnum]),
                false,
              )}

              <Text size="sm" c="dimmed" mt="sm">
                {t('general.slug')}
              </Text>
              {renderInformation(affiliate?.slug)}

              <Text size="sm" c="dimmed" mt="sm">
                {t('affiliates.affiliate_code')}
              </Text>
              {renderInformation(affiliate?.code)}

              <Text size="sm" c="dimmed" mt="sm">
                {t('affiliates.affiliate_link')}
              </Text>
              {renderInformation(
                'nailzy.com/?affiliate_code=' + affiliate?.code,
                true,
                process.env.REACT_APP_WEBSITE_URL +
                  '/?affiliate_code=' +
                  affiliate?.code,
              )}

              <Text size="sm" c="dimmed" mt="sm">
                {t('affiliates.commission_rate')}
              </Text>
              {renderInformation(
                affiliate?.commission_rate && affiliate?.commission_rate >= 0
                  ? ((affiliate?.commission_rate || 0) * 100).toFixed(2) + '%'
                  : '--',
                false,
              )}

              <Text size="sm" c="dimmed" mt="sm">
                {t('affiliates.extra_discount')}
              </Text>
              {renderInformation(
                affiliate?.discount && affiliate?.discount >= 0
                  ? affiliate?.discount + '%'
                  : '--',
                false,
              )}

              <Text size="sm" c="dimmed" mt="sm">
                {isPartner
                  ? t('partners.partner_name')
                  : t('affiliates.customer_name')}
              </Text>
              <Anchor
                component={Link}
                to={isPartner ? '' : '/customers/' + customer?._id}
              >
                {customer?.full_name || buildFullName(customer)}
              </Anchor>

              <Text size="sm" c="dimmed" mt="sm">
                {t('register.form.email')}
              </Text>
              {renderInformation(customer?.email)}

              <Text size="sm" c="dimmed" mt="sm">
                {t('general.phone')}
              </Text>
              {renderInformation(
                phoneFormatter(
                  customer?.phone_number || customer?.business_phone,
                ),
              )}

              <Text size="sm" c="dimmed" mt="sm">
                {t('affiliates.social_links')}
              </Text>
              {affiliate?.socials?.map((item, index) => {
                return renderSocialName(item);
              })}
            </SectionBody>
          </SectionWrapper>
        </Grid.Col>
      </Grid>

      <AddAffiliateModal
        opened={!!selectedAffiliate}
        onClose={() => setSelectedAffiliate(undefined)}
        data={selectedAffiliate}
      />
      <DeleteWarningModal
        name={deleteAffiliate?.display_name}
        onShow={!!deleteAffiliate}
        onClose={() => setDeleteAffiliate(undefined)}
        onDelete={onDelete}
        isLoading={deleting}
      />
    </div>
  );
};
export default AffiliateDetailsSections;
