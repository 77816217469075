import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from '@mantine/core';
import { SectionBody, SectionFooter } from 'components/MantineUI/CommonSection';

const DiscardWarningModal = ({
  showDiscardWarning,
  handleCloseModal,
  goBack,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={t('general.discard_all')}
      opened={showDiscardWarning}
      onClose={handleCloseModal}
      centered
    >
      <SectionBody>{t('general.discard_all_desc')}</SectionBody>
      <SectionFooter className="mt-3">
        <Button color="red" onClick={goBack}>
          {t('general.discard')}
        </Button>
        <Button variant="default" onClick={handleCloseModal}>
          {t('general.cancel')}
        </Button>
      </SectionFooter>
    </Modal>
  );
};

export default DiscardWarningModal;
