import { Alert, Anchor, Badge, Button, Flex, Text } from '@mantine/core';
import { ShippingStatus } from 'types';
import IconFA from 'components/common/IconFA';
import CopyButton from 'components/MantineUI/Buttons/CopyButton';
import { ShippingInformationTypeEnum } from 'constants/orders';
import { currencyFormatter } from 'helpers/utils';
import { t } from 'i18next';
import { Link } from 'react-router-dom';

type PropType = {
  shipment?: any;
  title?: string;
};

const ShippingLabelItems = (props: PropType) => {
  // ### CONSTANTs
  const { shipment, title = t('orders.shipping_label') } = props;
  const shippo_rate = shipment?.shippo_rate;

  // ### FUNCTIONs
  const hasShipmentWithShippo =
    shipment?.type === ShippingInformationTypeEnum.Shippo;

  // ### RENDERs
  return hasShipmentWithShippo ? (
    <>
      <Text fw={'600'}>{title}</Text>

      {(shipment?.shippo_transactions
        ? shipment?.shippo_transactions
        : []
      )?.map?.((item: any, index: number) => {
        const trackingUrlProvider = item?.tracking_url_provider;
        const trackingNumber = item?.tracking_number;
        const labelUrl = item?.label_url;

        const trackingStatus =
          shipment?.shippo_transaction?.tracking_status?.status ||
          shipment?.shippo_transaction?.tracking_status;
        const trackingStatusDetails =
          shipment?.shippo_transaction?.tracking_status?.status_details;
        const trackingStatusSubstatus =
          shipment?.shippo_transaction?.tracking_status?.substatus;

        const isFailedDelivery =
          trackingStatus === ShippingStatus.RETURNED ||
          trackingStatusSubstatus?.action_required;

        return (
          <div key={index} className="mb-2">
            <Flex align={'center'}>
              <Anchor
                size="sm"
                fw={'600'}
                target="_blank"
                component={Link}
                to={trackingUrlProvider}
              >
                {trackingNumber}
              </Anchor>
              <CopyButton value={trackingNumber} />
              <Badge color="blue" variant="light">
                {trackingStatus}
              </Badge>
            </Flex>
            {trackingStatusDetails ? (
              <>
                <Text size="sm">
                  {t('general.status')}: {trackingStatusDetails}
                </Text>
                {isFailedDelivery ? (
                  <Alert
                    title={t('general.action_required')}
                    icon={<IconFA icon={'triangle-exclamation'} />}
                    color="red"
                    className="my-2"
                  >
                    {trackingStatusSubstatus?.text}
                  </Alert>
                ) : null}
              </>
            ) : null}
            <div>
              <Button
                size="xs"
                variant="outline"
                onClick={() => window?.open(labelUrl)}
              >
                {t('orders.print_shipping_label')}
              </Button>
            </div>
          </div>
        );
      }) || null}

      {shippo_rate ? (
        <>
          <Text size="sm">
            {shippo_rate?.provider} {shippo_rate?.servicelevel_name}{' '}
          </Text>
          <Text size="sm">
            {t('orders.est_delivery')}: {shippo_rate?.estimated_days}{' '}
            {t('orders.business_days')}
          </Text>
          <Text size="sm">
            {t('orders.amount')}:{' '}
            {currencyFormatter.format(shippo_rate?.amount)}
          </Text>
        </>
      ) : null}
    </>
  ) : null;
};
export default ShippingLabelItems;
