import { t } from 'i18next';
import IconFA from 'components/common/IconFA';
import { Flex, Grid, Text, Tooltip } from '@mantine/core';
import {
  SectionBody,
  SectionWrapper,
} from 'components/MantineUI/CommonSection';
import { currencyFormatter } from 'helpers/utils';
import { faCircleQuestion } from '@fortawesome/pro-light-svg-icons';

type PropType = {
  customer?: any;
};
const CustomerSaleSummary = (props: PropType) => {
  const { customer } = props;
  const renderSummaryItem = ({
    title,
    decs,
    value,
  }: {
    title: string;
    decs: string;
    value: string;
  }) => {
    return (
      <Grid.Col span={{ base: 12, sm: 3 }}>
        <div>
          <Flex>
            {title}
            <Tooltip label={decs}>
              <div>
                <IconFA
                  icon={faCircleQuestion}
                  color="gray"
                  className="ml-1"
                  size="sm"
                />
              </div>
            </Tooltip>
          </Flex>
        </div>
        <div className="my-2">
          <Text size="lg" fw={600}>
            {value || '--'}
          </Text>
        </div>
      </Grid.Col>
    );
  };
  return (
    <SectionWrapper>
      <SectionBody>
        <Grid>
          {[
            {
              title: t('dashboard.total_paid_orders'),
              decs: t('dashboard.total_paid_orders_desc'),
              value: customer?.sale_report?.total_order,
            },
            {
              title: t('dashboard.total_paid_sales'),
              decs: t('dashboard.total_paid_sales_desc'),
              value:
                customer?.sale_report?.total_sale &&
                currencyFormatter.format(customer?.sale_report?.total_sale),
            },
            {
              title: t('dashboard.total_unpaid_orders'),
              decs: t('dashboard.total_unpaid_orders_desc'),
              value: customer?.sale_report?.total_unpaid_order,
            },
            {
              title: t('dashboard.total_unpaid_sales'),
              decs: t('dashboard.total_unpaid_sales_desc'),
              value:
                customer?.sale_report?.total_unpaid_sale &&
                currencyFormatter.format(
                  customer?.sale_report?.total_unpaid_sale,
                ),
            },
          ]?.map((item) => renderSummaryItem(item))}
        </Grid>
      </SectionBody>
    </SectionWrapper>
  );
};

export default CustomerSaleSummary;
