import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { Text, TextInput } from '@mantine/core';
import { useCheckSlug } from 'apis/ProductsApi';
import IconFA from 'components/common/IconFA';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { useAsyncDebounce } from 'react-table';

type Props = {
  type?: any;
  value?: string;
  prefix?: string;
  onChange?: (event: any) => void;
  onError?: (event: any) => void;
  errorMessage?: any;
  fieldName?: string;
  placeholder?: string;
  defaultValue?: string;
  required?: boolean;
  reload?: boolean;
  label?: string;
  key?: string;
  // localSlugs?: string[];
};

const SlugInput = ({
  type = 'product',
  value,
  prefix,
  onChange,
  defaultValue,
  label,
  errorMessage,
  placeholder,
  required,
  // localSlugs,
  key,
  onError,
  reload,
}: Props) => {
  const { mutate: checkSlug }: any = useCheckSlug();
  const [defaultSlug, setDefaultSlug] = useState<string>();
  const [isExist, setIsExist] = useState<boolean>();

  // SET INIT SLUG
  useEffect(() => {
    if (!defaultSlug && defaultValue) {
      setDefaultSlug(defaultValue);
    }
  }, [defaultValue, defaultSlug]);

  useEffect(() => {
    setDefaultSlug(defaultValue);
    fetchCheckSlug(value);
  }, [reload]);

  // VALIDATE ON VALUE CHANGE
  useEffect(() => {
    if (value) {
      fetchCheckSlug(value);
    }
  }, [value, defaultSlug, key]);

  const fetchCheckSlug = useAsyncDebounce(async (value) => {
    checkSlug(
      { slug: value, model: type },
      {
        onSuccess: (res: any) => {
          const isExisted = !res?.data && value !== defaultSlug;
          setIsExist(isExisted);
          onError?.(isExisted ? t('general.slug_exist') : '');
        },
      },
    );
  }, 500);

  return (
    <TextInput
      label={label || t('general.slug')}
      leftSection={
        <Text className="ms-2" size="sm" c={'neutral.5'}>
          {prefix}
        </Text>
      }
      leftSectionWidth={(prefix?.length || 0) * 7.3}
      value={value}
      required={required}
      placeholder={placeholder}
      error={isExist ? t('general.slug_exist') : errorMessage || ''}
      rightSection={
        value && !isExist ? <IconFA icon={faCheck} color="green" /> : null
      }
      onChange={onChange}
    ></TextInput>
  );
};
export default SlugInput;
