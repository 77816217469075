import {
  Button,
  Grid,
  Modal,
  NativeSelect,
  Text,
  TextInput,
} from '@mantine/core';
import { OrderPaymentStatusEnum, PayoutItem } from 'types';
import { t } from 'i18next';
import { currencyFormatter } from 'helpers/utils';
import { SectionFooter } from 'components/MantineUI/CommonSection';
import { useEffect, useState } from 'react';
import { useUpdateAffiliatePayout } from 'apis/AffiliateApis';
import { useQueryClient } from '@tanstack/react-query';
type PropType = {
  opened: boolean;
  onClose: () => void;
  data?: PayoutItem;
};
const UpdatePayoutModal = (props: PropType) => {
  // ### CONSTANTs
  const queryClient = useQueryClient();
  const { opened = false, onClose: onCloseProps, data } = props;
  const [reference, setReference] = useState('');
  const [status, setStatus] = useState<OrderPaymentStatusEnum>(
    OrderPaymentStatusEnum.UNPAID,
  );

  const { mutate: updatePayout, isPending } = useUpdateAffiliatePayout();

  useEffect(() => {
    if (data?._id) {
      setReference(data?.reference || '');
      setStatus(data?.payment_status || OrderPaymentStatusEnum.UNPAID);
    }
  }, [data]);
  // ### FUNCTIONs
  const onSave = () => {
    const submitData = {
      _id: data?._id,
      payment_status: status,
      reference: reference,
    };
    updatePayout(submitData, {
      onSuccess: () => {
        onClose();
        queryClient.invalidateQueries(['useAffiliatePayments'] as any);
      },
    });
  };
  const onClose = () => {
    setReference('');
    setStatus(OrderPaymentStatusEnum.UNPAID);
    onCloseProps();
  };
  // ### RENDERs
  return (
    <Modal
      size={'lg'}
      onClose={onClose}
      opened={opened}
      title={t('payouts.update_payout')}
    >
      <Grid>
        <Grid.Col span={{ base: 12 }}>
          <Text c={'dimmed'}>{t('payouts.payout_period')}</Text>
          <Text fw={'600'}>{data?.name || '--'}</Text>
        </Grid.Col>
        <Grid.Col span={{ base: 12 }}>
          <Text c={'dimmed'}>{t('payouts.earnings')}</Text>
          <Text fw={'600'}>
            {data?.earnings ? currencyFormatter.format(data?.earnings) : '--'}
          </Text>
        </Grid.Col>
        <Grid.Col span={{ base: 12 }}>
          <TextInput
            required
            label={t('affiliates.reference')}
            placeholder="Enter transaction number or notes"
            value={reference}
            onChange={(e) => setReference(e.target.value)}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12 }}>
          <NativeSelect
            required
            value={status}
            onChange={(e) =>
              setStatus(e.target.value as OrderPaymentStatusEnum)
            }
            label={t('general.status')}
            data={[
              {
                label: t('orders.paid'),
                value: OrderPaymentStatusEnum.PAID,
              },
              {
                label: t('orders.unpaid'),
                value: OrderPaymentStatusEnum.UNPAID,
              },
            ]}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12 }}>
          <SectionFooter>
            <Button loading={isPending} onClick={onSave}>
              {t('general.save')}
            </Button>
            <Button variant="default" onClick={onClose}>
              {t('general.cancel')}
            </Button>
          </SectionFooter>
        </Grid.Col>
      </Grid>
    </Modal>
  );
};
export default UpdatePayoutModal;
