import { yupResolver } from '@hookform/resolvers/yup';
import { useUpdateShippingAddress } from 'apis/OrdersApi';
import LoadingButton from 'components/common/buttons/LoadingButton';
import {
  FIRST_NAME_PATTERN_ERROR_MESSAGE,
  FIRST_NAME_REQUIRE_ERROR_MESSAGE,
  FULL_NAME_PATTERN,
  LAST_NAME_PATTERN_ERROR_MESSAGE,
  LAST_NAME_REQUIRE_ERROR_MESSAGE,
  PHONE_NO_REQUIRE_ERROR_MESSAGE,
} from 'constants/constants';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Button, Card, Toast } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import validator from 'validator';
import * as yup from 'yup';
import AddressForm from './AddressForm';
import { useQueryClient } from '@tanstack/react-query';
import {
  useUpdateCustomerAddress,
  useCreateCustomerAddress,
} from 'apis/CustomersApi';
import { toast } from 'react-toastify';
import AddressInfoComponent from 'components/MantineUI/AddressInfoComponent';

export const getAddressChangeValidation = () => {
  const registerAddressChangeSchema = yup.object({
    f_name: yup
      .string()
      .matches(FULL_NAME_PATTERN, FIRST_NAME_PATTERN_ERROR_MESSAGE)
      .required(FIRST_NAME_REQUIRE_ERROR_MESSAGE),
    l_name: yup
      .string()
      .matches(FULL_NAME_PATTERN, LAST_NAME_PATTERN_ERROR_MESSAGE)
      .required(LAST_NAME_REQUIRE_ERROR_MESSAGE),
    address: yup.object().required(),
    phone: yup
      .string()
      .test({
        name: 'is-phone-number',
        test(value) {
          return validator.isMobilePhone(value, undefined, {
            strictMode: false,
          });
        },
      })
      .required(PHONE_NO_REQUIRE_ERROR_MESSAGE),
    type: yup.string().required(),
  });

  return registerAddressChangeSchema;
};

export const ShippingAddress = ({
  shippingAddress,
  canAdd = false,
  customer,
  disabled = false,
  isOrder,
  onUpdateAddress,
  isCompleted,
}) => {
  const { t } = useTranslation();
  const { orderId } = useParams();
  const [isEditing, setIsEditing] = useState(false);
  const { mutate, isPending } = useUpdateShippingAddress(orderId);
  const { mutate: updateCustomerAddress, isPending: updateAddressPending } =
    useUpdateCustomerAddress();
  const { mutate: createCustomerAddress, isPending: createAddressPending } =
    useCreateCustomerAddress();
  const queryClient = useQueryClient();
  const formMethods = useForm({
    resolver: yupResolver(getAddressChangeValidation()),
  });

  const { handleSubmit, setValue } = formMethods;

  const onSubmit = (formData) => {
    const submitData = {
      ...formData.address,
      f_name: formData?.f_name,
      l_name: formData?.l_name,
      phone: formData?.phone,
      business_name:
        formData?.business_name ||
        (isOrder || shippingAddress?._id ? null : undefined),
      type: formData?.type,
      customer_id: customer?._id || undefined,
      is_default:
        !isOrder && !customer?.address?.length
          ? 1
          : shippingAddress?.is_default || 0,
      _id: shippingAddress?._id || undefined,
    };

    if (isOrder) {
      mutate(submitData, {
        onSuccess: () => {
          setIsEditing(false);
          queryClient.invalidateQueries({ queryKey: ['useGetOrders'] });
        },
      });
    } else {
      if (!shippingAddress?._id) {
        createCustomerAddress(submitData, {
          onSuccess: (res) => {
            if (res?.status) {
              toast.success(t('general.create_success'));
              setIsEditing(false);
              onUpdateAddress?.(res?.data?.address?.[0]);
            } else {
              toast.success(t('general.create_fail'));
            }
          },
        });
      } else {
        updateCustomerAddress(submitData, {
          onSuccess: (res) => {
            if (res?.status) {
              toast.success(t('general.update_success'));
              queryClient.invalidateQueries('useGetCustomers');
              onUpdateAddress?.(submitData);
              setIsEditing(false);
            } else {
              toast.success(t('general.update_fail'));
            }
          },
        });
      }
    }
  };

  useEffect(() => {
    if (!isEditing) return;
    setValue('f_name', shippingAddress?.f_name);
    setValue('l_name', shippingAddress?.l_name);
    setValue('phone', shippingAddress?.phone);
    setValue('business_name', shippingAddress?.business_name);
    setValue('type', shippingAddress?.type);
    const address = { ...shippingAddress };
    delete address.f_name;
    delete address.l_name;
    delete address.phone;
    delete address.business_name;
    setValue('address', address);
  }, [isEditing]);

  return (
    <Card className="mb-3">
      <Card.Header>
        <div className="d-flex flex-between-center align-items-center">
          <h5>{t('orders.shipping_address')}</h5>
          {isCompleted ? null : isEditing ? (
            <div>
              <Button
                variant="falcon-default"
                size="sm"
                onClick={() => setIsEditing(false)}
                className="me-2"
              >
                <span>{t('general.discard')}</span>
              </Button>
              <LoadingButton
                size="sm"
                isLoading={
                  isPending || updateAddressPending || createAddressPending
                }
                onClick={handleSubmit(onSubmit)}
              >
                <span>{t('general.save')}</span>
              </LoadingButton>
            </div>
          ) : shippingAddress ? (
            <Button
              variant="falcon-default"
              size="sm"
              onClick={() => setIsEditing(true)}
            >
              {t('general.edit')}
            </Button>
          ) : canAdd && customer ? (
            <Button
              variant="outline-primary"
              size="sm"
              onClick={() => setIsEditing(true)}
            >
              {t('general.add')}
            </Button>
          ) : null}
        </div>
      </Card.Header>
      <Card.Body className="pt-0">
        {isEditing ? (
          <AddressForm formMethods={formMethods} customer={customer} />
        ) : shippingAddress ? (
          <AddressInfoComponent address={shippingAddress} />
        ) : (
          <div className="fs--1">
            {t('customer.please_select_add_customer')}
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

ShippingAddress.propTypes = {
  data: PropTypes.object.isRequired,
};
